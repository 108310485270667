import { marker } from "@jsverse/transloco-keys-manager/marker";
import { maxPlantNumber } from "app/shared/work-order-functions";

import { NewLotCreationSchema } from "../lot/schemas/index";

const adjustment_reasons: string[] = [
	"Harvested",
	"Dried",
	"Processing Loss",
	"Packaged",
	"Transferred",
	"Drying Loss",
	"Destroyed",
	"Lost/Theft",
	"Lab Test",
	"Returns",
	"Opening Inventory",
	"Other",
];

export const schema = () => ({
	title: "",
	description: "",
	info: "",
	properties: {
		name: {
			type: "string",
			title: "Name (optional)",
			title_translation_key: marker("form_field_label_name_optional"),
		},
		reason: {
			type: "string",
			title: "Reason",
			widget: "select",
			oneOf: adjustment_reasons.map((item) => {
				return {
					name: item,
					value: item,
					enum: [item],
				};
			}),
		},
		reason_other: {
			type: "string",
			title: "Other Reason",
			visibleIf: {
				reason: ["Other"],
			},
			default: "",
		},
		cultivar_id: {
			type: "number",
			title: "Cultivar",
			widget: "data-select",
			oneOf: [{ result_type: "cultivars" }],
		},
		batch_id: {
			type: "number",
			title: "Destination Batch",
			title_translation_key: marker("form_field_label_destination_batch"),
			widget: "data-select",
			oneOf: [{ result_type: "batches" }],
		},
		growth_stage_id: {
			type: "number",
			title: "Plant Stage",
			title_translation_key: marker("form_field_label_plant_stage"),
			widget: "data-select",
			oneOf: [
				{
					result_type: "growth_stages",
					queryString: {
						growth_stage_status: ["ACTIVE"],
					},
				},
			],
		},
		lot_id: {
			...NewLotCreationSchema.lot_id({ dont_show_expired: "true" }),
			visibleIf: undefined,
			quick_create: true,
		},
		number_of_plants: {
			type: "number",
			title: "Number Of Plants",
			title_translation_key: marker("form_field_label_number_of_plants"),
			max: maxPlantNumber,
			warning:
				"Number of plants in output exceeds the recommended maximum - Please lower your plant count.",
			warning_translation_key: marker(
				"form_field_warning_number_of_plants_exceeds_recommended",
			),
		},
		timestamp: {
			type: "string",
			title: "Time in",
			title_translation_key: marker("form_field_label_time_in"),
			widget: "date",
		},
	},
	required: [
		"batch_id",
		"cultivar_id",
		"growth_stage_id",
		"number_of_plants",
		"reason",
	],
});
