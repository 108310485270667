import { marker } from "@jsverse/transloco-keys-manager/marker";

import { NewLotCreationSchema } from "../../lot";

export const WorkOrderPlantOutputDynamicSchema = () => ({
	title: "",
	description: "",
	info: "",
	properties: {
		id: {
			type: "number",
			hidden: true,
		},
		plant_batch_id: {
			type: "number",
			title: "Destination Batch",
			title_translation_key: marker("form_field_label_destination_batch"),
			widget: "data-select",
			// quick_create: false,
			oneOf: [
				{
					result_type: "batches",
					queryString: {
						input: "true",
						dont_show_expired: "true",
					},
				},
			],
		},
		plant_new_lot: {
			...NewLotCreationSchema.new_lot,
		},
		plant_create_new_lot: {
			...NewLotCreationSchema.create_new_lot,
			visibleIf: {
				plant_new_lot: ["Create New Lot"],
			},
		},
		plant_lot_name: {
			...NewLotCreationSchema.lot_name,
			visibleIf: {
				plant_create_new_lot: [true],
			},
		},
		plant_lot_id: {
			type: "number",
			title: "Destination Lot",
			title_translation_key: marker("form_field_label_destination_lot"),
			widget: "data-select",
			quick_create: false,
			oneOf: [
				{
					result_type: "lots",
					queryString: {
						input: "true",
						dont_show_expired: "true",
					},
				},
			],
			visibleIf: {
				plant_new_lot: ["Use Existing Lot"],
			},
		},

		plant_selection: {
			title: "Plant Output",
			title_translation_key: marker("form_field_label_plant_output"),
			type: "string",
			widget: "radio",
			oneOf: [
				{
					name: "Create new plants.",
					name_translation_key: marker("form_field_value_create_new_plants"),
					value: "CREATE",
					enum: ["CREATE"],
				},
				{
					name: "Output existing plants in work order.",
					name_translation_key: marker(
						"form_field_value_output_existing_plants_in_work_order",
					),
					value: "EXISTING",
					enum: ["EXISTING"],
				},
			],
		},
		remaining_plants: {
			title: "Use Remaining Plants",
			title_translation_key: marker("form_field_label_use_remaining_plants"),
			type: "boolean",
			widget: "checkbox",
			default: false,
			visibleIf: {
				allOf: [{ plant_selection: ["EXISTING"] }],
			},
		},
		use_plant_ids: {
			title: "Use Specific Plant Ids",
			title_translation_key: marker("form_field_label_use_specific_plant_ids"),
			type: "boolean",
			widget: "checkbox",
			default: false,
			visibleIf: {
				allOf: [{ plant_selection: ["EXISTING"] }, { remaining_plants: false }],
			},
		},
		plant_ids: {
			type: "array",
			title: "Plant Ids",
			title_translation_key: marker("form_field_label_plant_ids"),
			widget: "data-select",
			quick_create: false,
			items: {
				type: "number",
				oneOf: [
					{
						result_type: "plants",
						queryString: {
							plant_status: "ACTIVE",
						},
					},
				],
			},
			visibleIf: {
				allOf: [{ use_plant_ids: [true] }],
			},
		},
		cultivar_id: {
			type: "number",
			title: "Cultivar",
			title_translation_key: marker("word_cultivar"),
			widget: "data-select",
			oneOf: [
				{
					result_type: "cultivars",
				},
			],
			visibleIf: {
				allOf: [{ plant_selection: ["CREATE"] }],
			},
		},
		growth_stage_id: {
			type: "number",
			title: "Growth Stage",
			title_translation_key: marker("word_growth_stage"),
			widget: "data-select",
			oneOf: [
				{
					result_type: "growth_stages",
					queryString: {
						growth_stage_status: ["ACTIVE"],
					},
				},
			],
			visibleIf: {
				allOf: [{ plant_selection: ["CREATE"] }],
			},
		},
		plant_count_create: {
			type: "number",
			title: "Number of Plants",
			title_translation_key: marker("form_field_label_number_of_plants"),
			visibleIf: {
				allOf: [{ plant_selection: ["CREATE"] }],
			},
		},
		plant_count_use: {
			type: "number",
			title: "Number of Plants",
			title_translation_key: marker("form_field_label_number_of_plants"),
			visibleIf: {
				allOf: [
					{ plant_selection: ["EXISTING"] },
					{ use_plant_ids: [false] },
					{ remaining_plants: [false] },
				],
			},
		},
		timestamp: {
			type: "string",
			title: "Timestamp",
			title_translation_key: marker("word_timestamp"),
			hidden: false,
			widget: "date",
		},
		/** These are just used for the required to make sure we have all the correct items selected before allowing the user to submit the form */
		use_existing_lot_selected: {
			type: "boolean",
			widget: "checkbox",
			hidden: true,
			default: true,
			visibleIf: {
				new_lot: ["Use Existing Lot"],
			},
		},
		create_new_lot_selected: {
			type: "boolean",
			widget: "checkbox",
			hidden: true,
			default: true,
			visibleIf: {
				new_lot: ["Create New Lot"],
			},
		},
		remaining_plants_selected: {
			type: "boolean",
			widget: "checkbox",
			hidden: true,
			default: false,
			visibleIf: {
				remaining_plants: [true],
			},
		},
		plant_selection_existing: {
			type: "boolean",
			widget: "checkbox",
			hidden: true,
			default: false,
			visibleIf: {
				plant_selection: ["EXISTING"],
			},
		},
		destination_location_id: {
			type: "number",
			title: "Destination Location",
			title_translation_key: marker("form_field_label_destination_location"),
			description:
				"Destination batch will be moved to this location when the work order is closed.",
			description_translation_key: marker(
				"form_field_description_destination_batch_will_be_moved_to_this_location_when_the_work_order_is_closed",
			),
			widget: "data-select",
			quick_create: false,
			oneOf: [
				{
					result_type: "locations",
					queryString: {
						input: "true",
					},
				},
			],
			visibleIf: {
				move_batch_to_destination: [true],
			},
		},
	},
	anyOf: [
		// Use specific number of plants
		{
			required: ["id", "plant_batch_id", "plant_count_use"],
		},
		// Create specific number of plants
		{
			required: ["id", "plant_batch_id", "plant_count_create"],
		},
		// Use specific plant ids
		{
			required: ["id", "plant_batch_id", "plant_ids"],
		},

		// Use remaining existing plants
		{
			required: [
				"id",
				"plant_batch_id",
				"plant_selection_existing",
				"remaining_plants_selected",
			],
		},
	],
});
