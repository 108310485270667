/* jscpd:ignore-start */
import {
	Injector,
	Component,
	OnInit,
	OnDestroy,
	ChangeDetectorRef,
} from "@angular/core";
import { TitleCasePipe } from "@angular/common";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import {
	IDestructionLot,
	GroupedDestructionEventUpdate,
	MeasureUnit,
	round,
	UserProfile,
} from "@elevatedsignals/amygoodman";
import { ReplaySubject, EMPTY } from "rxjs";
import { catchError, skipWhile, tap, takeUntil, timeout } from "rxjs/operators";
import { BaseMeasureUnitToUnitPipe } from "app/modules/dashboard/modules/es-pipes/units/base_measure_unit_to_unit.pipe";
import { UnitToBaseMeasureUnitPipe } from "app/modules/dashboard/modules/es-pipes/units/unit_to_base_measure_unit.pipe";
import { Globals } from "app/shared/modules/globals/globals.service";
import { handleObservableError } from "app/shared/utils";
import { SelectionActions } from "app/modules/dashboard/actions/selection.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";

import { InventoryDestructionReweighSchema } from "./schemas/inventory-destruction-reweigh";
import { getPlantsDestructionReweighSchema } from "./schemas/plants-destruction-reweigh";
import { getSubstanceTypeDestructionReweighSchema } from "./schemas/substance-type-destruction-reweigh";
/* jscpd:ignore-end */

@Component({
	selector: "grouped-destruction-event-reweigh",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class GroupedDestructionEventReweighComponent
	extends GenericUpdateComponent<GroupedDestructionEventUpdate>
	implements OnInit, OnDestroy
{
	event;
	destruction_lot: IDestructionLot;

	form_title = "Reweigh Destruction Event";
	form_title_translation_key: string = marker(
		"form_title_reweigh_destruction_event",
	);

	submit_button = "Submit";
	submit_button_translation_key: string = marker("word_submit");
	submit_icon = "weight";

	model: any = {};
	weight_unit: string = this._globals.weight_unit;
	measure_unit: MeasureUnit = MeasureUnit.Mass;

	valid$: ReplaySubject<boolean> = new ReplaySubject(1);
	error$: ReplaySubject<string> = new ReplaySubject();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	loading_error$: ReplaySubject<string> = new ReplaySubject<string>();
	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	user: UserProfile;
	user$ = this._store.select(fromDashboard.getProfile);

	constructor(
		_store: Store<fromDashboard.State>,
		_cd: ChangeDetectorRef,
		private readonly _injector: Injector,
		private readonly _itemService: ItemService,
		private readonly _globals: Globals,
		private readonly _baseMeasureUnitToUnitPipe: BaseMeasureUnitToUnitPipe,
		private readonly _unitToBaseMeasureUnitPipe: UnitToBaseMeasureUnitPipe,
		private readonly _titleCasePipe: TitleCasePipe,
	) {
		super(_store, _cd);

		this.user$
			.pipe(
				takeUntil(this.destroyed$),
				skipWhile((user: UserProfile | null) => user === null),
			)
			.subscribe((user) => {
				this.user = user!;
			});
	}

	ngOnInit() {
		this.event = this._injector.get("event", null);
		this.destruction_lot = this._injector.get("destruction_lot", null);

		this.model.group = this.event.group;

		const eventCount = this.event.group_events_count;
		const NumDecimalPlaces = 4;
		if (this.event.inventory) {
			this.schema = InventoryDestructionReweighSchema();

			this.model.inventory_unit_id = this.event.inventory.inventory_unit_id;

			// destruction events in a group can have different values
			// so we need to sum them up and * eventCount will not always be correct
			if (eventCount > 1) {
				const sum = this.event.all_inventory_destruction_events_from_group.reduce(
					(accumulator, item) =>
						accumulator + item.inventory.totalAmountInDisplayUnit,
					0,
				);

				this.model.quantity = round(sum, NumDecimalPlaces);
			} else {
				this.model.quantity = round(
					this.event.inventory.totalAmountInDisplayUnit,
					NumDecimalPlaces,
				);
			}

			if (this.event.reweigh_inventory) {
				this.model.reweigh_value = round(
					this.event.reweigh_inventory.totalAmountInDisplayUnit * eventCount,
					NumDecimalPlaces,
				);
			}
		} else if (this.event.plant) {
			this.schema = getPlantsDestructionReweighSchema(this.weight_unit);

			this.measure_unit = MeasureUnit.Mass;
			this.model.weight = this.transform(this.event.weight, eventCount);

			if (this.event.reweigh_weight) {
				this.model.reweigh_value = this.transform(
					this.event.reweigh_weight,
					eventCount,
				);
			}
		}
		// @deprecated else
		else if (this.event.measure_event) {
			this.schema = getSubstanceTypeDestructionReweighSchema(
				this.weight_unit,
				this._titleCasePipe,
			);

			this.measure_unit = this.event.measure_event.measure_unit;
			this.model.measure_unit = this.event.measure_event.measure_unit;
			this.model.value = this.transform(
				this.event.measure_event.value,
				eventCount,
			);

			if (this.event.reweigh_measure_event) {
				this.model.reweigh_value = this.transform(
					this.event.reweigh_measure_event.value,
					eventCount,
				);
			}
		}

		if (this._globals.gmp_enabled) {
			delete this.schema.properties.timestamp;
		}

		this._cd.detectChanges();
	}

	valid(isValid) {
		this.valid$.next(isValid);
	}

	onChanges(model): void {
		// empty
	}

	onError(error): void {
		// empty
	}

	onSubmit() {
		const updateDestructionEvent = this.model;
		if (this.event.plant || this.event.measure_event) {
			updateDestructionEvent.reweigh_value =
				this._unitToBaseMeasureUnitPipe.transform(
					this.model.reweigh_value,
					this._globals.getFacilityUnit(this.measure_unit) ?? "",
					this.measure_unit,
				);
		}
		this.updateItem(updateDestructionEvent);
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}

	updateItem(updateDestructionEvent: GroupedDestructionEventUpdate) {
		this.loading$.next(true);
		this._itemService
			.add(
				`destruction_lots/${this.destruction_lot.id}/events/group/${this.event.group}/reweigh`,
				updateDestructionEvent,
				{},
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((item) => {
					this._store.dispatch(
						SelectionActions.select({
							result_type: "destruction_lots",
							id: this.destruction_lot.id,
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}

	transform(value: number, count: number) {
		const NumDecimalPlaces = 4;
		return Number(
			this._baseMeasureUnitToUnitPipe.transform(
				round(value * count, NumDecimalPlaces),
				this.measure_unit,
				this._globals.getFacilityUnit(this.measure_unit),
			),
		);
	}
}
