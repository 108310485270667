import {
	Observable,
	ReplaySubject,
	EMPTY,
	combineLatest,
	BehaviorSubject,
	NEVER,
	race,
	of,
} from "rxjs";
import { Component, OnDestroy, Input, inject } from "@angular/core";
import { HttpResponseBase } from "@angular/common/http";
import { Store } from "@ngrx/store";
import {
	takeUntil,
	filter,
	tap,
	take,
	map,
	timeout,
	catchError,
	combineLatestWith,
} from "rxjs/operators";
import { TranslocoService } from "@jsverse/transloco";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { Permissions, PERMISSIONS } from "app/shared/permissions";
import { TimeoutError } from "app/shared/errors";
import { ItemService } from "app/modules/dashboard/services/item.service";
import * as fromDashboard from "app/modules/dashboard/reducers";
import * as fromAuth from "app/modules/auth/reducers";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";
import {
	Batch,
	Plant,
	ILocation,
	Record as ESRecord,
	Sensor,
	ITag,
	IDestructionEvent,
	IWorkOrder,
	IMeasureEvent,
	ISubstanceType,
	MeasureUnitDict,
	SubstanceTypes,
	MeasureUnit,
	Cultivar,
	IInventory,
	StatusType,
	IInventoryProduct,
	Page,
	IUserAction,
} from "@elevatedsignals/amygoodman";
import {
	BatchUpdateComponent,
	BatchReopenComponent,
	BatchHarvestComponent,
	BatchCuttingsComponent,
	BatchRecordDriedComponent,
	BatchSplitPlantComponent,
	BatchMoveComponent,
	BatchChangeGrowthStageComponent,
	BatchSplitWeightComponent,
	BatchCloseComponent,
	PlantReconcileComponent,
} from "app/modules/dashboard/pages/sidenav/batch";
import {
	TagInputComponent,
	NewQuickRecordComponent,
	SensorCreateComponent,
	PlantCreateComponent,
	CreateMeasureEventComponent,
	CustomPrinterJobCreateComponent,
	WorkOrderStartComponent,
	DeleteMeasureEventComponent,
	UpdateMeasureEventComponent,
	WorkOrderCreateComponent,
	DestroyPlantComponent,
	TagPlantComponent,
	DestroyWeightComponent,
	SetStatusComponent,
	CreateInventoryMeasureEventComponent,
	UpdateInventoryMeasureEventComponent,
	InventoryDestructionComponent,
	CreateEzInventoryMeasureEventComponent,
	InventoryDestructionEzComponent,
} from "app/modules/dashboard/pages/sidenav";
import { ReadingsService } from "app/modules/dashboard/services/readings.service";
import { Globals } from "app/shared/modules/globals/globals.service";
import { Big } from "big.js";
import { ApiService } from "app/modules/auth/services/api.service";
import {
	getInventoryTotals,
	handleObservableError,
	hasDriedCannabis,
	hasFreshCannabis,
	isOnHold,
	canCloseBatch,
} from "app/shared/utils";
import { TranslateErrorService } from "app/shared/errors/handleError";
import {
	getUnix,
	FormatOption,
	getIsSameOrBefore,
	getFormattedEndOfDay,
	getFormattedStartOfDay,
	DateFormatsForDateFns,
} from "app/shared/time-format";
import {
	endOfHour,
	subMonths,
	startOfDay,
	isEqual,
	isValid,
	differenceInHours,
} from "date-fns";
import {
	BatchDetailQuery,
	BatchInventoryQuery,
	BatchPlantHistoryQuery,
	BatchRelativesQuery,
	BatchSensorsQuery,
	BatchSpecialWorkOrdersQuery,
	unwrapEager,
} from "app/shared/eagers";
import { DateFormatService } from "app/modules/dashboard/services/date-format/dateFormat.service";

import { RelatedItemTab } from "../../interfaces/related-item-tab.interface";
import { GramsToUnitPipe } from "../../modules/es-pipes/grams-to-unit.pipe";
import { Harvest_Type } from "../../modules/es-components/es-harvest";
import {
	generalInventoryEnabled,
	getSelectedFacilitySettings,
	ezGiMigration,
} from "../../selectors/facility-settings.selector";
import {
	FacilitySettings,
	LicenceType,
} from "../../state/facilitySettings.state";
import { DownloadActions } from "../../actions/download.actions";

@Component({
	selector: "batch-detail-page",
	templateUrl: "./batch-detail.component.html",
	host: { class: "container" },
	styleUrls: ["./batch-detail.component.scss"],
})
export class BatchDetailPageComponent implements OnDestroy {
	BatchDetailQuery = BatchDetailQuery;
	public static getBatchWeight = (batch: Batch): number | null => {
		if (batch.adjusted_weight || batch.adjusted_weight === 0) {
			return batch.adjusted_weight;
		} else if (batch.dry_weight) {
			return batch.dry_weight;
		}
		return null;
	};

	static getTotal(measure_events: IMeasureEvent[]): number {
		return Number(
			measure_events.reduce((result, item) => {
				return result.plus(item.value);
			}, new Big(0)),
		);
	}

	// @deprecated
	static batchSubstanceTypes(
		batch: Batch,
	): Record<number, Partial<ISubstanceType>> {
		if (!batch.measurements) {
			return {};
		}
		return batch.measurements.reduce((result, measurement) => {
			if (measurement.substance_type_id) {
				result[measurement.substance_type_id] = {
					id: measurement.substance_type_id,
					name: measurement.substance_type!.name,
				};
				return result;
			}
			return result;
		}, {});
	}

	// @deprecated
	static wetWeights(batch: Batch) {
		return batch.measurements?.filter((measurement: IMeasureEvent) => {
			if (measurement.substance_type) {
				return measurement.substance_type.name === SubstanceTypes.FreshCannabis;
			}

			return false;
		});
	}

	// @deprecated
	static dryWeights(batch: Batch) {
		return batch.measurements?.filter((measurement: IMeasureEvent) => {
			if (measurement.substance_type) {
				return measurement.substance_type.name === SubstanceTypes.FreshCannabis;
			}

			return false;
		});
	}

	static getMeasureUnits(measurements: IMeasureEvent[]) {
		const measureUnits: MeasureUnit[] = [];
		for (const measurement of measurements) {
			if (measureUnits.includes(measurement.measure_unit) === false) {
				measureUnits.push(measurement.measure_unit);
			}
		}
		return measureUnits;
	}

	/**
	 * Filters batch measure events based off the given substance type, if a measure unit is provided it will also filter on that
	 * @param batch - Batch who's measure event's we're filtering
	 * @param substance_type_id - ID of the substance type to filter on
	 * @param measure_unit - Optional measure unit to also filter on
	 *
	 * @returns {IMeasureEvent[]} - Filtered array of measure events
	 */
	// @deprecated
	static getMeasureEvents(
		batch: Batch,
		substance_type_id: number,
		measure_unit?: MeasureUnit,
	): IMeasureEvent[] {
		const filteredMeasureEvents: IMeasureEvent[] | undefined =
			batch.measurements?.filter((measureEvent) => {
				if (
					measureEvent.substance_type_id === substance_type_id &&
					(!measure_unit || measureEvent.measure_unit === measure_unit)
				) {
					return true;
				}
				return false;
			});

		return filteredMeasureEvents ?? [];
	}

	/**
	 * Filters batch measure events from harvest and drying work orders based off the given substance type, if a measure unit is provided it will also filter on that
	 * @param batch - Batch who's measure event's we're filtering
	 * @param substance_type_id - ID of the substance type to filter on
	 * @param measure_unit - Optional measure unit to also filter on
	 *
	 * @returns {IMeasureEvent[]} - Filtered array of measure events
	 */
	// @deprecated
	static getHarvestMeasurements(
		batch: Batch,
		substance_type_id: number,
		unit?: MeasureUnit,
	): IMeasureEvent[] {
		const harvestWorkOrderIds = [
			...(batch.harvest_work_orders ?? []),
			...(batch.drying_work_orders ?? []),
		].map((wo) => wo.id);
		const harvestMeasureEvents: IMeasureEvent[] =
			BatchDetailPageComponent.getMeasureEvents(batch, substance_type_id, unit)
				.filter((measureEvent) => {
					if (
						measureEvent.credit_event &&
						measureEvent.credit_event.work_order_output &&
						harvestWorkOrderIds.includes(
							measureEvent.credit_event.work_order_output.work_order_id,
						)
					) {
						return true;
					} else if (
						measureEvent.debit_event &&
						measureEvent.debit_event.work_order_output &&
						harvestWorkOrderIds.includes(
							measureEvent.debit_event.work_order_output.work_order_id,
						)
					) {
						return true;
					}
					return false;
				})
				.reduce((result, measure) => {
					const measureDate = measure.timestamp;
					result.push({
						...measure,
						timestamp: measureDate,
					});
					return result;
				}, [] as IMeasureEvent[]);
		harvestMeasureEvents.sort((eventA, eventB) => {
			if (
				getIsSameOrBefore(new Date(eventA.timestamp), new Date(eventB.timestamp))
			) {
				return 1;
			} else {
				return -1;
			}
		});
		return harvestMeasureEvents;
	}

	// @deprecated
	static getEsMeasureEventList(
		batch: Batch,
		substance_type_id: number,
	): { event: IMeasureEvent; dict: MeasureUnitDict }[] {
		const workOrderMeasurements: Record<
			number,
			{ event: IMeasureEvent; dict: MeasureUnitDict }
		> = {};
		let relatedMeasurements: {
			event: IMeasureEvent;
			dict: MeasureUnitDict;
		}[] = BatchDetailPageComponent.getMeasureEvents(
			batch,
			substance_type_id,
		).reduce(
			(result, measure: IMeasureEvent) => {
				let measureDate = measure.timestamp;
				let measureWorkOrder: IWorkOrder | undefined;
				if (measure.debit_event) {
					if (measure.debit_event.work_order_output) {
						measureWorkOrder = measure.debit_event.work_order_output.work_order;
					}
					if (measure.debit_event.work_order_source) {
						measureWorkOrder = measure.debit_event.work_order_source.work_order;
					}
				}
				if (measure.credit_event) {
					if (measure.credit_event.work_order_output) {
						measureWorkOrder = measure.credit_event.work_order_output.work_order;
					}
					if (measure.credit_event.work_order_source) {
						measureWorkOrder = measure.credit_event.work_order_source.work_order;
					}
				}
				// Adds up all measure events for work orders to display as one line item
				if (measureWorkOrder && measureWorkOrder.open_action) {
					measureDate = measureWorkOrder.close_action
						? measureWorkOrder.close_action.timestamp
						: measureWorkOrder.open_action.timestamp;

					const measurement = workOrderMeasurements[measureWorkOrder.id];
					if (measureWorkOrder.id && measurement) {
						if (measurement.dict[measure.measure_unit]) {
							measurement.dict[measure.measure_unit] = new Big(
								measurement.dict[measure.measure_unit]!,
							)
								.plus(measure.value)
								.toNumber();
						} else {
							measurement.dict[measure.measure_unit] = measure.value;
						}
					} else {
						workOrderMeasurements[measureWorkOrder.id] = {
							event: {
								...measure,
								timestamp: measureDate,
							},
							dict: {
								[MeasureUnit.Mass]: 0,
								[MeasureUnit.Quantity]: 0,
								[MeasureUnit.Volume]: 0,
								...{ [measure.measure_unit]: measure.value },
							},
						};
					}

					return result;
				}
				result.push({
					event: measure,
					dict: {
						[MeasureUnit.Mass]: 0,
						[MeasureUnit.Quantity]: 0,
						[MeasureUnit.Volume]: 0,
						...{ [measure.measure_unit]: measure.value },
					},
				});
				return result;
			},
			[] as { event: IMeasureEvent; dict: MeasureUnitDict }[],
		);
		relatedMeasurements = relatedMeasurements.concat(
			Object.values(workOrderMeasurements),
		);
		relatedMeasurements.sort((objectA, objectB) => {
			if (
				getIsSameOrBefore(
					new Date(objectA.event.timestamp),
					new Date(objectB.event.timestamp),
				)
			) {
				return 1;
			} else {
				return -1;
			}
		});
		return relatedMeasurements;
	}

	static workOrdersForHarvestTotals(
		batch: Batch,
	): Partial<ISubstanceType>[] | Partial<IInventoryProduct>[] {
		const workOrders = [
			...(batch.drying_work_orders ?? []),
			...(batch.harvest_work_orders ?? []),
		];
		const result = {};
		for (const workOrder of workOrders) {
			for (const output of workOrder.outputs ?? []) {
				// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
				const isGiOutput = Boolean(output.inventory?.inventory_product_id);
				if (isGiOutput) {
					if (
						output.inventory.inventory_product_id &&
						output.inventory.inventory_product
					) {
						result[output.inventory.inventory_product_id] =
							output.inventory.inventory_product;
					}
				} else if (
					output.measure_event?.substance_type &&
					output.measure_event.substance_type_id
				) {
					result[output.measure_event.substance_type_id] =
						output.measure_event.substance_type;
				}
			}
		}

		return Object.values(result);
	}

	static getInventoryProducts(
		inventories: IInventory[] = [],
	): Record<number, Partial<IInventoryProduct>> {
		if (!inventories.length) {
			return {};
		}
		return inventories.reduce((result, inventory) => {
			if (inventory.inventory_product) {
				result[inventory.inventory_product_id] = inventory.inventory_product;
				return result;
			}
			return result;
		}, {});
	}

	static getInventoryList(
		inventories: IInventory[] = [],
		product_id: number,
	): IInventory[] {
		return inventories.filter(
			(inventory) =>
				inventory.inventory_product_id === product_id &&
				inventory.measurements?.length &&
				(!inventory.archived || !inventory.pending), // To hide pending inventory archived in closed PO
		);
	}

	// Returns substance types in the batch that have values in them.
	// @deprecated
	static getActiveSubstanceTypes(batch: Batch): Partial<ISubstanceType>[] {
		return Object.values(
			BatchDetailPageComponent.batchSubstanceTypes(batch),
		).filter(
			(substance_type: Partial<ISubstanceType>) =>
				BatchDetailPageComponent.getTotal(
					BatchDetailPageComponent.getMeasureEvents(
						batch,
						substance_type.id!,
						MeasureUnit.Mass,
					),
				) > 0 ||
				BatchDetailPageComponent.getTotal(
					BatchDetailPageComponent.getMeasureEvents(
						batch,
						substance_type.id!,
						MeasureUnit.Quantity,
					),
				) > 0 ||
				BatchDetailPageComponent.getTotal(
					BatchDetailPageComponent.getMeasureEvents(
						batch,
						substance_type.id!,
						MeasureUnit.Volume,
					),
				) > 0,
		);
	}

	static inventoryLocations(
		batch: Batch,
		inventory_product_id?: number,
	): ILocation[] {
		if (!batch.inventories) {
			return [];
		}
		const locations = batch.inventories.reduce((result: any[], inventory) => {
			if (
				!inventory_product_id ||
				inventory_product_id === inventory.inventory_product_id
			) {
				if (inventory.location?.id && inventory.totalAmount) {
					result[inventory.location!.id] = inventory.location;
					return result;
				}
			}
			return result;
		}, []);

		return Object.values(locations).map((i) => i.name);
	}

	// Returns substance types in the batch that have values in them.
	static getActiveProducts(
		productInventory?: IInventory[],
	): Partial<IInventoryProduct>[] {
		const products: Record<number, Partial<IInventoryProduct>> = {};
		const productTotals: Record<number, Record<number, number>> = {};

		for (const inventory of productInventory ?? []) {
			if (inventory.inventory_product) {
				products[inventory.inventory_product_id] = inventory.inventory_product;
				if (inventory.measurements) {
					for (const measureEvent of inventory.measurements) {
						// set this just to keep things cleaner looking
						const totals = productTotals[inventory.inventory_product_id];

						const unitTotal = totals?.[inventory.inventory_unit_id];

						productTotals[inventory.inventory_product_id] = {
							...totals,
							[inventory.inventory_unit_id]: unitTotal
								? new Big(unitTotal).plus(measureEvent.value).toNumber()
								: measureEvent.value,
						};
					}
				}
			}
		}

		return Object.values(products).filter(
			(product: Partial<IInventoryProduct>) => {
				if (product.id && productTotals[product.id]) {
					// Issue when inventory was added to batch, but measurement was removed
					for (const total of Object.values(productTotals[product.id]!)) {
						if (total > 0) {
							return true;
						}
					}
				}
				return false;
			},
		);
	}

	@Input() unitSetting = "batch";
	hasPermission = Permissions.hasPermission;
	PERMISSIONS = PERMISSIONS;
	getInventoryTotals = getInventoryTotals;
	hasDriedCannabis = hasDriedCannabis;
	hasFreshCannabis = hasFreshCannabis;
	isOnHold = isOnHold;

	MeasureUnit = MeasureUnit;
	user$ = this._store.select(fromDashboard.getProfile);
	batch$: ReplaySubject<Batch> = new ReplaySubject();

	// Event Log / PDF Download
	quality_records: BehaviorSubject<boolean> = new BehaviorSubject(true);
	running_totals: BehaviorSubject<boolean> = new BehaviorSubject(true);
	batch_parent_events: BehaviorSubject<boolean> = new BehaviorSubject(false);
	email_pdf: BehaviorSubject<boolean> = new BehaviorSubject(false);
	custom_fields: BehaviorSubject<boolean> = new BehaviorSubject(false);
	available_inventory: BehaviorSubject<boolean> = new BehaviorSubject(false);
	history$: ReplaySubject<boolean> = new ReplaySubject(0);
	plantHistory$: ReplaySubject<boolean> = new ReplaySubject(0);
	schema$: ReplaySubject<boolean> = new ReplaySubject(0);
	availableInventory$: ReplaySubject<boolean> = new ReplaySubject(0);
	generalInventoryEnabled$ = this._store.select(generalInventoryEnabled);
	downloadPDFError = this._store.selectSignal(fromDashboard.pdfDownloadError);
	giEnabled = false;
	ezGiMigrated$ = this._store.select(ezGiMigration);
	ezGiMigrated = false;
	facilityWeightUnit = "g";

	getCustomFieldType$ = this.batch$.pipe(
		map((batch) => {
			return batch.batch_type?.uuid ?? "";
		}),
	);

	tags$ = this.batch$.pipe(
		map((batch) => {
			return batch.tags ?? [];
		}),
	);

	facilitySettings$ = this._store.select(getSelectedFacilitySettings);

	showInventoryLedger$ = this.facilitySettings$.pipe(
		map((settings) => {
			return settings.data?.options?.batch_expand_inventory_ledger_by_default;
		}),
	);

	canCloseBatch$ = this.facilitySettings$.pipe(
		combineLatestWith(this.batch$),
		map(([settings, batch]) => {
			return canCloseBatch(batch, settings.licence.general_inventory);
		}),
	);

	processingDestructionInventoryProducts$ = this.batch$.pipe(
		map((batch) => {
			const activeInventoryProducts = BatchDetailPageComponent.getActiveProducts(
				batch.inventories,
			);

			if ((batch.plant_count ?? 0) > 0) {
				// There are plants in this batch, so we consider it a cultivation batch
				const cultivationProductIds = this._globals.cultivationInventoryProducts;

				return activeInventoryProducts.filter(
					(activeInventoryProduct) =>
						!cultivationProductIds.includes(activeInventoryProduct.id!),
				);
			}

			return activeInventoryProducts;
		}),
	);

	shouldShowBatchDestructionButton$ = this.facilitySettings$.pipe(
		combineLatestWith(this.batch$, this.processingDestructionInventoryProducts$),
		map(([settings, batch, processingDestructionInventoryProducts]) => {
			const giDestruction =
				settings.licence.general_inventory &&
				processingDestructionInventoryProducts.length > 0;

			const substanceDestruction =
				!settings.licence.general_inventory &&
				this.processingDestructionSubstanceTypes(
					BatchDetailPageComponent.getActiveSubstanceTypes(batch),
					batch,
				).length > 0;

			return giDestruction || substanceDestruction;
		}),
	);

	propagationWorkOrderTypes$ = this.facilitySettings$.pipe(
		map((settings) => {
			const propagation_work_order_types =
				settings.data?.options?.propagation_work_order_types;
			return propagation_work_order_types ?? [];
		}),
	);

	seedLotSubstanceTypes$ = this.facilitySettings$.pipe(
		map((settings) => {
			return settings.data?.options?.seed_lot_substance_types ?? [];
		}),
	);

	showReportingPeriods$ = this.facilitySettings$.pipe(
		map((settings) => {
			return (
				settings.data?.options?.enable_reporting_periods &&
				settings.reporting_periods.length > 0
			);
		}),
	);

	reportingPeriods$ = this.facilitySettings$.pipe(
		map((settings) => {
			return settings.reporting_periods;
		}),
	);

	relatedItemTabs$: ReplaySubject<RelatedItemTab[]> = new ReplaySubject();

	showCultivationSection$ = this.facilitySettings$.pipe(
		map((settings) => {
			return settings.licence[LicenceType.Cultivation] === true;
		}),
	);

	showProcessingSection$ = this.facilitySettings$.pipe(
		map((settings) => {
			return (
				settings.licence[LicenceType.Processing] === true ||
				settings.licence[LicenceType.NonCannabis] === true
			);
		}),
	);

	showQualityRecordsSection$ = this.facilitySettings$.pipe(
		map((settings) => {
			return (
				settings.licence[LicenceType.Quality] === true &&
				settings.licence[LicenceType.Forms] === true
			);
		}),
	);

	showHarvestActions$ = this.facilitySettings$.pipe(
		map((settings) => settings.harvest_archived === false),
	);

	showDryingActions$ = this.facilitySettings$.pipe(
		map((settings) => settings.drying_archived === false),
	);

	allowDrying$ = this.showDryingActions$.pipe(
		combineLatestWith(
			this.batch$,
			this.generalInventoryEnabled$,
			this.facilitySettings$,
		),
		map(([showDryingAction, batch, giEnabled, facilitySettings]) => {
			return (
				showDryingAction &&
				this.hasFreshCannabis(batch, giEnabled, facilitySettings)
			);
		}),
	);

	canBeginHarvest$ = this.batch$.pipe(
		map((batch) =>
			(batch.growth_stages ?? []).some(
				(gs) => gs.growth_stage_type?.name === "Flowering",
			),
		),
	);

	// Harvest
	harvest_weight: number;
	harvest_tared = false;
	harvest_bin_weight: number;
	harvest_type = Harvest_Type;

	SubstanceTypes = SubstanceTypes;
	showBatchEnvironmentals = false;
	tabs = [
		{
			title: "Details",
		},
		{
			title: "More Info",
		},
	];

	getBatchWeight = BatchDetailPageComponent.getBatchWeight;

	private batch: Batch;
	private readonly batch_id$ = this._store.select(
		fromDashboard.getSelectedBatchId,
	);

	private readonly batch_detail$ = this._store.select(
		fromDashboard.getSelectedBatch,
	);

	private readonly batch_loading_error$ = this._store.select(
		fromDashboard.getSelectedBatchError,
	);

	private readonly destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
	private readonly loading_error$: ReplaySubject<string | null> =
		new ReplaySubject<string | null>();

	private readonly plant_history_loading_error$: ReplaySubject<string | null> =
		new ReplaySubject<string | null>();

	private readonly token$ = this._authStore.select(fromAuth.getToken);

	private readonly plantsPage$: ReplaySubject<Page<Plant>> = new ReplaySubject();
	private readonly inventoryPage$: ReplaySubject<Page<IInventory>> =
		new ReplaySubject();

	private readonly recordsPage$: ReplaySubject<Page<ESRecord>> =
		new ReplaySubject();

	private readonly sensorsPage$: ReplaySubject<Page<Sensor>> =
		new ReplaySubject();

	private readonly destructionEventsPage$: ReplaySubject<
		Page<IDestructionEvent>
	> = new ReplaySubject();

	private readonly workOrderPage$: ReplaySubject<Page<IWorkOrder>> =
		new ReplaySubject();

	private readonly loading_readings_error$ = new ReplaySubject<string | null>();
	private readonly plotData$ = new ReplaySubject();

	private additionalDestroyTypes: Partial<ISubstanceType>[] = [];

	private additionalDestroyTypesLoaded = false;

	private additionalDestroyProducts: Partial<ISubstanceType>[] = [];
	private additionalDestroyProductsLoaded = false;

	private readonly weight_unit: BehaviorSubject<string> =
		this._globals.weight_unit_replay;

	// Accessed in html
	private readonly barcode_type: BehaviorSubject<string> =
		this._globals.barcode_type_replay;

	private readonly relatedItemTabsDefault: RelatedItemTab[] = [
		{
			title: "Work Orders",
			title_translation_key: marker("page_title_plural_work_orders"),
			page: this.workOrderPage$,
			selector: fromDashboard.getWorkOrderPage,
			result_type: "work_orders",
			permission: PERMISSIONS.WORK_ORDERS_VIEW,
			save: true,
			newItem: (batch) => {
				this.openNewWorkOrderSidenav(batch);
			},
		},
		{
			title: "Plants",
			title_translation_key: marker("page_title_plural_plants"),
			page: this.plantsPage$,
			selector: fromDashboard.getPlantPage,
			result_type: "plants",
			permission: PERMISSIONS.PLANTS_VIEW,
			save: true,
			newItem: (batch) => {
				this.openNewPlantSidenav(batch);
			},
			selectable: true,
			requiredLicence: LicenceType.Cultivation,
		},
		{
			title: "Inventory",
			title_translation_key: marker("page_title_inventory"),
			page: this.inventoryPage$,
			selector: fromDashboard.getInventoryPage,
			permission: PERMISSIONS.INVENTORIES_VIEW,
			result_type: "inventories",
			save: true,
			create: false,
			navigate: true,
			disabled: true,
		},
		{
			title: "Sensors",
			title_translation_key: marker("page_title_plural_sensors"),
			page: this.sensorsPage$,
			selector: fromDashboard.getSensorPage,
			result_type: "sensors",
			permission: PERMISSIONS.SENSORS_VIEW,
			save: true,
			newItem: (batch) => {
				this.openNewSensorSidenav(batch);
			},
		},
		{
			title: "Records",
			title_translation_key: marker("page_title_plural_records"),
			page: this.recordsPage$,
			selector: fromDashboard.getRelatedRecordPage("batch"),
			result_type: "records",
			paging_key: "batch_related_records",
			permission: PERMISSIONS.RECORDS_VIEW,
			save: true,
			newItem: (batch) => {
				this.openNewRecordSidenav(batch);
			},
			selectable: true,
			requiredLicence: LicenceType.Forms,
		},
		{
			title: "Destruction Events",
			title_translation_key: marker("page_title_plural_destruction_events"),
			page: this.destructionEventsPage$,
			selector: fromDashboard.getNonGiBatchDestructionEventPage,
			result_type: "destruction_events",
			paging_key: "destruction_events_batch_non_gi",
			permission: PERMISSIONS.DESTRUCTION_EVENTS_VIEW,
			save: true,
			create: false,
			search: false,
		},
	];

	private show_inventory_ledger = false;

	private translateErrorService = inject(TranslateErrorService);

	constructor(
		private readonly _store: Store<fromDashboard.State>,
		private readonly _authStore: Store<fromAuth.State>,
		private readonly _itemService: ItemService,
		private readonly _readingService: ReadingsService,
		private readonly _gramsToUnitPipe: GramsToUnitPipe,
		private readonly _globals: Globals,
		private readonly _api: ApiService,
		private readonly _dateFormatService: DateFormatService,
		private readonly _translocoService: TranslocoService,
	) {
		combineLatest([this.generalInventoryEnabled$, this.ezGiMigrated$])
			.pipe(takeUntil(this.destroyed$))
			.subscribe((values) => {
				this.giEnabled = values[0];
				this.ezGiMigrated = values[1];
			});
		this.facilitySettings$
			.pipe(takeUntil(this.destroyed$))
			.subscribe((settings) => {
				this.facilityWeightUnit = settings.data?.options?.weight_unit ?? "g";
			});
		this.showInventoryLedger$
			.pipe(takeUntil(this.destroyed$))
			.subscribe((showInventoryLedger) => {
				this.show_inventory_ledger = showInventoryLedger === "Expanded";

				if (this.show_inventory_ledger) {
					// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
					if (this.batch) {
						this.fetchAvailableInventory();
					}
				}
			});
		race(
			combineLatest([this.batch_id$, this.batch_detail$]).pipe(
				takeUntil(this.destroyed$),
				filter((values): values is [string | number, Batch] => {
					if (values[1] instanceof HttpResponseBase) {
						throw values[1];
					}
					return values[1] !== undefined && values[1].id === values[0];
				}),
				this.translateErrorService.catchAndTranslateError(
					"error_failed_to_fetch_batch",
				),
			),
			NEVER.pipe(timeout(100000)),
		).subscribe({
			next: (values) => {
				const [batchId, batch] = values;

				this.batch = batch;

				this.batch$.next(batch);

				// Remove additions on relatedItemTabs if on hold
				const tabItems = this.relatedItemTabsDefault;
				if (this.isOnHold(batch)) {
					for (const item of tabItems) {
						item.create = false;
					}
				}

				// Add inventory
				this._globals.general_inventory_enabled_replay.subscribe((gi_enabled) => {
					const newTabsView = tabItems;

					const destructionEventsView = tabItems.find(
						(tab) => tab.result_type === "destruction_events",
					);
					const inventoriesView = tabItems.find(
						(tab) => tab.result_type === "inventories",
					);

					if (gi_enabled) {
						if (inventoriesView) {
							inventoriesView.disabled = false;
						}
						if (destructionEventsView) {
							destructionEventsView.selector =
								fromDashboard.getGiBatchDestructionEventPage;
							destructionEventsView.paging_key = "destruction_events_batch_gi";
						}
					}

					this.updateRelatedPageLists(Number(batchId), newTabsView);
				});

				if (batch.location && this.showBatchEnvironmentals) {
					this.fetchReadingData([batch.location]);
				}
				const missingDataEager = this.buildAdditionalEager();
				if (missingDataEager.eager !== "[]") {
					this.fetchAdditionalData(values[1], missingDataEager);
				}
			},
			error: (err) => {
				this.loading_error$.next(handleObservableError(err));
				return EMPTY;
			},
		});
	}

	fetchAdditionalData(batch: Batch, eager = BatchDetailQuery) {
		if (!batch) {
			return EMPTY;
		}
		return this._itemService
			.fetchItem("batch", `${batch.id}`, eager)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((_error) => {
					throw new TimeoutError();
				}),
			)
			.pipe(
				catchError((_error) => {
					this.availableInventory$.next(false);
					return EMPTY;
				}),
			)
			.subscribe((updatedItem) => {
				this.availableInventory$.next(false);
				this._store.dispatch(
					ItemActions.updateSuccess({
						updatedItem,
						result_type: "batches",
					}),
				);
			});
	}

	// TODO: find a better way to manipulate with eager object
	buildAdditionalEager() {
		const combineEager: string[] = [];
		if (BatchRelativesQuery.eager && !this.batch.parent_batches) {
			combineEager.push(unwrapEager(BatchRelativesQuery.eager));
		}
		if (BatchSpecialWorkOrdersQuery.eager && !this.batch.harvest_work_orders) {
			combineEager.push(unwrapEager(BatchSpecialWorkOrdersQuery.eager));
		}
		if (
			BatchSensorsQuery.eager &&
			this.batch.sensors?.length !== this.batch.sensors_count
		) {
			combineEager.push(unwrapEager(BatchSensorsQuery.eager));
		}
		if (
			this.show_inventory_ledger &&
			BatchInventoryQuery.eager &&
			(!this.batch.inventories ||
				!this.batch.measurements ||
				(this.batch.inventories_count &&
					this.batch.inventories.length !== this.batch.inventories_count) ||
				(this.batch.measurements_count &&
					this.batch.measurements.length !== this.batch.measurements_count) ||
				(this.batch.inventories[0] && !this.batch.inventories[0]?.user) ||
				(this.batch.measurements[0] && !this.batch.measurements[0]?.user))
		) {
			this.availableInventory$.next(true);
			combineEager.push(unwrapEager(BatchInventoryQuery.eager));
		}
		return {
			eager: `[${combineEager.join(",")}]`,
		};
	}

	fetchBatchDetailsForPlants(batch_id: number) {
		this.plantHistory$.next(true);
		this._itemService
			.fetchItem(`batch`, `${batch_id}`, BatchPlantHistoryQuery)
			.pipe(
				takeUntil(this.destroyed$),
				timeout(50000),
				catchError((error) => {
					this.plantHistory$.next(false);
					this.plant_history_loading_error$.next(handleObservableError(error));
					return EMPTY;
				}),
			)
			.subscribe((updatedItem) => {
				this.plantHistory$.next(false);
				this.plant_history_loading_error$.next(null);
				this._store.dispatch(
					ItemActions.updateSuccess({
						updatedItem,
						result_type: "batches",
					}),
				);
			});
	}

	fetchAvailableInventory() {
		if (
			!this.batch.inventories ||
			!this.batch.measurements ||
			(this.batch.inventories_count &&
				this.batch.inventories.length !== this.batch.inventories_count) ||
			(this.batch.measurements_count &&
				this.batch.measurements.length !== this.batch.measurements_count) ||
			(this.batch.inventories[0] && !this.batch.inventories[0]?.user) ||
			(this.batch.measurements[0] && !this.batch.measurements[0]?.user)
		) {
			this.availableInventory$.next(true);
			this.fetchAdditionalData(this.batch, BatchInventoryQuery);
		}
	}

	fetchSpecialWoOutputInventories(
		batch: Batch,
		inventory_product_id: number,
	): IInventory[] {
		const inventories: IInventory[] = [];
		const harvest_work_orders = batch.harvest_work_orders ?? [];
		const drying_work_orders = batch.drying_work_orders ?? [];
		for (const work_order of [...harvest_work_orders, ...drying_work_orders]) {
			const outputs = work_order.outputs ?? [];
			for (const output of outputs) {
				inventories.push(output.inventory);
			}
		}

		return inventories;
	}

	fetchPlantHistory() {
		if (!this.batch.plant_batch_history)
			this.fetchBatchDetailsForPlants(this.batch.id!);
	}

	updateRelatedPageLists(id: number, tabs: RelatedItemTab[]) {
		if (id) {
			const loadTabs: Observable<any>[] = [];
			for (const tab of tabs) {
				// ESS-6992 special page type for destruction events, pregrouped plants from db
				const page_type =
					tab.result_type === "destruction_events"
						? "destruction_events_batch"
						: tab.result_type;

				loadTabs.push(
					this._store.select(tab.selector).pipe(
						takeUntil(this.destroyed$),
						tap((page) => {
							tab.page.next({
								...page,
								type: "batches",
								type_id: id,
								result_type: tab.result_type,
								page_type,
								paging_key: tab.paging_key,
							});
						}),
					),
				);
			}
			combineLatest(loadTabs).subscribe((_data) => {
				this.relatedItemTabs$.next(tabs);
			});
		}
	}

	fetchReadingData(locations: ILocation[] | null) {
		if (!locations) {
			return EMPTY;
		}

		if (locations.length <= 0) {
			return EMPTY;
		}

		const location = locations[0]!;

		const from = startOfDay(subMonths(new Date(), 1));
		const to = endOfHour(new Date());

		const fromUnix = getUnix(from);
		const toUnix = getUnix(to);

		return this._readingService
			.getLocationReadings(location, fromUnix, toUnix)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((_err) => {
					throw new TimeoutError();
				}),
			)
			.pipe(
				catchError((_) => {
					this.loading_readings_error$.next(
						this._translocoService.translate("error_failed_to_load_sensor_data"),
					);
					return EMPTY;
				}),
				tap((readings) => {
					this.plotData$.next({
						sensors: location.sensors,
						readings,
						dateRange: {
							range: [getFormattedStartOfDay(), getFormattedEndOfDay()],
							rangeslider: [
								this._dateFormatService.formatWithDateFns(from),
								this._dateFormatService.formatWithDateFns(to),
							],
						},
					});
				}),
			);
	}

	name(batch: Batch) {
		const translatedWordBatch = this._translocoService.translate("word_batch");

		return batch.name
			? `${translatedWordBatch}: ${batch.name} (#${batch.id})`
			: `${translatedWordBatch}: #${batch.id}`;
	}

	processingDestructionSubstanceTypes(activeSubstanceTypes, batch) {
		if (batch.plant_count > 0) {
			// There are plants in this batch, so we consider it a cultivation batch
			const cultivationSubstanceTypeIds = this._globals.cultivationSubstanceTypes;

			return (activeSubstanceTypes ?? []).filter(
				(activeSubstanceType) =>
					!cultivationSubstanceTypeIds.includes(activeSubstanceType.id),
			);
		}

		return activeSubstanceTypes;
	}

	additionalCultivationTypesToDestroy() {
		if (
			!this.additionalDestroyTypesLoaded &&
			this._globals.cultivationSubstanceTypes.length > 0
		) {
			this.additionalDestroyTypesLoaded = true;
			this.loadCultivationSubstanceTypes(this._globals.cultivationSubstanceTypes);
		}

		return this.additionalDestroyTypes;
	}

	loadCultivationSubstanceTypes(cultivationSubstanceTypeIds) {
		this._itemService
			.fetchItems("substance_types", cultivationSubstanceTypeIds)
			.subscribe((value) => {
				if (Array.isArray(value)) {
					this.additionalDestroyTypes = value.map((substanceType) => {
						return { id: substanceType.id, name: substanceType.name };
					});
				}
			});
	}

	additionalCultivationProductsToDestroy() {
		if (
			!this.additionalDestroyProductsLoaded &&
			this._globals.cultivationInventoryProducts.length > 0
		) {
			this.additionalDestroyProductsLoaded = true;
			this.loadCultivationProducts(this._globals.cultivationInventoryProducts);
		}

		return this.additionalDestroyProducts;
	}

	loadCultivationProducts(cultivationProductIds) {
		this._itemService
			.fetchItems("inventory_products", cultivationProductIds)
			.subscribe((value) => {
				if (Array.isArray(value)) {
					this.additionalDestroyProducts = value;
				} else {
					this.additionalDestroyProducts = [];
				}
			});
	}

	hasOpenHarvestWorkOrder(batch: Batch): boolean {
		if (batch.harvest_work_orders && batch.harvest_work_orders.length > 0) {
			for (const workOrder of batch.harvest_work_orders) {
				if (workOrder.open_action_id && !workOrder.close_action_id) {
					return true;
				}
			}
		}
		return false;
	}

	hasOpenDryingWorkOrder(batch: Batch): boolean {
		if (batch.drying_work_orders && batch.drying_work_orders.length > 0) {
			for (const workOrder of batch.drying_work_orders) {
				if (workOrder.open_action_id && !workOrder.close_action_id) {
					return true;
				}
			}
		}
		return false;
	}

	getFacilityUnit(unit: MeasureUnit, unitSetting?: string) {
		const setting = unitSetting ? unitSetting : "default";
		return this._globals.getFacilityUnit(unit, setting);
	}

	getUnitSetting(
		substance: Partial<ISubstanceType>,
		seedLotSubstanceTypes: number[],
	) {
		if (seedLotSubstanceTypes.length > 0) {
			if (seedLotSubstanceTypes.includes(Number(substance.id))) {
				return "seed_lot";
			}
		}

		return "default";
	}

	getWeightUnit(unitSetting: string, settings: FacilitySettings) {
		if (unitSetting === "seed_lot") {
			return settings.data?.options?.seed_lot_weight_unit ?? "g";
		}

		return settings.data?.options?.weight_unit ?? "kg";
	}

	wetWeighingInProgress(batch: Batch) {
		return batch.wet_weights && !batch.harvested;
	}

	dryWeighingInProgress(batch: Batch) {
		return batch.dry_weights && !batch.dry_weight;
	}

	batchCultivars(batch: Batch): Cultivar[] {
		const reducedBatch = (batch.plants || []).reduce((result, plant) => {
			return (result[plant.cultivar_id || "No Cultivar"] = plant.cultivar);
		}, {});
		return Object.values(reducedBatch ?? {});
	}

	totalPlants(cultivar_id: number, plants: Plant[]) {
		return plants.filter((plant) => plant.cultivar_id === cultivar_id);
	}

	get substanceTypes(): Observable<Partial<ISubstanceType>[]> {
		return this.batch$.pipe(
			map((batch) =>
				Object.values(BatchDetailPageComponent.batchSubstanceTypes(batch)),
			),
		);
	}

	hasOpenHarvests(harvest_work_orders: IWorkOrder[]) {
		for (const workOrder of harvest_work_orders) {
			if (!workOrder.close_action_id) {
				return true;
			}
		}
		return false;
	}

	// getTared(partial_weight: any) {
	// 	return partial_weight.tared ? "Yes" : "No";
	// }

	// getBinWeight(partial_weight: any) {
	// 	return partial_weight.bin_weight ? partial_weight.bin_weight : "--";
	// }

	// DEPRECATED: For old batch versions
	getTotalWeightOld(partial_weight: {
		tared: boolean;
		weight: number;
		bin_weight?: number;
	}) {
		return partial_weight.tared
			? partial_weight.weight
			: partial_weight.weight - (partial_weight.bin_weight ?? 0);
	}

	// DEPRECATED: For old batch versions
	getTotalMaterialWeight(harvest_weights: any[]): number | null {
		if (!harvest_weights.length) {
			return null;
		}
		let total = 0;
		for (const partial_weight of harvest_weights) {
			total += this.getTotalWeightOld(partial_weight);
		}
		return total;
	}

	getPredictedYield(batch: Batch) {
		if (batch.cultivar && batch.cultivar.predicted_yield) {
			if (batch.plant_count && batch.plant_count !== 0) {
				return batch.plant_count * batch.cultivar.predicted_yield;
			} else {
				return "--";
			}
		} else {
			return "N/A";
		}
	}

	getDryingLoss(batch: Batch): string {
		let wet_weight: number | null = this.getTotalOutputWeight(
			batch.harvest_work_orders?.filter((wo) => wo.close_action_id) ?? [],
		);
		let dry_weight: number | null = this.getTotalOutputWeight(
			batch.drying_work_orders?.filter((wo) => wo.close_action_id) ?? [],
		);
		const destruction_weight: number | null = this.getTotalDestructionWeight(
			batch.drying_work_orders
				?.filter((wo) => wo.close_action_id)
				.reduce((result: any, wo) => {
					return [...result, ...(wo.destruction_events ?? [])];
				}, []),
		);
		// DEPRECATED: If statement below is for handling old batch harvest data.
		if (
			wet_weight == null &&
			dry_weight == null &&
			batch.wet_weights &&
			batch.dry_weights
		) {
			wet_weight = this.getTotalMaterialWeight(batch.wet_weights);
			dry_weight = this.getTotalMaterialWeight(batch.dry_weights);
		}
		if (wet_weight && wet_weight > 0 && dry_weight && dry_weight > 0) {
			const percent = new Big(dry_weight)
				.plus(destruction_weight || 0)
				.div(wet_weight)
				.times(100)
				.round(1);
			const weight_loss = this._gramsToUnitPipe.transform(
				new Big(wet_weight)
					.minus(dry_weight)
					.minus(destruction_weight || 0)
					.toNumber(),
				this.weight_unit.value,
			);
			return `${weight_loss} ${this.weight_unit.value} (${percent.toNumber()}%)`;
		} else {
			return "--";
		}
	}

	getTotalOutputWeight(work_orders: IWorkOrder[]): number | null {
		if (work_orders.length === 0) {
			return null;
		}
		let result = new Big(0);
		const woWithOutputs = work_orders.filter((workOrder) => workOrder.outputs);
		for (const wo of woWithOutputs) {
			for (const output of wo.outputs ?? []) {
				if (output.measure_event && !isNaN(output.measure_event.value)) {
					result = result.plus(output.measure_event.value);
				}
			}
		}
		return Number(result);
	}

	getTotalDestructionWeight(events: IDestructionEvent[]): number | null {
		if (events.length === 0) {
			return null;
		}
		let result = new Big(0);
		for (const event of events) {
			if (event.measure_event && !isNaN(event.measure_event.value)) {
				result = result.plus(event.measure_event.value);
			} else if (!isNaN(event.weight ?? NaN)) {
				result = result.plus(event.weight ?? 0);
			}
		}
		return Number(result);
	}

	getAdjustedWeight(batch: Batch) {
		const weight = BatchDetailPageComponent.getBatchWeight(batch);
		return weight ? weight : "--";
	}

	growthStage(batch: Batch) {
		return batch.growth_stages && batch.growth_stages.length > 0
			? batch.growth_stages.map((stage) => stage.name).join(", ")
			: null;
	}

	productionStage(batch: Batch) {
		return batch.production_stage ? batch.production_stage.name : null;
	}

	formatDate(date: string) {
		return this._dateFormatService.formatDateTime(
			date,
			FormatOption.includeWeekNameAndMonthName,
		);
	}

	formatSpan(from: string, until: string | null = null) {
		const validTo = isValid(new Date(until ?? ""))
			? new Date(until!)
			: new Date();

		const spanInWeeks =
			Math.round((differenceInHours(validTo, new Date(from)) / (24 * 7)) * 100) /
			100;

		return spanInWeeks;
	}

	datesNotEqual(date1: Date, date2: Date) {
		return !isEqual(new Date(date1), new Date(date2));
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	isWorkOrderOpen(work_order: IWorkOrder): boolean {
		return (
			Boolean(work_order.close_action_id) === false &&
			Boolean(work_order.open_action_id)
		);
	}

	openUpdateSidenav() {
		this._store.dispatch(
			layoutActions.openSidenav({ component: BatchUpdateComponent }),
		);
	}

	openPlantReconcileSidenav(batch: Batch) {
		this._store.dispatch(
			layoutActions.openSidenav({
				component: PlantReconcileComponent,
				inputs: {
					max_plant_count: batch.plant_count,
					gi_enabled: this.giEnabled,
					ez_gi_migrated: this.ezGiMigrated,
				},
			}),
		);
	}

	openBatchCuttingsSidenav(batch: Batch) {
		this._store.dispatch(
			layoutActions.openSidenav({
				component: BatchCuttingsComponent,
				inputs: {
					max_plant_count: batch.plant_count,
				},
			}),
		);
	}

	openSplitPlantSidenav() {
		this._store.dispatch(
			layoutActions.openSidenav({ component: BatchSplitPlantComponent }),
		);
	}

	openMoveBatchSidenav() {
		this._store.dispatch(
			layoutActions.openSidenav({ component: BatchMoveComponent }),
		);
	}

	openChangeGrowthStageBatchSidenav() {
		this._store.dispatch(
			layoutActions.openSidenav({
				component: BatchChangeGrowthStageComponent,
			}),
		);
	}

	openSplitWeightSidenav() {
		this._store.dispatch(
			layoutActions.openSidenav({
				component: BatchSplitWeightComponent,
			}),
		);
	}

	openRecordDriedSidenav() {
		this._store.dispatch(
			layoutActions.openSidenav({
				component: BatchRecordDriedComponent,
			}),
		);
	}

	openNewSensorSidenav(batch: Batch) {
		this._store.dispatch(
			layoutActions.openSidenav({
				component: SensorCreateComponent,
				inputs: { batch_id: batch.id },
			}),
		);
	}

	openNewRecordSidenav(batch: Batch) {
		this._store.dispatch(
			layoutActions.openSidenav({
				component: NewQuickRecordComponent,
				inputs: { batch_id: batch.id },
			}),
		);
	}

	openNewPlantSidenav(batch: Batch) {
		this._store.dispatch(
			layoutActions.openSidenav({
				component: PlantCreateComponent,
				inputs: {
					cultivar_id: batch.cultivar_id,
					batch_id: batch.id,
					result_type: "batch",
				},
			}),
		);
	}

	openNewPrintJobSidenav(batch: Batch) {
		const openSidenavAction = {
			component: CustomPrinterJobCreateComponent,
			inputs: {
				batch_id: batch.id,
				entity_type: "batch",
				batch_label_options: "BATCH_LABEL",
				printer_id: this._globals.printer_id,
			},
		};

		this._store.dispatch(layoutActions.openSidenav(openSidenavAction));
	}

	triggerNewPrintJobSidenavForAllLotsInBatch(batch: Batch) {
		this._store.dispatch(
			layoutActions.openSidenav({
				component: CustomPrinterJobCreateComponent,
				inputs: {
					batch_id: batch.id,
					printer_id: this._globals.printer_id,
					entity_type: "batch",
					batch_label_options: "ALL_LOTS_IN_BATCH",
				},
			}),
		);
	}

	openNewWorkOrderSidenav(batch: Batch) {
		this._store.dispatch(
			layoutActions.openSidenav({
				component: WorkOrderCreateComponent,
				inputs: {
					batch_id: batch.id,
					ez_gi_migrated: this.ezGiMigrated,
				},
			}),
		);
	}

	openDestroyPlantsSidenav(batch: Batch) {
		this._store.dispatch(
			layoutActions.openSidenav({
				component: DestroyPlantComponent,
				inputs: {
					batch_id: batch.id,
					result_type: "batch",
				},
			}),
		);
	}

	openTagPlantsSidenav(batch: Batch) {
		this._store.dispatch(
			layoutActions.openSidenav({
				component: TagPlantComponent,
				inputs: {
					batch_id: batch.id,
				},
			}),
		);
	}

	openDestroyWeightSidenav(batch: Batch, substance_type_id) {
		this._store.dispatch(
			layoutActions.openSidenav({
				component: DestroyWeightComponent,
				inputs: {
					batch_id: batch.id,
					substance_type_id,
					result_type: "batch",
				},
			}),
		);
	}

	openDestroyInventorySidenav(
		batch: Batch,
		inventory_product: IInventoryProduct,
	) {
		let component: any = InventoryDestructionComponent;
		if (this.ezGiMigrated) {
			component = InventoryDestructionEzComponent;
		}
		this._store.dispatch(
			layoutActions.openSidenav({
				component,
				inputs: {
					batch_id: batch.id,
					inventory_product_id: inventory_product.id,
					inventory_unit_id: inventory_product.display_unit_id,
					result_type: "batch",
				},
			}),
		);
	}

	createMeasureAdjustment(batch: Batch, substance_type_id?: number) {
		this._store.dispatch(
			layoutActions.openSidenav({
				component: CreateMeasureEventComponent,
				inputs: {
					...(substance_type_id && { substance_type_id }),
					related_item_id: batch.id,
					measure_setting: "batch",
					measurements: batch.measurements,
				},
			}),
		);
	}

	createInventoryAdjustment(
		batch: Batch,
		inventory_product_id?: number,
		inventory_unit_id?: number,
	) {
		if (this.ezGiMigrated) {
			this._store.dispatch(
				layoutActions.openSidenav({
					component: CreateEzInventoryMeasureEventComponent,
					inputs: {
						...(inventory_product_id && { inventory_product_id }),
						facilityWeightUnit: this.facilityWeightUnit,
						related_item_id: batch.id,
						measurements: batch.measurements,
						location_id: batch.location_id,
					},
				}),
			);
		} else {
			this._store.dispatch(
				layoutActions.openSidenav({
					component: CreateInventoryMeasureEventComponent,
					inputs: {
						...(inventory_product_id && { inventory_product_id }),
						...(inventory_unit_id && { inventory_unit_id }),
						related_item_id: batch.id,
						measurements: batch.measurements,
						location_id: batch.location_id,
					},
				}),
			);
		}
	}

	editInventoryMeasureEvent(
		measureEvent: IMeasureEvent & { inventory: IInventory },
		batch: Batch,
	) {
		this._store.dispatch(
			layoutActions.openSidenav({
				component: UpdateInventoryMeasureEventComponent,
				inputs: {
					batch,
					measure_event: measureEvent,
					related_item_id: batch.id,
					related_item: "batch",
				},
			}),
		);
	}

	deleteMeasureEvent(
		measureEvent: IMeasureEvent & { inventory: IInventory },
		batch_id: number,
	) {
		this._store.dispatch(
			layoutActions.openSidenav({
				component: DeleteMeasureEventComponent,
				inputs: {
					related_item_id: batch_id,
					related_item: "batch",
					measure_event: measureEvent,
				},
			}),
		);
	}

	editMeasureEvent(measureEvent: IMeasureEvent, batch: Batch) {
		this._store.dispatch(
			layoutActions.openSidenav({
				component: UpdateMeasureEventComponent,
				inputs: {
					batch,
					measure_event: measureEvent,
					related_item_id: batch.id,
					measure_setting: "batch",
				},
			}),
		);
	}

	deleteInventory(measureEvent: IMeasureEvent, batch_id: number) {
		this._store.dispatch(
			layoutActions.openSidenav({
				component: DeleteMeasureEventComponent,
				inputs: {
					related_item_id: batch_id,
					related_item: "batch",
					measure_event: measureEvent,
				},
			}),
		);
	}

	editInventory(inventory: IMeasureEvent, batch: Batch) {
		this._store.dispatch(
			layoutActions.openSidenav({
				component: UpdateMeasureEventComponent,
				inputs: {
					batch,
					measure_event: inventory,
					related_item_id: batch.id,
					measure_setting: "batch",
				},
			}),
		);
	}

	createSpecialWorkOrder(batch, harvest) {
		this._store.dispatch(
			layoutActions.openSidenav({
				component: WorkOrderStartComponent,
				inputs: {
					batch_id: batch.id,
					harvest,
				},
			}),
		);
	}

	closeHarvest(wet: boolean) {
		if (wet) {
			this._store.dispatch(
				layoutActions.openSidenav({ component: BatchHarvestComponent }),
			);
		} else {
			this._store.dispatch(
				layoutActions.openSidenav({
					component: BatchRecordDriedComponent,
				}),
			);
		}
	}

	createTag(batch: Batch) {
		if (batch.id) {
			this._store.dispatch(
				layoutActions.openSidenav({
					component: TagInputComponent,
					inputs: {
						reference_type: "batch",
						reference_id: batch.id.toString(),
						selector: fromDashboard.getSelectedBatch,
					},
				}),
			);
		}
	}

	deleteTag(batch: Batch, tag: ITag) {
		const item = `batch/${batch.id}/tag`;
		const id = tag.id;
		if (id) {
			this._itemService
				.delete(item, id)
				.pipe(takeUntil(this.destroyed$))
				.pipe(
					timeout(10000),
					catchError((_err) => {
						// this.error$.next(handleObservableError(e, true));
						return EMPTY;
					}),
				)
				.pipe(
					tap((t) => {
						const batchWithDeletedTag = {
							...batch,
							tags: batch.tags?.filter((tag) => tag.id !== id),
						};
						this._store.dispatch(
							ItemActions.updateSuccess({
								updatedItem: batchWithDeletedTag,
								result_type: "batches",
							}),
						);
					}),
				)
				.subscribe();
		}
	}

	getSystemTag(batch: Batch) {
		const systemTags = batch.tags?.filter((tag) => tag.tag_type === "SYSTEM");
		if (systemTags && systemTags.length > 0) {
			return systemTags.pop()!.name;
		} else {
			return null;
		}
	}

	getSystemTagId(batch: Batch) {
		const systemTags = batch.tags?.filter((tag) => tag.tag_type === "SYSTEM");
		if (systemTags && systemTags.length > 0) {
			return systemTags.pop()!.id;
		} else {
			return null;
		}
	}

	download(viewed_batch: Batch) {
		const pbrSettings = this._globals.pbr_settings;

		of(viewed_batch) // Hotfix to get latest batch of this.batch$
			.pipe(take(1))
			.subscribe((batch) => {
				const params = {
					...pbrSettings,
					quality_records: this.quality_records.value.toString(),
					running_totals: this.running_totals.value.toString(),
					parent_history: this.batch_parent_events.value.toString(),
					custom_fields: this.custom_fields.value.toString(),
					available_inventory: this.available_inventory.value.toString(),
					email_pdf: this.email_pdf.value.toString(),
				};
				const url = this._api.record.batchDownload(batch.id!);
				if (this.email_pdf.value) {
					this._store.dispatch(
						DownloadActions.pdfEmailRequestStart({ url, params }),
					);
				} else {
					const momentUpdatedAt = this._dateFormatService.formatWithDateFns(
						viewed_batch.updated_at,
						DateFormatsForDateFns.monthDayYearTime24HourWithDashes,
					);
					const fileName = `${viewed_batch.name}-REPORT-${momentUpdatedAt}.pdf`;
					this._store.dispatch(
						DownloadActions.pdfDownloadStart({ url, params, fileName }),
					);
				}
			});
	}

	recordSettingsChanged(setting, value) {
		switch (setting) {
			case "quality_records":
				this.quality_records.next(value);
				break;
			case "running_totals":
				this.running_totals.next(value);
				break;
			case "batch_parent_events":
				this.batch_parent_events.next(value);
				break;
			case "email_pdf":
				this.email_pdf.next(value);
				break;
			case "custom_fields":
				this.custom_fields.next(value);
				break;
			case "available_inventory":
				this.available_inventory.next(value);
				break;
		}
	}

	closeBatch(batch: Batch) {
		this._store.dispatch(
			layoutActions.openSidenav({ component: BatchCloseComponent }),
		);
	}

	reopenBatch(batch: Batch) {
		this._store.dispatch(
			layoutActions.openSidenav({ component: BatchReopenComponent }),
		);
	}

	setStatus(batch: Batch) {
		this._store.dispatch(
			layoutActions.openSidenav({
				component: SetStatusComponent,
				inputs: {
					status_type: StatusType.Batch,
					status_id: batch.status_id,
					id: batch.id,
				},
			}),
		);
	}

	isInProgress(batch: Batch): boolean {
		return batch.system_status?.is_blocking ?? false;
	}

	formatUserAction(action: IUserAction): string {
		return this._dateFormatService.formatUserAction(action);
	}

	historyLoading(event: boolean) {
		this.history$.next(event);
	}

	plantHistoryLoading(event: boolean) {
		this.plantHistory$.next(event);
	}

	formLoading(event: boolean) {
		this.schema$.next(event);
	}

	formatDescription(batch: Batch) {
		if (batch.description) {
			if (batch.description.length >= 255) {
				return true;
			}
		}

		return false;
	}

	generalInventoryEnabled(giEnabled, ezGiMigration = false): boolean {
		if (giEnabled) {
			return !ezGiMigration;
		}

		return false;
	}
}
