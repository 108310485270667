/* jscpd:ignore-start */
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { timeout, catchError, takeUntil, tap } from "rxjs/operators";
import { EMPTY, ReplaySubject } from "rxjs";
import {
	Component,
	OnInit,
	OnDestroy,
	Injector,
	ChangeDetectorRef,
	SimpleChanges,
} from "@angular/core";
import {
	InventoryDetailQuery,
	LotDetailQuery,
	DestructionLotDetailQuery,
} from "app/shared/eagers";
import { Globals } from "app/shared/modules/globals/globals.service";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { ItemKeyType } from "app/modules/dashboard/reducers/selection/keys";
import { ItemService } from "app/modules/dashboard/services/item.service";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";

import { getDynamicFormChanges } from "../shared/functions/updateWhatChanged";
import { didItemChange } from "../shared/functions/hasPropertyChanged";
import { fetchNewInventoryTotals } from "../shared/functions/fetchNewInventoryTotals";

@Component({
	selector: "inventory-destruction-by-lot",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})

/*
	Inventory Destruction By Lot
*/
export class InventoryDestructionByLotComponent implements OnInit, OnDestroy {
	valid$: ReplaySubject<boolean> = new ReplaySubject(1);
	error$: ReplaySubject<string> = new ReplaySubject();

	form_title = "Inventory Destruction By Lot";
	form_title_translation_key = marker("form_title_inventory_destruction_by_lot");
	submit_button = "Destroy";
	submit_button_translation_key = marker("word_destroy");
	submit_icon = "plus";
	loading = false;

	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	model: any = {};

	/* jscpd:ignore-start */
	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			destruction_lot_id: {
				type: "number",
				minItems: 1,
				title: "Destruction Lot",
				title_translation_key: marker("word_destruction_lot"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "destruction_lots",
						pretext: "",
						text_key: ["name", "id"],
						queryString: {
							lot_status: "OPEN",
						},
					},
				],
			},
			lot_id: {
				type: "number",
				title: "Inventory Lot",
				widget: "data-select",
				quick_create: false,
				oneOf: [
					{
						result_type: "lots",
						queryString: {
							input: "true",
							non_zero_inventory: "true",
						},
					},
				],
			},
			inventory_ids: {
				type: "array",
				title: "Inventory (Optional)",
				title_translation_key: marker("form_field_label_inventory_optional"),
				widget: "data-select",
				quick_create: false,
				multi: true,
				related_properties: ["lot_id"],
				items: {
					type: "number",
					oneOf: [
						{
							result_type: "inventories",
							text_key: ["name", "id"],
							text_format: "?1 (#?2)",
							value_key: "id",
							queryString: {
								non_zero_inventory: "true",
								dont_show_expired: "false",
							},
						},
					],
				},
			},
			destruction_reason_id: {
				type: "number",
				title: "Destruction Reason",
				title_translation_key: marker("word_destruction_reason"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "destruction_reasons",
					},
				],
			},
			description: {
				type: "string",
				title: "Description",
				title_translation_key: marker("word_description"),
				widget: "textarea",
			},
			amount_available: {
				type: "string",
				widget: "string",
				title: "Amount Available",
				readOnly: true,
				visibleIf: {
					oneOf: [
						{ lot_id: ["$EXP$ target.value > 0"] },
						{ inventory_ids: ["$ANY$"] },
					],
				},
			},
			timestamp: {
				type: "string",
				title: "Timestamp",
				title_translation_key: marker("word_timestamp"),
				widget: "date",
			},
		},
		required: ["destruction_lot_id", "lot_id", "destruction_reason_id"],
	};
	/* jscpd:ignore-end */

	validators = {};

	private result_type: "destruction_lot" | "lot" | "inventory" | "work_order";

	private readonly whatChanged: SimpleChanges = {};
	private cacheModel = "";
	private userChangesEnabled = false;

	constructor(
		private readonly _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _globals: Globals,
		private readonly _injector: Injector,
		private readonly _cd: ChangeDetectorRef,
	) {}

	valid(valid) {
		this.valid$.next(valid);
	}

	ngOnInit() {
		if (this._globals.gmp_enabled) {
			delete (this.schema.properties as any).timestamp;
		}

		this.result_type = this._injector.get("result_type", "destruction_lot");
		const inventory_ids = this._injector.get("inventory_ids", []);
		const lot_id = this._injector.get("lot_id", null);
		const destruction_lot_id = this._injector.get("destruction_lot_id", null);

		if (inventory_ids.length > 0) {
			this.model.inventory_ids = inventory_ids;
			this.schema.properties.inventory_ids.readOnly = true;
		}

		if (destruction_lot_id) {
			this.model.destruction_lot_id = destruction_lot_id;
			this.schema.properties.destruction_lot_id.readOnly = true;
		}

		if (lot_id) {
			this.model.lot_id = lot_id;
			this.schema.properties.lot_id.readOnly = true;
		}

		// Enable user changes after we set everything up otherwise i'll mess up our initial model settings
		this.userChangesEnabled = true;
		this.onModelChange(this.model);
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onSubmit() {
		this.destroyRequest(this.model);
	}

	/**
	 * Function meant to set this.model's properties.
	 * this.model changes are important, but this.model.x = [...] is easy to miss so this function is here for more visibility.
	 * @param key
	 * @param value
	 */
	setThisModelValue = (key: string, value: any) => {
		this.model = {
			...this.model,
			[key]: value,
		};
	};

	/**
	 * Checks if a form element has changed since the last time
	 * If true, runs the function to update this.model
	 * If even one form element has changed, then 'Amount Remaining' will also be updated
	 * Tries to avoid running detectChanges unnecessarily; they're ran in changeFormByRequireBatch() and updateInventoryTotals() .
	 * @param model
	 */
	updateDynamicForm = () => {
		let detectChangeNeeded = false;
		if (didItemChange(this.whatChanged.lot_id)) {
			detectChangeNeeded = true;
		} else if (didItemChange(this.whatChanged.inventory_ids)) {
			detectChangeNeeded = true;
		} else if (didItemChange(this.whatChanged.timestamp)) {
			detectChangeNeeded = true;
		}

		if (detectChangeNeeded) {
			fetchNewInventoryTotals(this.whatChanged, this._itemService).subscribe(
				(availableInventoryAmount) => {
					const amountAvailable = availableInventoryAmount.content
						.map((availableInventory) => {
							return `${availableInventory.sum.toFixed(2)} ${availableInventory.name}`;
						})
						.join("\n");
					this.setThisModelValue("amount_available", amountAvailable);

					this._cd.detectChanges();
				},
			);
		}
	};

	onModelChange(model) {
		const modelString = JSON.stringify(model);
		if (this.cacheModel !== modelString) {
			if (this.userChangesEnabled) {
				getDynamicFormChanges(this.whatChanged, model);
				this.updateDynamicForm();
			}

			this.cacheModel = JSON.stringify({ ...model });
		}
	}

	onChanges(model) {
		this.onModelChange(model);
	}

	destroyRequest(destruction_event: any) {
		let query = LotDetailQuery;
		if (this.result_type === "inventory") {
			query = InventoryDetailQuery;
		} else if (this.result_type === "destruction_lot") {
			query = DestructionLotDetailQuery;
		}

		this.loading$.next(true);
		this._itemService
			.update(
				`lot`,
				`${this.model.lot_id}/destroy`,
				{ ...destruction_event, result_type: this.result_type },
				query,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: this.pluralize(this.result_type),
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}

	private pluralize(
		item: "destruction_lot" | "work_order" | "inventory" | "lot",
	): ItemKeyType {
		if (item === "destruction_lot") {
			return "destruction_lots";
		} else if (item === "work_order") {
			return "work_orders";
		} else if (item === "inventory") {
			return "inventories";
		}

		return "lots";
	}

	private closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}
}
/* jscpd:ignore-end */
