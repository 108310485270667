import { StatusType } from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";

export const SetInventoryStatusSchema = () => ({
	title: "",
	description: "",
	info: "",
	properties: {
		status_id: {
			type: "number",
			title: "Status",
			title_translation_key: marker("word_status"),
			widget: "data-select",
			oneOf: [
				{
					result_type: "statuses",
					text_key: ["name"],
					queryString: {
						status_type: StatusType.Inventory,
					},
				},
			],
		},
	},
});
