<ng-container *transloco="let t; read: 'batch_detail_page'">
	<div class="ui grid" *ngIf="batch$ | async; let batch; else: loading">
		<es-message
			*ngIf="downloadPDFError()"
			[message]="downloadPDFError()"
			[clearable]="true"
			[fixed]="true"
		></es-message>
		<div class="sixteen wide column">
			<div class="ui stackable grid">
				<div class="three wide column">
					<back-button />
				</div>

				<ng-template #commonSettings class="">
					<div *ngIf="showQualityRecordsSection$ | async" class="item">
						<fui-checkbox
							[ngModel]="quality_records | async"
							(checkChange)="recordSettingsChanged('quality_records', $event)"
						>
							{{ t("pdf_checkbox_with_quality_records") }}
						</fui-checkbox>
					</div>
					<div class="item">
						<fui-checkbox
							[ngModel]="running_totals | async"
							(checkChange)="recordSettingsChanged('running_totals', $event)"
						>
							{{ t("pdf_checkbox_with_running_totals") }}
						</fui-checkbox>
					</div>
					<div class="item">
						<fui-checkbox
							[ngModel]="batch_parent_events | async"
							(checkChange)="recordSettingsChanged('batch_parent_events', $event)"
						>
							{{ t("pdf_checkbox_with_parent_history") }}
						</fui-checkbox>
					</div>
				</ng-template>
				<ng-template let-popup #eventLogSettings class="ui vertical menu">
					<ng-container *ngTemplateOutlet="commonSettings"></ng-container>
				</ng-template>
				<ng-template let-popup #reportSettings class="ui vertical menu">
					<ng-container *ngTemplateOutlet="commonSettings"></ng-container>
					<div class="item">
						<fui-checkbox
							[ngModel]="custom_fields | async"
							(checkChange)="recordSettingsChanged('custom_fields', $event)"
						>
							{{ t("with_custom_fields") }}
						</fui-checkbox>
					</div>
					<div class="item">
						<fui-checkbox
							[ngModel]="available_inventory | async"
							(checkChange)="recordSettingsChanged('available_inventory', $event)"
						>
							{{ t("with_available_inventory") }}
						</fui-checkbox>
					</div>
					<div class="item">
						<fui-checkbox
							[ngModel]="email_pdf | async"
							(checkChange)="recordSettingsChanged('email_pdf', $event)"
						>
							{{ t("pdf_checkbox_email_pdf") }}
						</fui-checkbox>
					</div>
				</ng-template>

				<div class="right aligned thirteen wide column">
					<es-action-dropdown
						*ngIf="hasPermission(user$ | async, PERMISSIONS.BATCHES_ACTIONS)"
						[actionDropdownText]="t('batch_actions')"
					>
						<!-- <div class="item" (click)="openNewBatchSidenav(batch)">
						New
						</div> -->
						<es-action-item
							[disabled]="isOnHold(batch)"
							(click)="openMoveBatchSidenav()"
						>
							{{ t("move_batch") }}
						</es-action-item>

						<ng-container *ngIf="showCultivationSection$ | async">
							<ng-container *ngIf="batch.plant_count > 0">
								<div class="divider"></div>
								<span class="header">{{ t("batch_actions_cultivation_label") }}</span>
								<br />
							</ng-container>

							<es-action-item
								*ngIf="batch.plant_count > 0"
								[disabled]="isOnHold(batch)"
								(click)="openBatchCuttingsSidenav(batch)"
							>
								{{ t("propagate_plants") }}
							</es-action-item>
							<es-action-item
								[disabled]="isOnHold(batch)"
								(click)="openPlantReconcileSidenav(batch)"
							>
								{{ t("reconcile_plants") }}
							</es-action-item>

							<es-action-item
								*ngIf="batch.plant_count > 0"
								[disabled]="isOnHold(batch)"
								(click)="openSplitPlantSidenav()"
							>
								{{ t("split_batch_by_plants") }}
							</es-action-item>
							<es-action-item
								*ngIf="batch.plant_count > 0"
								[disabled]="isOnHold(batch)"
								(click)="openChangeGrowthStageBatchSidenav()"
							>
								{{ t("advance_growth_stage") }}
							</es-action-item>

							<es-action-item
								*ngIf="batch.plant_count > 0"
								[disabled]="isOnHold(batch)"
								(click)="openTagPlantsSidenav(batch)"
							>
								{{ t("tag_plants") }}
							</es-action-item>

							<ng-container *ngIf="batch.plant_count > 0">
								<div class="divider"></div>
								<span class="header">{{
									t("batch_actions_cultivation_destruction_label")
								}}</span>
								<br />
							</ng-container>

							<es-action-item
								*ngIf="batch.plant_count > 0"
								[disabled]="isOnHold(batch)"
								(click)="openDestroyPlantsSidenav(batch)"
							>
								{{ t("destroy_plants") }}
							</es-action-item>

							<ng-container
								*ngIf="!(generalInventoryEnabled$ | async) && batch.plant_count > 0"
							>
								<es-action-dropdown
									subDropdown="true"
									[disabled]="isOnHold(batch)"
									[actionDropdownText]="t('batch_actions_cultivation_destruction_label')"
								>
									<ng-container *ngIf="additionalCultivationTypesToDestroy().length > 0">
										<es-action-item
											*ngFor="let substanceType of additionalCultivationTypesToDestroy()"
											(click)="openDestroyWeightSidenav(batch, substanceType.id)"
											[disabled]="isOnHold(batch)"
										>
											{{ substanceType.name }}
										</es-action-item>
									</ng-container>
									<ng-container
										*ngIf="additionalCultivationTypesToDestroy().length === 0"
									>
										<es-action-item class="disabled item">
											{{ t("no_cultivation_substance_types_defined") }}
										</es-action-item>
									</ng-container>
								</es-action-dropdown>
							</ng-container>

							<ng-container
								*ngIf="(generalInventoryEnabled$ | async) && batch.plant_count > 0"
							>
								<es-action-dropdown
									subDropdown="true"
									[disabled]="isOnHold(batch)"
									[actionDropdownText]="t('batch_actions_cultivation_destruction_label')"
								>
									<ng-container
										*ngIf="additionalCultivationProductsToDestroy().length > 0"
									>
										<es-action-item
											*ngFor="
												let inventoryProduct of additionalCultivationProductsToDestroy()
											"
											(click)="openDestroyInventorySidenav(batch, inventoryProduct)"
											[disabled]="isOnHold(batch)"
										>
											{{ inventoryProduct.name }}
										</es-action-item>
									</ng-container>
									<ng-container
										*ngIf="additionalCultivationProductsToDestroy().length === 0"
									>
										<es-action-item disabled="true">
											{{ t("no_cultivation_products_defined") }}
										</es-action-item>
									</ng-container>
								</es-action-dropdown>
							</ng-container>

							<div class="divider"></div>
						</ng-container>

						<ng-container *ngIf="showProcessingSection$ | async">
							<span class="header">{{ t("batch_actions_processing_label") }}</span
							><br />

							<es-action-item
								[disabled]="isOnHold(batch)"
								routerLink="/dashboard/work_orders"
							>
								{{ t("work_orders") }}
							</es-action-item>

							<es-action-item
								*ngIf="(showHarvestActions$ | async) && (canBeginHarvest$ | async)"
								[disabled]="isOnHold(batch)"
								(click)="createSpecialWorkOrder(batch, true)"
							>
								{{ t("begin_harvest") }}
							</es-action-item>
							<!-- Below menu item is now deprecated, harvest will be managed through work orders-->
							<es-action-item
								*ngIf="
									(showHarvestActions$ | async) &&
									!batch.harvested &&
									wetWeighingInProgress(batch)
								"
								[disabled]="isOnHold(batch)"
								(click)="closeHarvest(true)"
							>
								{{ t("close_harvest") }}
							</es-action-item>

							<es-action-item
								*ngIf="allowDrying$ | async"
								[disabled]="isOnHold(batch)"
								(click)="createSpecialWorkOrder(batch, false)"
							>
								{{ t("begin_drying") }}
							</es-action-item>

							<!-- Below menu item is now deprecated, drying will be managed through work orders-->
							<es-action-item
								*ngIf="
									(showDryingActions$ | async) &&
									batch.harvested &&
									dryWeighingInProgress(batch)
								"
								[disabled]="isOnHold(batch)"
								(click)="closeHarvest(false)"
							>
								{{ t("close_dry_weigh") }}
							</es-action-item>

							<es-action-item
								*ngIf="!(generalInventoryEnabled$ | async)"
								[disabled]="isOnHold(batch)"
								(click)="createMeasureAdjustment(batch)"
							>
								{{ t("create_substance_reconciliation") }}
							</es-action-item>
							<es-action-item
								*ngIf="generalInventoryEnabled$ | async"
								[disabled]="isOnHold(batch)"
								(click)="createInventoryAdjustment(batch)"
							>
								{{ t("create_inventory_reconciliation") }}
							</es-action-item>

							<es-action-item
								*ngIf="
									!(generalInventoryEnabled$ | async) &&
									(hasFreshCannabis(batch, generalInventoryEnabled$ | async) ||
										hasDriedCannabis(batch, generalInventoryEnabled$ | async))
								"
								[disabled]="isOnHold(batch)"
								(click)="openSplitWeightSidenav()"
							>
								{{ t("split_batch_by_weight") }}
							</es-action-item>

							<ng-container *ngIf="shouldShowBatchDestructionButton$ | async">
								<!-- <div class="divider"></div>
									<span class="header">Processing Destruction</span><br /> -->

								<ng-container *ngIf="generalInventoryEnabled$ | async">
									<es-action-dropdown
										subDropdown="true"
										[disabled]="isOnHold(batch)"
										[actionDropdownText]="t('batch_actions_processing_destruction_label')"
									>
										<es-action-item
											*ngFor="
												let product of processingDestructionInventoryProducts$ | async
											"
											[disabled]="isOnHold(batch)"
											(click)="openDestroyInventorySidenav(batch, product)"
										>
											{{ "word_destroy" | transloco }} {{ product.name }}
										</es-action-item>
									</es-action-dropdown>
								</ng-container>

								<ng-container *ngIf="!(generalInventoryEnabled$ | async)">
									<es-action-dropdown
										subDropdown="true"
										[disabled]="isOnHold(batch)"
										[actionDropdownText]="t('batch_actions_processing_destruction_label')"
									>
										<es-action-item
											*ngFor="
												let substance of processingDestructionSubstanceTypes(
													batch | getActiveSubstanceTypes,
													batch
												)
											"
											[disabled]="isOnHold(batch)"
											(click)="openDestroyWeightSidenav(batch, substance.id)"
										>
											{{ substance.name }}
										</es-action-item>
									</es-action-dropdown>
								</ng-container>
							</ng-container>

							<div class="divider"></div>
						</ng-container>

						<span class="header">{{ "word_other" | transloco }}</span
						><br />

						<es-action-item (click)="setStatus(batch)">
							{{ t("set_status") }}
						</es-action-item>

						<es-action-item
							*ngIf="hasPermission(user$ | async, PERMISSIONS.PRINTER_JOBS_ADD)"
							(click)="openNewPrintJobSidenav(batch)"
						>
							{{ t("print_labels") }}
						</es-action-item>

						<es-action-item
							*ngIf="hasPermission(user$ | async, PERMISSIONS.PRINTER_JOBS_ADD)"
							(click)="triggerNewPrintJobSidenavForAllLotsInBatch(batch)"
						>
							{{ t("print_labels_for_all_lots_in_batch") }}
						</es-action-item>

						<es-action-item
							*ngIf="!batch.archived"
							[disabled]="!(canCloseBatch$ | async)"
							(click)="closeBatch(batch)"
						>
							{{ t("close_batch") }}
						</es-action-item>
						<es-action-item *ngIf="batch.archived" (click)="reopenBatch(batch)">
							{{ t("reopen_batch") }}
						</es-action-item>
					</es-action-dropdown>

					<div
						*ngIf="hasPermission(user$ | async, PERMISSIONS.RECORDS_VIEW)"
						class="ui buttons pr-1 pdf-button"
					>
						<div
							class="ui dropdown icon button"
							fuiPopup
							[popupTemplate]="reportSettings"
							popupTrigger="outsideClick"
							popupPlacement="bottom center"
						>
							<i class="dropdown icon"></i>
						</div>
						<pdf-download-component
							[isEmailed]="email_pdf | async"
							(downloadClick)="download(batch, true)"
						></pdf-download-component>
					</div>

					<button
						*ngIf="hasPermission(user$ | async, PERMISSIONS.BATCHES_EDIT)"
						class="ui labeled icon button"
						[class.disabled]="isOnHold(batch)"
						(click)="openUpdateSidenav()"
					>
						<i class="edit icon"></i>
						{{ "word_edit" | transloco }}
					</button>
					<button
						*ngIf="hasPermission(user$ | async, PERMISSIONS.PRINTER_JOBS_ADD)"
						class="ui labeled icon button"
						(click)="openNewPrintJobSidenav(batch)"
					>
						<i class="print icon"></i> {{ t("print_labels") }}
					</button>
					<!-- <div *ngIf="batch.harvested && batch.wet_weight && !batch.dry_weight" class="fitted item">
					<button class="ui button"
					(click)="openRecordDriedSidenav()">Record Dried Weight</button>
				</div> -->
				</div>
			</div>
		</div>

		<div class="sixteen wide column">
			<div class="ui grid">
				<div class="eight wide column">
					<div class="ui grid">
						<div class="sixteen wide column">
							<div class="ui breadcrumb">
								<div class="section" *ngIf="batch.location">
									<a routerLink="/dashboard/locations/{{ batch.location.id }}">
										{{ batch.location.name }}
									</a>
								</div>
								<i class="right arrow icon divider"></i>
								<div class="active section">
									{{ name(batch) }}
								</div>
							</div>
							{{ " " }}
							<copy-link />
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			*ngIf="(barcode_type | async) == '2D' && getSystemTag(batch)"
			class="batch-qr"
			(click)="openNewPrintJobSidenav(batch)"
		>
			<qrcode
				[qrdata]="getSystemTag(batch)"
				[width]="128"
				[errorCorrectionLevel]="'M'"
			></qrcode>
		</div>
		<div
			*ngIf="(barcode_type | async) == '1D' && getSystemTag(batch)"
			class="batch-barcode"
			(click)="openNewPrintJobSidenav(batch)"
		>
			<ngx-barcode6
				[bc-value]="getSystemTag(batch)"
				[bc-display-value]="false"
				[bc-element-type]="img"
				bc-width="1"
				bc-height="50"
			></ngx-barcode6>
		</div>
		<div class="sixteen wide column grid">
			<div class="column">
				<div class="ui relaxed horizontal list">
					<div
						*ngIf="!(batch.archived || batch.destroyed || isInProgress(batch))"
						class="item"
					>
						<span class="ui green label">{{ "word_active" | transloco }}</span>
					</div>
					<div *ngIf="isInProgress(batch)" class="item">
						<span class="ui yellow label">
							{{ t("batch_actions_in_progress") }}
						</span>
					</div>
					<div *ngIf="batch.archived" class="item">
						<span class="ui yellow label">{{ "word_archived" | transloco }}</span>
					</div>
					<div *ngIf="batch.destroyed" class="item">
						<span class="ui red label">{{ "word_destroyed" | transloco }}</span>
					</div>
					<div class="item">
						<tag-detail
							[tags]="tags$ | async"
							[user]="user$ | async"
							[filterUserTags]="true"
							(createTag)="createTag(batch)"
							(deleteTag)="deleteTag(batch, $event)"
						>
						</tag-detail>
					</div>
				</div>
				<h1 class="ui header">
					{{ name(batch) }}
				</h1>

				<div *ngIf="formatDescription(batch)" class="sixteen wide column">
					<div class="header" style="font-weight: 700">
						{{ "word_description" | transloco }}
					</div>
					<div style="max-width: inherit; word-wrap: break-word">
						<textarea style="width: 100%" rows="5" disabled>{{
							batch.description
						}}</textarea>
					</div>
				</div>

				<div class="ui relaxed horizontal list">
					<div
						class="item"
						*ngIf="!formatDescription(batch)"
						data-testid="batch-description"
					>
						<div class="header">{{ "word_description" | transloco }}</div>
						<span *ngIf="batch.description">{{ batch.description }}</span>
						<span *ngIf="!batch.description">{{ "word_none" | transloco }}</span>
					</div>
					<div
						*ngIf="
							batch.effective_date &&
							datesNotEqual(batch.effective_date, batch.created_at)
						"
						class="item"
					>
						<div class="header">{{ t("effective_date") }}</div>
						{{ batch.effective_date | formatDate }}
					</div>
					<div class="item">
						<div class="header">{{ t("created_on") }}</div>
						{{ batch.created_at | formatDate }}
					</div>
					<div class="item" data-testid="batch-expiry-date">
						<div class="header">{{ t("expiry_date") }}</div>
						{{ batch.expiry_date | formatDate }}
					</div>
					<div class="item" data-testid="batch-cultivar">
						<div class="header">{{ "word_cultivar" | transloco }}</div>
						<a
							*ngIf="batch.cultivar"
							routerLink="/dashboard/cultivars/{{ batch.cultivar_id }}"
							>{{ batch.cultivar.name }}</a
						>
						<span *ngIf="!batch.cultivar">{{ "acronym_n_a" | transloco }}</span>
					</div>
					<div *ngIf="growthStage(batch)" class="item">
						<div class="header">{{ "word_growth_stage" | transloco }}</div>
						<a routerLink="/dashboard/growth_stages">{{ growthStage(batch) }}</a>
					</div>
					<div
						*ngIf="productionStage(batch)"
						class="item"
						data-testid="batch-production-stage"
					>
						<div class="header">{{ "word_production_stage" | transloco }}</div>
						<a
							*ngIf="productionStage(batch)"
							routerLink="/dashboard/production_stages"
							>{{ productionStage(batch) }}</a
						>
					</div>
					<div class="item">
						<div class="header">{{ "word_location" | transloco }}</div>
						<a
							*ngIf="batch.location"
							routerLink="/dashboard/locations/{{ batch.location_id }}"
							>{{ batch.location.name }}</a
						>
						<span *ngIf="!batch.location">{{ "acronym_n_a" | transloco }}</span>
					</div>
					<div class="item" data-testid="batch-type">
						<div class="header">{{ "word_batch_type" | transloco }}</div>
						<span *ngIf="batch.batch_type">{{ batch.batch_type.name }}</span>
						<span *ngIf="!batch.batch_type">{{ "acronym_n_a" | transloco }}</span>
					</div>
					<!-- <div class="item">
					<div class="header">Harvested</div>
					<span *ngIf="!growthStage(batch) && !productionStage(batch)">N/A</span>
					<span *ngIf="!growthStage(batch) && productionStage(batch)">Yes</span>
					<span *ngIf="growthStage(batch) && productionStage(batch)">Partially</span>
					<span *ngIf="growthStage(batch) && !productionStage(batch)">No</span>
				</div> -->
				</div>
			</div>
		</div>

		<es-status-history
			[data]="batch"
			[entity]="'batches'"
			[entityDetailQuery]="BatchDetailQuery"
		></es-status-history>

		<div class="row" *ngIf="getCustomFieldType$ | async; let customFieldType">
			<custom-field
				class="sixteen wide column grid"
				[entity]="customFieldType"
				[entity_id]="batch.id"
				(loading)="formLoading($event)"
			>
			</custom-field>
		</div>

		<div
			class="sixteen wide column grid"
			*ngIf="
				batch.historical_growth_stage_timespans ||
				batch.harvest_work_orders?.length > 0 ||
				batch.drying_work_orders?.length > 0 ||
				(batch | getActiveSubstanceTypes).length > 0
			"
		>
			<div class="column">
				<div class="ui basic segment relaxed horizontal list">
					<div
						*ngIf="batch.plant_count > 0"
						class="item"
						style="vertical-align: top"
					>
						<div class="header">{{ t("active_plants") }}</div>
						<div class="ui list">
							<div class="item item-spacing">
								<div class="content">
									<div class="ui sub header">{{ (batch.cultivar || {}).name }}</div>
									<div class="description">
										{{ batch.plant_count }} {{ t("plants") }}
									</div>
								</div>
							</div>
							<!-- TODO: somehow get the batches cultivars, right now we don't get plants in eager
						<div *ngFor="let cultivar of batchCultivars(batch)">
							<div  class="item">
								<div class="content">
									<div class="ui sub header">{{ cultivar.name }}</div>
									<div class="description">
										{{ getTotalPlants(batch.plants)}} plants
									</div>
								</div>
							</div>
						</div> -->
						</div>
					</div>
					<div
						*ngIf="
							batch.historical_growth_stage_timespans &&
							batch.historical_growth_stage_timespans.length > 0
						"
						class="item"
					>
						<div class="header">{{ t("growth_stages") }}</div>
						<div class="ui list">
							<div *ngFor="let timespan of batch.historical_growth_stage_timespans">
								<div class="item item-spacing">
									<div class="content">
										<div class="ui sub header">{{ timespan.name }}</div>
										<div class="description">
											<span *ngIf="timespan.time_end; else no_time_end">
												{{ formatSpan(timespan.time_start, timespan.time_end) }}
												{{ t("weeks_total") }}.
											</span>
											<ng-template #no_time_end>
												<span>
													{{ formatSpan(timespan.time_start) }}
													{{ "fragment_weeks" | transloco }} ({{
														"fragment_current" | transloco
													}}).
												</span>
											</ng-template>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div
						*ngIf="
							batch.drying_work_orders?.length > 0 ||
							batch.harvest_work_orders?.length > 0
						"
						class="item"
						style="vertical-align: top"
					>
						<div class="header">
							{{ t("harvest_totals") }}
							<i
								class="fitted circular tiny blue info icon"
								style="vertical-align: top"
								fuiPopup
								popupPlacement="top center"
								popupText="{{ t('harvest_totals_popup_text') }}"
							></i>
						</div>
						<div class="ui list">
							<div *ngFor="let product of batch | workOrdersForHarvestTotals">
								<div class="item item-spacing">
									<div class="content">
										<div class="ui sub header">{{ product.name }}</div>
										<div class="description" *ngIf="generalInventoryEnabled$ | async">
											{{
												fetchSpecialWoOutputInventories(batch, product.id)
													| getInventoryList: product.id
													| getInventoryTotals: product : false
											}}
										</div>
										<div class="description" *ngIf="!(generalInventoryEnabled$ | async)">
											<ng-container
												*ngFor="
													let unit of batch
														| getHarvestMeasurements: product.id
														| getMeasureUnits
												"
											>
												{{
													batch
														| getHarvestMeasurements: product.id : unit
														| measureEventTotal
														| baseMeasureUnitToUnit: unit : getFacilityUnit(unit)
												}}
												{{ getFacilityUnit(unit) }}
												<br />
											</ng-container>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div
						class="item"
						style="vertical-align: top"
						*ngIf="
							!(generalInventoryEnabled$ | async) &&
							(batch | getActiveSubstanceTypes).length > 0
						"
					>
						<div class="header">
							{{ t("batch_substance_weights") }}
							<i
								class="fitted circular tiny blue info icon"
								style="vertical-align: top"
								fuiPopup
								popupPlacement="top center"
								popupText="{{ t('batch_substance_weights_popup_text') }}"
							></i>
						</div>
						<div class="ui list">
							<div *ngFor="let substance of batch | getActiveSubstanceTypes">
								<div class="item item-spacing">
									<div class="content">
										<div class="ui sub header">{{ substance.name }}</div>
										<div
											class="description"
											*ngIf="
												getUnitSetting(substance, seedLotSubstanceTypes$ | async);
												let unitSetting
											"
										>
											<ng-container
												*ngFor="
													let unit of batch
														| getMeasureEvents: substance.id
														| getMeasureUnits
												"
											>
												{{
													batch
														| getMeasureEvents: substance.id : unit
														| measureEventTotal
														| baseMeasureUnitToUnit: unit : getFacilityUnit(unit, unitSetting)
												}}
												{{ getFacilityUnit(unit, unitSetting) }}
												<br />
											</ng-container>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div
						class="item"
						style="vertical-align: top"
						*ngIf="
							(generalInventoryEnabled$ | async) &&
							(batch | getActiveProducts).length > 0
						"
					>
						<div class="header">
							{{ t("batch_inventory_totals") }}
							<i
								class="fitted circular tiny blue info icon"
								style="vertical-align: top"
								fuiPopup
								popupPlacement="top center"
								popupText="{{ t('batch_inventory_totals_popup_text') }}"
							></i>
						</div>
						<div class="ui list">
							<div *ngFor="let product of batch | getActiveProducts">
								<div class="item item-spacing">
									<div class="content">
										<div class="ui sub header">{{ product.name }}</div>
										<div class="description">
											{{
												batch.inventories
													| getInventoryList: product.id
													| getInventoryTotals: product
											}}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div
						class="item"
						style="vertical-align: top"
						*ngIf="
							(generalInventoryEnabled$ | async) &&
							!(ezGiMigrated$ | async) &&
							(batch | getActiveProducts).length > 0
						"
					>
						<div class="header">
							{{ t("batch_inventory_locations") }}
							<i
								class="fitted circular tiny blue info icon"
								style="vertical-align: top"
								fuiPopup
								popupPlacement="top center"
								popupText="{{ t('batch_inventory_locations_popup_text') }}"
							></i>
						</div>
						<div class="ui list">
							<div *ngFor="let product of batch | getActiveProducts">
								<div
									*ngIf="(batch | inventoryLocations: product.id).length > 0"
									class="item item-spacing"
								>
									<div class="content">
										<div class="ui sub header">{{ product.name }}</div>
										<div class="description">
											{{ batch | inventoryLocations: product.id }}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div
			*ngIf="batch.parent_batches || batch.child_batches"
			class="sixteen wide column"
		>
			<div class="column ui relaxed verticle list">
				<div
					*ngIf="batch.parent_batches && batch.parent_batches.length > 0"
					class="item"
				>
					<div class="header">{{ t("batch_parents") }}</div>
					<div class="ui basic segment horizontal list">
						<div *ngFor="let parent of batch.parent_batches" class="item">
							<div class="ui label">
								<a routerLink="/dashboard/batches/{{ parent.id }}">{{ parent.name }}</a>
							</div>
						</div>
					</div>
				</div>
				<div
					*ngIf="batch.child_batches && batch.child_batches.length > 0"
					class="item"
				>
					<div class="header">{{ t("batch_children") }}</div>
					<div class="ui basic segment horizontal list">
						<div *ngFor="let child of batch.child_batches" class="item">
							<div class="ui label">
								<a routerLink="/dashboard/batches/{{ child.id }}">{{ child.name }}</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!--
	<div class="sixteen wide column">
		<div class="ui green segment">
			<div class="ui small six statistics">
				<div *ngIf="batch.plant_count > 0" class="statistic">
					<div class="value">
						{{batch.plant_count}}
					</div>
					<div class="label">
						Plants
					</div>
				</div>
				<div class="statistic" fuiPopup popupPlacement="bottom center" popupText="Total Wet Weight">
					<div class="value">
						{{getTotalFreshCannabis(batch) | gramsToUnitPipe: (weight_unit | async)}}
					</div>
					<div class="label">
						TWW ({{weight_unit | async}})
					</div>
				</div>
				<div class="statistic" fuiPopup popupPlacement="bottom center" popupText="Total Dry Weight">
					<div class="value">
						{{getTotalDriedCannabis(batch) | gramsToUnitPipe: (weight_unit | async)}}
					</div>
					<div class="label">
						TDW ({{weight_unit | async}})
					</div>
				</div>
				<div class="statistic" fuiPopup popupPlacement="bottom center" popupText="Drying Loss">
					<div class="value">
						{{getDryingLoss(batch)}}
					</div>
					<div class="label">
						DL ({{weight_unit | async}}-%)
					</div>
				</div>
				<div class="statistic" fuiPopup popupPlacement="bottom center" popupText="Predicted Yield For Batch">
					<div class="value">
						{{getPredictedYield(batch) | gramsToUnitPipe: (weight_unit | async)}}
					</div>
					<div class="label">
						Predicted Yield ({{weight_unit | async}})
					</div>
				</div>
			</div>
		</div>
	</div> -->

		<div class="sixteen wide column">
			<div class="ui buttons">
				<div
					class="ui button primary"
					[ngClass]="(history$ | async) ? 'loading button disabled' : 'button'"
					(click)="show_event_log = !show_event_log"
				>
					<i class="list icon"></i>
					<span *ngIf="show_event_log">{{ t("hide_event_log") }}</span>
					<span *ngIf="!show_event_log">{{ t("show_event_log") }}</span>
				</div>
				<div
					class="ui floating dropdown icon button primary"
					fuiPopup
					[popupTemplate]="eventLogSettings"
					popupTrigger="outsideClick"
					popupPlacement="bottom center"
				>
					<i class="dropdown icon"></i>
				</div>
			</div>
		</div>

		<es-event-log
			*ngIf="show_event_log"
			class="sixteen wide column"
			[batch]="batch"
			[quality_records]="quality_records | async"
			[running_totals]="running_totals | async"
			[batch_parent_events]="batch_parent_events | async"
			[showReportingPeriods]="showReportingPeriods$ | async"
			[reportingPeriods]="reportingPeriods$ | async"
			(loading)="historyLoading($event)"
		>
		</es-event-log>

		<div *ngIf="show_event_log" class="sixteen wide column">
			<button class="ui primary button" (click)="show_event_log = false">
				<i class="list icon"></i>
				{{ t("hide_event_log") }}
			</button>
		</div>

		<div
			*ngIf="batch.harvest_work_orders && batch.harvest_work_orders.length > 0"
			class="ui fitted divider"
		></div>

		<div
			*ngIf="batch.harvest_work_orders && batch.harvest_work_orders.length > 0"
			class="sixteen wide column"
		>
			<div title>
				<h3>{{ t("batch_harvests") }}</h3>
			</div>
			<div content>
				<div class="ui list">
					<div
						*ngFor="let harvest of batch.harvest_work_orders; let i = index"
						class="item"
						routerLink="/dashboard/work_orders/{{ harvest.id }}"
						style="cursor: pointer"
					>
						<span *ngIf="harvest.close_action_id == null"
							>{{ harvest.name }} {{ t("in_progress") }}</span
						>
						<span *ngIf="harvest.close_action_id != null"
							>{{ harvest.name }} {{ t("completed_on") }}
							{{ formatUserAction(harvest.close_action) }}</span
						>
					</div>
				</div>
			</div>
		</div>
		<div
			*ngIf="batch.drying_work_orders && batch.drying_work_orders.length > 0"
			class="sixteen wide column"
		>
			<div title>
				<h3>{{ t("batch_drying") }}</h3>
			</div>
			<div content>
				<div class="ui list">
					<div
						*ngFor="let drying of batch.drying_work_orders; let i = index"
						class="item"
						routerLink="/dashboard/work_orders/{{ drying.id }}"
						style="cursor: pointer"
					>
						<span *ngIf="drying.close_action_id == null"
							>{{ drying.name }} {{ t("in_progress") }}</span
						>
						<span *ngIf="drying.close_action_id != null"
							>{{ drying.name }} {{ t("completed_on") }}
							{{ formatUserAction(drying.close_action) }}</span
						>
					</div>
				</div>
			</div>
		</div>

		<div
			*ngIf="batch.wet_weights && batch.wet_weights.length > 0"
			class="sixteen wide column"
		>
			<div title>
				<h3>
					<span *ngIf="wetWeighingInProgress(batch)">{{
						t("harvest_in_progress")
					}}</span>
					<span *ngIf="batch.harvested"
						>{{ t("harvest_completed_on") }}
						{{ batch.harvest_date | formatDate }}</span
					>
				</h3>
			</div>
			<div content>
				<es-harvest
					[batch]="batch"
					[harvest_type]="Harvest_Type.wet_weight"
					[weight_unit]="weight_unit | async"
				>
				</es-harvest>
			</div>
		</div>

		<div
			*ngIf="batch.dry_weights && batch.dry_weights.length > 0"
			class="sixteen wide column"
		>
			<div title>
				<h3>
					<span *ngIf="dryWeighingInProgress(batch)">{{
						t("dry_weighing_in_progress")
					}}</span>
					<span *ngIf="batch.dry_weight"
						>{{ t("drying_completed_on") }} {{ batch.dried_date | formatDate }}</span
					>
				</h3>
			</div>
			<div content>
				<es-harvest
					[batch]="batch"
					[harvest_type]="Harvest_Type.dry_weight"
					[weight_unit]="weight_unit | async"
				>
				</es-harvest>
			</div>
		</div>

		<div
			class="ui divider"
			*ngIf="
				batch.harvest_work_orders &&
				(batch.harvest_work_orders?.length > 0 ||
					batch.drying_work_orders?.length > 0)
			"
		></div>

		<ng-container *ngIf="batch.historical_plant_count != 0">
			<div class="sixteen wide column">
				<h3 class="plantLedgerHeader">
					<span
						>{{ "word_plants" | transloco }}
						<small> {{ "word_total" | transloco }}: {{ batch.plant_count }} </small>
						<i
							*ngIf="batch.historical_plant_count > 17000"
							fuiPopup
							popupPlacement="center"
							class="exclamation triangle yellow icon"
							popupText="{{
								t(
									'the_plant_ledger_is_unavailable_for_batches_with_over_seventeen_thousand_plants'
								)
							}}"
						>
						</i>
					</span>
				</h3>

				<ng-container *ngIf="batch.historical_plant_count < 17000">
					<div class="ui buttons">
						<div
							class="ui button primary"
							[ngClass]="
								(plantHistory$ | async) ? 'loading button disabled' : 'button'
							"
							(click)="show_plant_ledger = !show_plant_ledger; fetchPlantHistory()"
						>
							<i class="list icon"></i>
							<span *ngIf="show_plant_ledger">{{ t("hide_plant_ledger") }}</span>
							<span *ngIf="!show_plant_ledger">{{ t("show_plant_ledger") }}</span>
						</div>
					</div>

					<ng-container
						*ngIf="
							!(plantHistory$ | async) && !(plant_history_loading_error$ | async);
							else plant_history_loading
						"
					>
						<es-plant-event-list
							*ngIf="show_plant_ledger && propagationWorkOrderTypes$ | async"
							class="eight wide column"
							[batch]="batch$ | async"
							[propagationWorkOrderTypes]="propagationWorkOrderTypes$ | async"
						>
						</es-plant-event-list>
					</ng-container>

					<ng-template #plant_history_loading class="loading">
						<es-loader
							*ngIf="!(plant_history_loading_error$ | async)"
							class="center aligned eight wide column"
						>
						</es-loader>
						<es-message
							*ngIf="plant_history_loading_error$ | async"
							[message]="plant_history_loading_error$ | async"
							class="center aligned eight wide column"
						>
						</es-message>
					</ng-template>
				</ng-container>
			</div>
		</ng-container>

		<ng-container
			*ngIf="batch.measurements_count > 0 || batch.inventories_count > 0"
		>
			<div class="ui buttons">
				<div
					class="ui button primary"
					[ngClass]="
						(availableInventory$ | async) ? 'loading button disabled' : 'button'
					"
					(click)="
						show_inventory_ledger = !show_inventory_ledger; fetchAvailableInventory()
					"
				>
					<i class="list icon"></i>
					<span *ngIf="show_inventory_ledger">{{ t("hide_inventory_ledger") }}</span>
					<span *ngIf="!show_inventory_ledger">{{
						t("show_inventory_ledger")
					}}</span>
				</div>
			</div>

			<ng-container
				*ngIf="show_inventory_ledger && !(availableInventory$ | async)"
			>
				<ng-container *ngIf="!(generalInventoryEnabled$ | async)">
					<div
						class="sixteen wide column"
						*ngFor="let substance of batch | getSubstanceTypes"
					>
						<ng-container
							*ngIf="
								getUnitSetting(substance, seedLotSubstanceTypes$ | async);
								let unitSetting
							"
						>
							<h3 class="substanceLedgerHeader">
								<span
									>{{ substance.name }}
									<small>
										{{ "word_total" | transloco }}:
										<span
											*ngFor="
												let unit of batch | getMeasureEvents: substance.id | getMeasureUnits
											"
											>{{
												batch
													| getMeasureEvents: substance.id : unit
													| measureEventTotal
													| baseMeasureUnitToUnit: unit : getFacilityUnit(unit, unitSetting)
											}}
											{{ getFacilityUnit(unit, unitSetting) }},
										</span>
									</small>
								</span>
								<a
									*ngIf="!isOnHold(batch)"
									style="padding-left: 5px"
									(click)="createMeasureAdjustment(batch, substance.id)"
									><i class="plus small icon"></i
								></a>
							</h3>

							<es-measure-event-list
								class="eight wide column"
								[measureData]="batch | getEsMeasureEventList: substance.id"
								[weightUnit]="getWeightUnit(unitSetting, facilitySettings$ | async)"
								[isSeed]="unitSetting === 'seed_lot' ? true : false"
								(delete)="deleteMeasureEvent($event, batch.id)"
								(edit)="editMeasureEvent($event, batch)"
							>
							</es-measure-event-list>
						</ng-container>
					</div>
				</ng-container>

				<ng-container
					*ngIf="
						generalInventoryEnabled(
							generalInventoryEnabled$ | async,
							ezGiMigrated$ | async
						)
					"
				>
					<ng-container
						*ngFor="let product of batch.inventories | getInventoryProducts"
					>
						<ng-container
							*ngIf="(batch.inventories | getInventoryList: product.id).length > 0"
						>
							<div class="sixteen wide column">
								<es-inventory-list
									class="eight wide column"
									[inventoryList]="batch.inventories | getInventoryList: product.id"
									[product]="product"
									[showTotal]="true"
									[showBatch]="false"
									[pending]="false"
									(create)="createInventoryAdjustment(batch, product.id, $event)"
									(edit)="editInventoryMeasureEvent($event, batch)"
									(delete)="deleteInventory($event, batch.id)"
								></es-inventory-list>
							</div>
						</ng-container>
					</ng-container>
				</ng-container>
				<ng-container *ngIf="ezGiMigrated$ | async">
					<ng-container
						*ngFor="let product of batch.inventories | getInventoryProducts"
					>
						<ng-container
							*ngIf="(batch.inventories | getInventoryList: product.id).length > 0"
						>
							<div class="sixteen wide column">
								<es-ez-gi-inventory-list
									class="eight wide column"
									[inventoryList]="batch.inventories | getInventoryList: product.id"
									[product]="product"
									[showTotal]="true"
									[showBatch]="false"
									[pending]="false"
									(create)="createInventoryAdjustment(batch, product.id, $event)"
									(edit)="editInventoryMeasureEvent($event, batch)"
									(delete)="deleteInventory($event, batch.id)"
								></es-ez-gi-inventory-list>
							</div>
						</ng-container>
					</ng-container>
				</ng-container>
			</ng-container>
		</ng-container>

		<div
			*ngIf="
				showBatchEnvironmentals &&
				hasPermission(user$ | async, PERMISSIONS.READINGS_VIEW)
			"
			class="sixteen wide column"
		>
			<div class="ui fitted divider"></div>
			<h3 class="ui header">{{ "word_environmentals" | transloco }}</h3>
			<div class="sixteen wide column">
				<div *ngIf="plotData$ | async; let plotData; else: loading_sensor_data">
					<es-sensor-graph [plotData]="plotData"> </es-sensor-graph>
				</div>
				<ng-template #loading_sensor_data>
					<es-loader
						*ngIf="!(loading_readings_error$ | async)"
						class="center aligned eight wide column"
					>
					</es-loader>
					<es-message
						*ngIf="loading_readings_error$ | async"
						[message]="loading_readings_error$ | async"
						class="center aligned eight wide column"
					>
					</es-message>
				</ng-template>
			</div>
		</div>

		<div
			class="sixteen wide column"
			*ngIf="
				(user$ | async) &&
				(relatedItemTabs$ | async) &&
				(relatedItemTabs$ | async).length > 0
			"
		>
			<div class="ui divider"></div>
			<es-related-item-tabs
				[relatedItemTabs]="relatedItemTabs$ | async"
				[user]="user$ | async"
				[detailItem]="batch"
			>
			</es-related-item-tabs>
		</div>
	</div>
</ng-container>
<ng-template #loading>
	<es-loader
		*ngIf="!(loading_error$ | async) && !(batch_loading_error$ | async)"
		class="center aligned eight wide column"
	>
	</es-loader>
	<es-message
		*ngIf="(loading_error$ | async) || (batch_loading_error$ | async)"
		[message]="(batch_loading_error$ | async) ?? (loading_error$ | async)"
		class="center aligned eight wide column"
	>
	</es-message>
</ng-template>
