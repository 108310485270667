import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { TranslocoService } from "@jsverse/transloco";

@Component({
	selector: "subscription-button",
	template: `
		<button
			*transloco="let t"
			[ngClass]="buttonClass"
			[disabled]="loading"
			(click)="onSubscriptionButtonClicked()"
		>
			<span>
				<i class="{{ buttonIcon }} icon"></i>
				{{ buttonText }}
			</span>
		</button>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionButtonComponent {
	@Input() loading: boolean;
	@Input() subscribed: boolean;

	@Output() onSubscribe = new EventEmitter();
	@Output() onUnsubscribe = new EventEmitter();

	constructor(private readonly _translocoService: TranslocoService) {}

	get unsubscribedText(): string {
		return this._translocoService.translate("word_unsubscribe");
	}

	get subscribedText(): string {
		return this._translocoService.translate("word_subscribe");
	}

	get buttonText(): string {
		return this.subscribed ? this.unsubscribedText : this.subscribedText;
	}

	get buttonClass(): string {
		const classes = ["ui", "button"];
		if (!this.subscribed) {
			classes.push("primary");
		}
		if (this.loading) {
			classes.push("loading");
		}
		return classes.join(" ");
	}

	get buttonIcon(): string {
		return this.subscribed ? "bell slash icon" : "bell icon";
	}

	onSubscriptionButtonClicked = () => {
		if (this.subscribed) {
			this.onUnsubscribe.emit();
		} else {
			this.onSubscribe.emit();
		}
	};
}
