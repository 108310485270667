import { Component, Input, TemplateRef } from "@angular/core";

@Component({
	selector: "es-icon",
	template: `
		@if (tooltip) {
			<lucide-icon
				nz-tooltip
				[nzTooltipTitle]="tooltip"
				[name]="name"
				[size]="size"
				[color]="color"
			></lucide-icon>
		} @else if (popoverIsString()) {
			<lucide-icon
				nz-popover
				[nzPopoverContent]="contentTemplate"
				[name]="name"
				[size]="size"
				[color]="color"
			></lucide-icon>
			<ng-template #contentTemplate>
				<p style="white-space: pre-wrap">{{ popover }}</p>
			</ng-template>
		} @else if (popover) {
			<lucide-icon
				nz-popover
				[nzPopoverContent]="popover"
				[name]="name"
				[size]="size"
				[color]="color"
			></lucide-icon>
		} @else {
			<lucide-icon [name]="name" [size]="size" [color]="color"></lucide-icon>
		}
	`,
	host: {
		style:
			"display: inline-block; opacity: 0.9; margin-top: -4px; margin-bottom: -2px;",
	},
})
export default class IconComponent {
	@Input() name: string;
	@Input() size = 16;
	@Input() color = "currentColor";
	@Input() tooltip: string;
	@Input() popover: TemplateRef<void> | string;

	popoverIsString(): boolean {
		return typeof this.popover === "string";
	}
}
