/* Put your keys here
	Eg: BackendHelp = "be-help-control",

	The point of this is to give us some type safety in terms of options and centralize keys to allow for easy cleanup.
*/
export enum DevCycleKey {
	SpecialHarvestPlantByIdSidenav = "fe-harvest-plant-by-id-wo-input-sidenav",
	WorkOrderInputOutputPlantIdEnumeration = "fe-work-order-input-output-plant-id-enumeration",
	WorkOrderOutputLabelRange = "fe-work-order-output-label-ranges",
	InventoryTableShowVendor = "fe-inventory-table-show-vendor",
	PlantIdsDestructionEvent = "fe-plantids-destruction-event",
	ShowPlantLots = "fe-show-plant-lots",
	LocationIdForPlantDetailedReport = "fe-location-id-for-plant-detailed-report",
	BatchPageDefaultShowInventoryLedger = "fe-batch-page-default-show-inventory-ledger",
	WorkOrderPageDefaultShowInputsOutputs = "fe-wo-page-default-show-inputs-outputs",
	ShowNonPaymentBanner = "fe-show-non-payment-banner",
	ShowHorizonBanner = "fe-show-horizon-banner",

	ArchiveReasonForRecords = "fe-reason-for-archiving-record",
	FinalWeightsDestructionLots = "fe-destruction-lot-final-weight",
	ArchiveBatchesOnCLose = "fe-archive-empty-batches-on-close",
	WorkOrderOutputInventoryStatus = "fe-wo-output-inventory-status",
	SimplifySalesOrders = "simplify-sales-orders",
	VerticalHarvestWoBom = "fe-vertical-harvest-wo-bom",
	LockRecordOnWoClose = "fe-tilray-lock-records-on-wo-close", // ESS-6740
	AllowTraceabilityReport = "allow-traceability-report",
	QrCodeScanner = "fe-qr-scanner",
	LockVerifyPurchaseOrders = "fe-lock-purchase-orders", // ESS-5867
	CtsAccountingReports = "cts-accounting-reports", // ESS-7489
	CustomFieldsCsvUpload = "fe-custom-fields-csv-upload",
	AccountingModule = "fe-accounting-module", // ESS-7726
	InProgressInventoryInOpeningDetailedReport = "fe-in-progress-inventory-in-opening-detailed-report",
	ShowIntercomMessenger = "fe-show-intercom-messenger",
	InventorySourceDynamicV2 = "inventory-source-dynamic-v-2", // ESS-7948
	ShowMaintenanceBanner = "maintenance-banner",
	MaintenanceBannerTitle = "maintenance-banner-title",
	MaintenanceBannerBody = "maintenance-banner-body",
	WorkOrderStatus = "fe-wo-status",
	RecordStatus = "fe-record-status",
	Costing = "fe-costing",
	ManageUnits = "fe-manage-units",
	SelfVerification = "fe-self-verification",
	ShippingOrdersPlanning = "fe-shipping-orders-planning",
	ShowBillingNavIcon = "fe-billing-page",
	SubscriptionsEnabled = "fe-subscriptions-enabled",
	ShowProductionPlanning = "fe-production-planning",
	ShowQuickbooksIntegration = "feature-quickbooks-integration",
}
