import { Component, Input, TemplateRef } from "@angular/core";

@Component({
	selector: "es-info-icon",
	template: `
		<es-icon name="info" [color]="color" [popover]="infoText"></es-icon>
	`,
	host: {
		style: "display: inline-block;",
		class: "align-top",
	},
})
export default class InfoIconComponent {
	@Input() infoText: TemplateRef<void> | string;
	@Input() color = "orange";
}
