import { SimpleChange } from "@angular/core";

export const updateWhatChanged = (whatChanged, key: string, newValue: any) => {
	whatChanged[key] = new SimpleChange(
		whatChanged[key]?.currentValue,
		newValue,
		false,
	);
};

export const defaultIdsToTrack = [
	"inventory_product_id",
	"inventory_ids",
	"batch_id",
	"lot_id",
	"timestamp",
	"vendor_id",
];
export const skuIdsToTrack = [...defaultIdsToTrack, "sku_id"];

export const getDynamicFormChanges = (
	whatChanged,
	model,
	idsToTrack = defaultIdsToTrack,
) => {
	for (const id of idsToTrack) {
		updateWhatChanged(whatChanged, id, model[id]);
	}
};
