import { Injectable } from "@angular/core";

@Injectable({
	providedIn: "root",
})
export class EnvService {
	// The values that are defined here are the default values that can
	// be overridden by env.js

	public es_env: string;
	public es_api_server: string;
	public es_client_api: string;
	public cookie_domain: string;

	// DevCycle
	public dev_cycle_environment_key: string;

	public live_reporting_url?: string;
	public accounting_url?: string;

	// Datadog
	public dd_version?: string;
	public dd_env?: string;
	public dd_rum_application_id?: string;
	public dd_rum_client_token?: string;
	public dd_rum_proxy?: string;

	// Integration Apps
	public integration_apps_url?: string;

	constructor() {}
}
