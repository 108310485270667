import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { timeout, catchError, takeUntil, tap } from "rxjs/operators";
import { EMPTY } from "rxjs";
import { Component, OnDestroy, Injector } from "@angular/core";
import { IInventory } from "@elevatedsignals/amygoodman";
import { InventoryPageQuery } from "app/shared/eagers";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";

import { GenericCreateComponent } from "../generic/generic-create.component";
import { NewLotCreationSchema } from "../lot/schemas/index";

@Component({
	selector: "inventory-create",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class InventoryCreateComponent
	extends GenericCreateComponent<IInventory>
	implements OnDestroy
{
	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			inventory_product_id: {
				type: "number",
				title: "Product",
				title_translation_key: marker("word_product"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "inventory_products",
						queryString: {
							showArchived: "false",
						},
					},
				],
			},
			inventory_unit_id: {
				type: "number",
				title: "Unit",
				title_translation_key: marker("word_unit"),
				widget: "data-select",
				related_properties: ["inventory_product_id"],
				oneOf: [
					{
						result_type: "inventory_units",
					},
				],
			},
			location_id: {
				type: "number",
				title: "Location",
				title_translation_key: marker("word_location"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "locations",
					},
				],
			},
			inventory_expires: {
				type: "boolean",
				widget: "checkbox",
				title: "Inventory Expires?",
				title_translation_key: marker("form_field_label_inventory_expires"),
				default: false,
			},
			expires: {
				type: "string",
				title: "Expiry Date",
				title_translation_key: marker("form_field_label_expiry_date"),
				widget: "date",
				visibleIf: {
					inventory_expires: [true],
				},
			},

			new_lot: { ...NewLotCreationSchema.new_lot },
			create_new_lot: { ...NewLotCreationSchema.create_new_lot },
			lot_name: { ...NewLotCreationSchema.lot_name },
			lot_id: { ...NewLotCreationSchema.lot_id({ dont_show_expired: "true" }) },
		},
		required: ["inventory_product_id", "inventory_unit_id"],
	};

	specific_field_id: string;

	constructor(
		protected _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _injector: Injector,
	) {
		super(_store);
		this.form_title = "Create a piece of Inventory";
		this.form_title_translation_key = marker(
			"form_title_create_a_piece_of_inventory",
		);
		this.submit_button = "Create";
		this.submit_button_translation_key = marker("word_create");
		this.specific_field_id = this._injector.get("specific_field_id", null);
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	createItem(inventory: Partial<IInventory>) {
		this.loading$.next(true);
		this._itemService
			.add(`inventories`, inventory, InventoryPageQuery)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((addedItem) => {
					this._store.dispatch(
						ItemActions.addSuccess({
							result_type: "inventories",
							addedItem,
							specific_field_id: this.specific_field_id,
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
