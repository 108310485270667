/* jscpd:ignore-start */
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { timeout, catchError, takeUntil, tap } from "rxjs/operators";
import { EMPTY, ReplaySubject } from "rxjs";
import {
	Component,
	ChangeDetectorRef,
	OnInit,
	OnDestroy,
	Injector,
	SimpleChanges,
} from "@angular/core";
import { TranslocoService } from "@jsverse/transloco";
import { DestructionLotDetailQuery } from "app/shared/eagers";
import { Globals } from "app/shared/modules/globals/globals.service";
import { handleObservableError } from "app/shared/utils";
import { SelectionActions } from "app/modules/dashboard/actions/selection.actions";
import { ItemService } from "app/modules/dashboard/services/item.service";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";

import { GenericCreateComponent } from "../generic/generic-create.component";
import {
	fetchNewInventoryTotals,
	getDynamicFormChanges,
	hasInventoryIdsChanged,
	hasPropertyChanged,
	onVendorChange,
	skuIdsToTrack,
} from "../shared";

@Component({
	selector: "inventory-destruction-by-sku",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})

/*
	Inventory Destruction By Sku
*/
export class InventoryDestructionBySkuComponent
	extends GenericCreateComponent<any>
	implements OnInit, OnDestroy
{
	valid$: ReplaySubject<boolean> = new ReplaySubject(1);
	error$: ReplaySubject<string> = new ReplaySubject();

	form_title = "Inventory Destruction By Sku";
	form_title_translation_key = marker("form_title_inventory_destruction_by_sku");
	submit_button = "Create";
	submit_button_translation_key = marker("word_create");
	submit_icon = "plus";
	loading = false;

	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	model: any = {};

	/* jscpd:ignore-start */
	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			destruction_lot_id: {
				type: "number",
				minItems: 1,
				title: "Destruction Lot",
				title_translation_key: marker("word_destruction_lot"),
				widget: "data-select",
				visible: false,
				oneOf: [
					{
						result_type: "destruction_lots",
						pretext: "",
						text_key: ["name", "id"],
						queryString: {
							lot_status: "OPEN",
						},
					},
				],
			},
			sku_id: {
				type: "number",
				title: "SKU",
				title_translation_key: marker("word_sku"),
				widget: "data-select",
				quick_create: false,
				oneOf: [
					{
						result_type: "skus",
						text_key: ["sku"],
					},
				],
			},
			inventory_product_id: {
				title: "Product",
				title_translation_key: marker("word_product"),
				type: "number",
				widget: "data-select",
				quick_create: false,
				selectFirstAndOnly: true,
				related_properties: ["sku_id"],
				oneOf: [
					{
						result_type: "inventory_products",
						text_key: "name",
						value_key: "id",
						queryString: {
							non_zero_inventory: "true",
						},
					},
				],
				visibleIf: {
					allOf: [{ sku_id: ["$EXP$ target.value > 0"] }],
				},
			},
			vendor_id: {
				type: "number",
				title: "Vendor",
				title_translation_key: marker("word_vendor"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "vendors",
						queryString: {
							show_vendor_by_inventory_sku: "true",
						},
					},
				],
				hidden: true,
				related_properties: ["inventory_product_id", "sku_id"],
			},
			batch_id: {
				type: "number",
				title: "Source Batch",
				title_translation_key: marker("form_field_label_source_batch"),
				widget: "data-select",
				quick_create: false,
				related_properties: ["inventory_product_id", "vendor_id", "sku_id"],
				selectFirstAndOnly: true,
				oneOf: [
					{
						result_type: "batches",
						queryString: {
							input: "true",
							dont_show_expired: "true",
							non_zero_inventory: "true",
						},
					},
				],
				visibleIf: {
					allOf: [{ inventory_product_id: ["$EXP$ target.value > 0"] }],
				},
				hidden: true,
			},
			existing_inventory: {
				title: "Inventory Exists in System",
				title_translation_key: marker(
					"form_field_label_inventory_exists_in_system",
				),
				description:
					"Sometimes you'd like to destroy inventory that doesn't yet exist in the system, such as foliage from plants etc.",
				description_translation_key: marker(
					"form_field_description_sometimes_you_would_like_to_destroy_inventory_that_does_not_yet_exist_in_the_system",
				),
				type: "boolean",
				widget: "checkbox",
				default: true,
			},
			lot_id: {
				type: "number",
				title: "Inventory Lot",
				title_translation_key: marker("form_field_label_inventory_lot"),
				widget: "data-select",
				quick_create: false,
				related_properties: [
					"inventory_product_id",
					"vendor_id",
					"batch_id",
					"sku_id",
				],
				oneOf: [
					{
						result_type: "lots",
						queryString: {
							input: "true",
						},
					},
				],
				visibleIf: {
					allOf: [
						{ inventory_product_id: ["$EXP$ target.value > 0"] },
						{ existing_inventory: [true] },
					],
				},
			},
			inventory_ids: {
				type: "array",
				title: "Inventory ID",
				title_translation_key: marker("form_field_label_inventory_id"),
				widget: "data-select",
				quick_create: false,
				multi: true,
				related_properties: [
					"inventory_product_id",
					"batch_id",
					"lot_id",
					"sku_id",
				],
				items: {
					type: "number",
					oneOf: [
						{
							result_type: "inventories",
							text_key: ["name", "id"],
							text_format: "?1 (#?2)",
							value_key: "id",
							queryString: {
								dont_show_expired: "false",
								non_zero_inventory: "true",
							},
						},
					],
				},
				visibleIf: {
					allOf: [
						{
							existing_inventory: true,
						},
						{ inventory_product_id: ["$EXP$ target.value > 0"] },
					],
				},
			},
			remaining_inventory: {
				title: "Use Remaining Inventory",
				title_translation_key: marker("form_field_label_use_remaining_inventory"),
				type: "boolean",
				widget: "checkbox",
				default: false,
				visibleIf: { existing_inventory: true },
			},
			quantity: {
				type: "number",
				title: `Quantity`,
				title_translation_key: marker("word_quantity"),
				visibleIf: {
					oneOf: [
						{
							allOf: [
								{ existing_inventory: true },
								{ remaining_inventory: false },
								{ inventory_product_id: ["$EXP$ target.value > 0"] },
							],
						},
						{
							allOf: [
								{ existing_inventory: false },
								{ inventory_product_id: ["$EXP$ target.value > 0"] },
							],
						},
					],
				},
				width: "50%",
			},
			inventory_unit_id: {
				title: "Inventory Unit",
				title_translation_key: marker("word_inventory_unit"),
				type: "number",
				widget: "data-select",
				quick_create: false,
				shorter_placeholder: true,
				related_properties: ["inventory_product_id", "location_id", "batch_id"],
				oneOf: [
					{
						result_type: "inventory_units",
					},
				],
				visibleIf: {
					oneOf: [
						{
							allOf: [
								{ existing_inventory: true },
								{ remaining_inventory: false },
								{ inventory_product_id: ["$EXP$ target.value > 0"] },
							],
						},
						{
							allOf: [
								{ existing_inventory: false },
								{ inventory_product_id: ["$EXP$ target.value > 0"] },
							],
						},
					],
				},
				width: "50%",
			},
			amount_available: {
				type: "string",
				widget: "string",
				title: "Amount Available",
				readOnly: true,
				visibleIf: {
					allOf: [{ inventory_product_id: [true] }],
				},
			},
			destruction_reason_id: {
				type: "number",
				title: "Destruction Reason",
				title_translation_key: marker("word_destruction_reason"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "destruction_reasons",
					},
				],
			},
			description: {
				type: "string",
				title: "Description",
				title_translation_key: marker("word_description"),
				widget: "textarea",
			},
			timestamp: {
				type: "string",
				title: "Timestamp",
				title_translation_key: marker("word_timestamp"),
				widget: "date",
				hidden: false,
			},
		},
		anyOf: [
			{
				required: [
					"destruction_lot_id",
					"destruction_reason_id",
					"inventory_product_id",
					"sku_id",
					"inventory_unit_id",
					"quantity",
				],
			},
			{
				required: [
					"destruction_lot_id",
					"destruction_reason_id",
					"inventory_product_id",
					"sku_id",
					"remaining_inventory",
				],
			},
		],
	};
	/* jscpd:ignore-end */

	validators = {};

	private prevInventoryProductId: number | undefined;
	private readonly whatChanged: SimpleChanges = {};
	private amount_valid: boolean;
	private readonly vendor_id: number;

	constructor(
		protected _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _cd: ChangeDetectorRef,
		private readonly _globals: Globals,
		private readonly _injector: Injector,
		private readonly _translocoService: TranslocoService,
	) {
		super(_store);
	}

	valid(valid) {
		this.valid$.next(valid);
	}

	ngOnInit() {
		if (this._globals.gmp_enabled) {
			delete (this.schema.properties as any).timestamp;
		}

		const destruction_lot_id = this._injector.get("destruction_lot_id", null);

		if (destruction_lot_id) {
			this.model.destruction_lot_id = destruction_lot_id;
			this.schema.properties.destruction_lot_id.readOnly = true;
		}
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onChanges(model) {
		getDynamicFormChanges(this.whatChanged, model, skuIdsToTrack);
		this.checkAndUpdateInventoryTotals();

		if (!model.inventory_product_id && this.prevInventoryProductId) {
			this.prevInventoryProductId = undefined;
			model.inventory_unit_id = undefined;
		} else if (
			model.inventory_product_id &&
			this.prevInventoryProductId !== model.inventory_product_id
		) {
			this.prevInventoryProductId = model.inventory_product_id;
			model.inventory_unit_id = undefined;
			const firstProductSet = !this.model.inventory_product_id;
			if (this.schema.properties.existing_inventory) {
				model.existing_inventory =
					!this._globals.cultivationInventoryProducts.includes(
						model.inventory_product_id,
					);
			}
			this._itemService
				.fetchItem(`inventory_product`, `${model.inventory_product_id}`, {
					eager: "[vendors]",
				})
				.pipe(
					takeUntil(this.destroyed$),
					timeout(50000),
					catchError((error) => {
						/* eslint no-console: off */
						console.error(error);
						return EMPTY;
					}),
				)
				.subscribe((inventory_product) => {
					if (!firstProductSet || !this.model.inventory_unit_id) {
						this.model.inventory_unit_id = inventory_product.display_unit_id;
					}
					if (this.schema.properties.existing_inventory) {
						this.model.existing_inventory = !(
							this._globals.cultivationInventoryProducts.includes(
								model.inventory_product_id,
							) || inventory_product.non_inventory_destruction
						);
					}

					if (!this.vendor_id) {
						[this.model, this.schema] = onVendorChange(
							this.model,
							this.schema,
							inventory_product,
						);
					}

					this._cd.detectChanges();
				});
		}
	}

	checkAndUpdateInventoryTotals = () => {
		if (
			hasPropertyChanged(this.whatChanged.inventory_product_id) ||
			hasPropertyChanged(this.whatChanged.lot_id) ||
			hasPropertyChanged(this.whatChanged.batch_id) ||
			hasInventoryIdsChanged(this.whatChanged.inventory_ids) ||
			hasPropertyChanged(this.whatChanged.timestamp) ||
			hasPropertyChanged(this.whatChanged.vendor_id) ||
			hasPropertyChanged(this.whatChanged.sku_id)
		) {
			fetchNewInventoryTotals(this.whatChanged, this._itemService).subscribe(
				(availableInventoryAmount) => {
					this.model.amount_available = availableInventoryAmount.content
						.map((availableInventory) => {
							return `${availableInventory.sum.toFixed(2)} ${availableInventory.name}`;
						})
						.join("\n");

					this.amount_valid = availableInventoryAmount.content.length > 0;

					this._cd.detectChanges();
				},
			);
		}
	};

	createItem(destruction_event: any) {
		const item = {
			...destruction_event,
			vendor_id: this.model.vendor_id,
			result_type: "destruction_lot",
		};

		if (
			!destruction_event.remaining_inventory &&
			(!destruction_event.quantity || destruction_event.quantity <= 0)
		) {
			this.error$.next(
				this._translocoService.translate(
					"error_inventory_destruction_must_include_positive_quantity",
				),
			);
			return;
		}

		this.loading$.next(true);
		this._itemService
			.add(
				`destruction_lots/${destruction_event.destruction_lot_id}/inventory`,
				item,
				DestructionLotDetailQuery,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(50000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((_item) => {
					this._store.dispatch(
						SelectionActions.select({
							result_type: "destruction_lots",
							id: this.model.destruction_lot_id,
						}),
					);

					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}
}

/* jscpd:ignore-end */
