/* eslint-disable @typescript-eslint/naming-convention */
import { InjectionToken } from "@angular/core";

import { IPermissions } from "./permissions.interface";

export const APP_PERMISSIONS: IPermissions = {
	FACILITIES_ADD: "facilities.add",
	FACILITIES_VIEW: "facilities.view",
	FACILITIES_EDIT: "facilities.edit",

	USERS_ADD: "users.add",
	USERS_VIEW: "users.view",
	USERS_EDIT: "users.edit",
	USERS_CHANGE_EMAIL: "users.change_email",

	ROLES_ADD: "roles.add",
	ROLES_VIEW: "roles.view",
	ROLES_EDIT: "roles.edit",
	ROLES_ARCHIVE: "roles.archive",

	LOCATIONS_ADD: "locations.add",
	LOCATIONS_VIEW: "locations.view",
	LOCATIONS_EDIT: "locations.edit",

	BATCHES_ADD: "batches.add",
	BATCHES_VIEW: "batches.view",
	BATCHES_EDIT: "batches.edit",
	BATCHES_ACTIONS: "batches.actions",

	PLANTS_ADD: "batches.add",
	PLANTS_VIEW: "batches.view",
	PLANTS_EDIT: "batches.edit",

	CULTIVARS_ADD: "cultivars.add",
	CULTIVARS_VIEW: "cultivars.view",
	CULTIVARS_EDIT: "cultivars.edit",

	SEED_LOTS_ADD: "seed_lots.add",
	SEED_LOTS_VIEW: "seed_lots.view",
	SEED_LOTS_EDIT: "seed_lots.edit",

	SENSORS_ADD: "sensors.add",
	SENSORS_VIEW: "sensors.view",
	SENSORS_EDIT: "sensors.edit",

	HUBS_ADD: "hubs.add",
	HUBS_VIEW: "hubs.view",
	HUBS_EDIT: "hubs.edit",

	READINGS_ADD: "readings.add",
	READINGS_VIEW: "readings.view",
	READINGS_EDIT: "readings.edit",

	THRESHOLDS_ADD: "thresholds.add",
	THRESHOLDS_REMOVE: "thresholds.remove",
	THRESHOLDS_VIEW: "thresholds.view",
	THRESHOLDS_EDIT: "thresholds.edit",

	SUBSCRIPTIONS_ADD: "subscriptions.add",
	SUBSCRIPTIONS_REMOVE: "subscriptions.remove",
	SUBSCRIPTIONS_VIEW: "subscriptions.view",
	SUBSCRIPTIONS_EDIT: "subscriptions.edit",

	NOTIFICATIONS_VIEW: "notifications.view",
	NOTIFICATIONS_EDIT: "notifications.edit",

	RECORDS_ADD: "records.add",
	RECORDS_VIEW: "records.view",
	RECORDS_EDIT: "records.edit",
	RECORDS_LOCK: "records.lock.add",
	RECORDS_UNLOCK: "records.lock.remove",
	RECORDS_VERIFY: "records.verification.add",
	RECORDS_SELF_VERIFY: "records.verification.self_allowed",
	RECORDS_ARCHIVE: "records.remove",

	SCHEMAS_ADD: "schemas.add",
	SCHEMAS_EDIT: "schemas.edit",
	SCHEMAS_VIEW: "schemas.view",
	SCHEMAS_RELEASE: "schemas.release",
	SCHEMAS_UNRELEASE: "schemas.unrelease",

	CUSTOM_FIELDS_SCHEMAS_VIEW: "custom_field_schemas.view",
	CUSTOM_FIELDS_SCHEMAS_EDIT: "custom_field_schemas.edit",

	CUSTOM_FIELDS_VIEW: "custom_fields.view",
	CUSTOM_FIELDS_EDIT: "custom_fields.edit",

	SOPS_ADD: "sops.add",
	SOPS_EDIT: "sops.edit",

	SOP_CATEGORIES_ADD: "sop_categories.add",
	SOP_CATEGORIES_EDIT: "sop_categories.edit",

	REPORT_TYPES_ADD: "report_types.add",
	REPORT_TYPES_VIEW: "report_types.view",
	REPORT_TYPES_EDIT: "report_types.edit",

	REPORTING_CATEGORIES_ADD: "reporting_categories.add",
	REPORTING_CATEGORIES_VIEW: "reporting_categories.view",
	REPORTING_CATEGORIES_EDIT: "reporting_categories.edit",

	DOCUMENT_SETS_ADD: "document_sets.add",
	DOCUMENT_SETS_VIEW: "document_sets.view",
	DOCUMENT_SETS_EDIT: "document_sets.edit",

	FLOORPLANS_ADD: "floorplans.add",
	FLOORPLANS_VIEW: "floorplans.view",
	FLOORPLANS_EDIT: "floorplans.edit",

	DESTRUCTION_LOTS_ADD: "destruction_lots.add",
	DESTRUCTION_LOTS_VIEW: "destruction_lots.view",
	DESTRUCTION_LOTS_EDIT: "destruction_lots.edit",
	DESTRUCTION_LOTS_EDIT_REOPEN: "destruction_lots.edit.qp_reopen",
	DESTRUCTION_LOTS_LOCK: "destruction_lots.lock.add",
	DESTRUCTION_LOTS_UNLOCK: "destruction_lots.lock.remove",
	DESTRUCTION_LOTS_VERIFY: "destruction_lots.verification.add",

	DESTRUCTION_EVENTS_ADD: "destruction_events.add",
	DESTRUCTION_EVENTS_VIEW: "destruction_events.view",
	DESTRUCTION_EVENTS_EDIT: "destruction_events.edit",
	DESTRUCTION_EVENTS_REMOVE: "destruction_events.remove",
	DESTRUCTION_EVENTS_REWEIGH: "destruction_events.reweigh",

	DESTRUCTION_REASONS_ADD: "destruction.reasons.add",
	DESTRUCTION_REASONS_VIEW: "destruction.reasons.view",
	DESTRUCTION_REASONS_EDIT: "destruction.reasons.edit",

	GROWTH_STAGES_ADD: "growth_stages.add",
	GROWTH_STAGES_VIEW: "growth_stages.view",
	GROWTH_STAGES_EDIT: "growth_stages.edit",

	PRODUCTION_STAGES_ADD: "production_stages.add",
	PRODUCTION_STAGES_VIEW: "production_stages.view",
	PRODUCTION_STAGES_EDIT: "production_stages.edit",

	WORK_ORDERS_ADD: "work_orders.add",
	WORK_ORDERS_OPEN: "work_orders.open",
	WORK_ORDERS_REOPEN: "work_orders.reopen",
	WORK_ORDERS_CLOSE: "work_orders.close",
	WORK_ORDERS_VIEW: "work_orders.view",
	WORK_ORDERS_EDIT: "work_orders.edit.edit",
	WORK_ORDERS_ADD_INPUTS: "work_orders.edit.add_inputs",
	WORK_ORDERS_ADD_OUTPUTS: "work_orders.edit.add_outputs",
	WORK_ORDERS_ADD_DESTRUCTION_EVENTS: "work_orders.edit.add_destruction_events",
	WORK_ORDERS_ADD_EQUIPMENT: "work_orders.edit.add_equipment",
	WORK_ORDERS_ADD_RECORD: "work_orders.edit.add_record",
	WORK_ORDERS_LOCK: "work_orders.lock.add",
	WORK_ORDERS_UNLOCK: "work_orders.lock.remove",
	WORK_ORDERS_VERIFY: "work_orders.verification.add",
	WORK_ORDERS_SELF_VERIFY: "work_orders.verification.self_allowed",

	WORK_ORDER_TYPES_ADD: "work_order_types.add",
	WORK_ORDER_TYPES_VIEW: "work_order_types.view",
	WORK_ORDER_TYPES_EDIT: "work_order_types.edit",

	SUBSTANCE_TYPES_ADD: "substance_types.add",
	SUBSTANCE_TYPES_VIEW: "substance_types.view",
	SUBSTANCE_TYPES_EDIT: "substance_types.edit",

	LOCATION_TYPES_ADD: "location_types.add",
	LOCATION_TYPES_VIEW: "location_types.view",
	LOCATION_TYPES_EDIT: "location_types.edit",

	APPLICABLE_PRODUCTS_ADD: "applicable_products.add",
	APPLICABLE_PRODUCTS_VIEW: "applicable_products.view",
	APPLICABLE_PRODUCTS_EDIT: "applicable_products.edit",

	APPLICATION_METHODS_ADD: "application_methods.add",
	APPLICATION_METHODS_VIEW: "application_methods.view",
	APPLICATION_METHODS_EDIT: "application_methods.edit",

	RATE_TYPES_ADD: "rate_types.add",
	RATE_TYPES_VIEW: "rate_types.view",
	RATE_TYPES_EDIT: "rate_types.edit",

	VENDORS_ADD: "vendors.add",
	VENDORS_VIEW: "vendors.view",
	VENDORS_EDIT: "vendors.edit",

	VENDOR_CATEGORIES_ADD: "vendors.categories.add",
	VENDOR_CATEGORIES_VIEW: "vendors.categories.view",
	VENDOR_CATEGORIES_EDIT: "vendors.categories.edit",

	PURCHASE_ORDERS_ADD: "purchase_orders.add",
	PURCHASE_ORDERS_VIEW: "purchase_orders.view",
	PURCHASE_ORDERS_EDIT: "purchase_orders.edit",
	PURCHASE_ORDERS_EDIT_REOPEN: "purchase_orders.edit.reopen",
	PURCHASE_ORDERS_LOCK_ADD: "purchase_orders.lock.add",
	PURCHASE_ORDERS_LOCK_REMOVE: "purchase_orders.lock.remove",
	PURCHASE_ORDERS_VERIFY: "purchase_orders.verification.add",

	SHIPPING_ORDERS_ADD: "shipping_orders.add",
	SHIPPING_ORDERS_VIEW: "shipping_orders.view",
	SHIPPING_ORDERS_EDIT: "shipping_orders.edit",
	SHIPPING_ORDERS_LOCK_ADD: "shipping_orders.lock.add",
	SHIPPING_ORDERS_LOCK_REMOVE: "shipping_orders.lock.remove",

	BATCH_TYPES_ADD: "batch_types.add",
	BATCH_TYPES_VIEW: "batch_types.view",
	BATCH_TYPES_EDIT: "batch_types.edit",

	EQUIPMENTS_ADD: "equipments.add",
	EQUIPMENTS_VIEW: "equipments.view",
	EQUIPMENTS_EDIT: "equipments.edit",

	PRINTERS_ADD: "printers.add",
	PRINTERS_VIEW: "printers.view",
	PRINTERS_EDIT: "printers.edit",

	PRINTER_JOBS_ADD: "printers.jobs.add",
	PRINTER_JOBS_VIEW: "printers.jobs.view",
	PRINTER_JOBS_EDIT: "printers.jobs.edit",

	TAGS_ADD: "tags.add",
	TAGS_VIEW: "tags.view",
	TAGS_EDIT: "tags.edit",

	INVENTORIES_ADD: "inventories.add",
	INVENTORIES_VIEW: "inventories.view",
	INVENTORIES_EDIT: "inventories.edit",
	INVENTORIES_ADJUSTMENT: "inventories.adjustment",

	INVENTORY_PRODUCTS_ADD: "inventory_products.add",
	INVENTORY_PRODUCTS_VIEW: "inventory_products.view",
	INVENTORY_PRODUCTS_EDIT: "inventory_products.edit",

	INVENTORY_CATEGORIES_ADD: "inventory_categories.add",
	INVENTORY_CATEGORIES_VIEW: "inventory_categories.view",
	INVENTORY_CATEGORIES_EDIT: "inventory_categories.edit",

	INVENTORY_UNITS_ADD: "inventory_units.add",
	INVENTORY_UNITS_VIEW: "inventory_units.view",
	INVENTORY_UNITS_EDIT: "inventory_units.edit",

	EQUIVALENT_UNITS_ADD: "equivalent_units.edit",
	EQUIVALENT_UNITS_VIEW: "equivalent_units.edit",
	EQUIVALENT_UNITS_EDIT: "equivalent_units.edit",

	LOTS_ADD: "lots.add",
	LOTS_VIEW: "lots.view",
	LOTS_EDIT: "lots.edit",

	STATUSES_ADD: "statuses.add",
	STATUSES_VIEW: "statuses.view",
	STATUSES_EDIT: "statuses.edit",
	STATUSES_CLEAR: "statuses.clear",

	BILL_OF_MATERIALS_ADD: "bill_of_materials.add",
	BILL_OF_MATERIALS_VIEW: "bill_of_materials.view",
	BILL_OF_MATERIALS_EDIT: "bill_of_materials.edit",

	BULK_ACTIONS_VIEW: "bulk_actions.view",

	SKUS_ADD: "skus.add",
	SKUS_VIEW: "skus.view",
	SKUS_EDIT: "skus.edit",

	REPORTING_PERIODS_ADD: "reporting_period.add",
	REPORTING_PERIODS_VIEW: "reporting_period.view",
	REPORTING_PERIODS_EDIT: "reporting_period.edit",

	PRODUCTION_PLANS_ADD: "production_plans.add",
	PRODUCTION_PLANS_EDIT: "production_plans.edit",
	PRODUCTION_PLANS_VIEW: "production_plans.view",

	BILLING_ADD: "billing.add",
	BILLING_EDIT: "billing.edit",
	BILLING_VIEW: "billing.view",

	REPORT_TEMPLATES_ADD: "report_templates.add",
	REPORT_TEMPLATES_EDIT: "report_templates.edit",
	REPORT_TEMPLATES_VIEW: "report_templates.view",
};

export const PERMISSIONS = new InjectionToken<IPermissions>("app.permissions");
