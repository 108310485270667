import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import {
	Component,
	OnDestroy,
	ChangeDetectorRef,
	Injector,
	OnInit,
} from "@angular/core";
import { IInventory, IPurchaseOrder } from "@elevatedsignals/amygoodman";
import { Globals } from "app/shared/modules/globals/globals.service";
import { dateIsBefore } from "app/shared/time-format";
import { ESValidator } from "app/shared/es-validator";
import { TranslocoService } from "@jsverse/transloco";
import { PurchaseOrderDetailQuery } from "app/shared/eagers";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { catchError, EMPTY, takeUntil, tap, timeout } from "rxjs";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { DevCycleService } from "app/devcycle/devcycle.service";
import { DevCycleKey } from "app/devcycle/devcycleKeys";
import { handleObservableError } from "app/shared/utils";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";

import { SetInventoryStatusSchema } from "../work-order/schemas";

@Component({
	selector: "purchase-order-received-at",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
// Only used for Receive/Ship All Inventory
export class PurchaseOrderReceivedAtComponent
	extends GenericUpdateComponent<IInventory>
	implements OnDestroy, OnInit
{
	parentSubmit;

	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			timestamp: {
				type: "string",
				title: "Date",
				widget: "date",
				title_translation_key: marker("word_date"),
				warning: "The date must be after item was added to this order.",
				warning_translation_key: marker(
					"form_field_warning_the_date_must_be_after_item_was_added_to_this_order",
				),
			},
		},
	};

	validators: Record<string, ESValidator> = {
		"/timestamp": (value, property, form) => {
			this.dateValidatorFailed = false;

			const isValueBefore = dateIsBefore(new Date(value), new Date(this.minDate));
			if (isValueBefore) {
				this.dateValidatorFailed = true;

				const error = {
					code: "INVALID_DATE",
					path: `#${property.path}`,
					message: "The date must be in the past",
					params: ["timestamp"],
				};
				return [error];
			}

			return null;
		},
	};

	statusSchema: any | undefined = SetInventoryStatusSchema();
	statusModel: any = {};

	showInventoryStatus = this._devCycleService.getVariableSignal(
		DevCycleKey.WorkOrderOutputInventoryStatus,
		false,
	);

	private po: IPurchaseOrder;
	private minDate: Date;
	private dateValidatorFailed: boolean;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _injector: Injector,
		private readonly _globals: Globals,
		private readonly _translocoService: TranslocoService,
		private readonly _itemService: ItemService,
		private readonly _devCycleService: DevCycleService,
	) {
		super(_store, _cd);
		this.form_title = "Inventory Received";
		this.form_title_translation_key = marker("form_title_inventory_received");
		this.submit_button = "Mark Received";
		this.submit_button_translation_key = marker("form_button_mark_received");
	}

	valid(val) {
		this.valid$.next(val);

		if (this.dateValidatorFailed) {
			this.valid$.next(false);
		}
	}

	ngOnInit() {
		if (this._globals.gmp_enabled) {
			delete this.schema.properties.timestamp;
		}
		this.po = this._injector.get("purchase_order", undefined);
		if (this.po.is_outgoing) {
			this.form_title = "Inventory Shipped";
			this.form_title_translation_key = marker("form_title_inventory_shipped");
			this.submit_button = "Mark Shipped";
			this.submit_button_translation_key = marker("form_button_mark_shipped");
		}
		this.schema.description = this.po.is_outgoing
			? this._translocoService.translate(
					"form_description_please_confirm_all_ordered_inventory_was_shipped",
				)
			: this._translocoService.translate(
					"form_description_please_confirm_all_ordered_inventory_was_received",
				);
		this.minDate = this._injector.get("minDate", null);
		this.parentSubmit = this._injector.get("onSubmit", null);

		this._cd.detectChanges();
		if (this.po.is_outgoing || !this.showInventoryStatus()) {
			this.statusSchema = undefined;
		}
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	updateItem() {
		this.loading$.next(true);
		this._itemService
			.update(
				`purchase_order`,
				`${this.po.id}/receive_all`,
				{
					timestamp: this.model.timestamp,
					status_id: this.statusSchema ? this.statusModel.status_id : undefined,
				},
				{
					...PurchaseOrderDetailQuery,
					is_outgoing: this.po.is_outgoing ? "true" : "false",
				},
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "purchase_orders",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
