/* jscpd:ignore-start */
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { timeout, catchError, takeUntil, tap } from "rxjs/operators";
import { EMPTY } from "rxjs";
import {
	Component,
	ChangeDetectorRef,
	OnInit,
	OnDestroy,
	Injector,
} from "@angular/core";
import { TranslocoService } from "@jsverse/transloco";
import {
	BatchDetailQuery,
	WorkOrderDestructionEventsQuery,
} from "app/shared/eagers";
import { Globals } from "app/shared/modules/globals/globals.service";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { SelectionActions } from "app/modules/dashboard/actions/selection.actions";
import { ItemKeyType } from "app/modules/dashboard/reducers/selection/keys";
import { IInventoryUnit, MeasureUnit } from "@elevatedsignals/amygoodman";
import { ItemService } from "app/modules/dashboard/services/item.service";
import * as fromDashboard from "app/modules/dashboard/reducers";

import { GenericCreateComponent } from "../generic/generic-create.component";

import { InventoryDestruction } from "./schemas/inventory-destruction-create";
import { InventoryDestructionEzSchema } from "./schemas/inventory-destruction-ez-create";

// @deprecated
@Component({
	selector: "inventory-destruction-ez",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class InventoryDestructionEzComponent
	extends GenericCreateComponent<InventoryDestruction>
	implements OnInit, OnDestroy
{
	schema: any = InventoryDestructionEzSchema();

	facilityWeightUnit = "g";
	private batch_id: number;
	private location_id: number;
	private work_order_id: number;
	private work_order_type_id: number;
	private destruction_lot_id: number;
	private result_type: "destruction_lot" | "work_order" | "batch";
	private prevInventoryProductId: number | undefined;
	private prevMeasureUnit: MeasureUnit | undefined;
	private readonly measure_setting: string;
	private measure_setting_fallback: string;

	constructor(
		protected _store: Store<fromDashboard.State>,
		private readonly _injector: Injector,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
		private readonly _globals: Globals,
		private readonly _translocoService: TranslocoService,
	) {
		super(_store);
		this.form_title = "Create a Destruction Event";
		this.form_title_translation_key = marker(
			"form_title_create_a_destruction_event",
		);
		this.submit_button = "Create Destruction Event";
		this.submit_button_translation_key = marker(
			"form_button_create_destruction_event",
		);
		this.submit_icon = "plus";
	}

	ngOnInit() {
		this.batch_id = this._injector.get("batch_id", null);
		this.location_id = this._injector.get("location_id", null);
		this.work_order_type_id = this._injector.get("work_order_type_id", null);
		this.work_order_id = this._injector.get("work_order_id", null);
		this.destruction_lot_id = this._injector.get("destruction_lot_id", null);
		this.result_type = this._injector.get("result_type", "destruction_lot");

		const inventory_product_id = this._injector.get("inventory_product_id", null);
		const inventory_unit_id = this._injector.get("inventory_unit_id", null);
		const quantity = this._injector.get("quantity", null);
		const destruction_reason_id = this._injector.get(
			"destruction_reason_id",
			null,
		);
		if (this._globals.gmp_enabled) {
			delete this.schema.properties.timestamp;
		}

		this.facilityWeightUnit = this._injector.get("facilityWeightUnit", "g");

		this.model = {
			...this.model,
			batch_id: this.batch_id,
			consumptionSource: this.location_id ? "Location" : "Batch",
			location_id: this.location_id,
			destruction_lot_id: this.destruction_lot_id,
			work_order_id: this.work_order_id,
		};
		this.schema.properties = {
			...this.schema.properties,
			...(this.work_order_id && {
				inventory_product_id: {
					...this.schema.properties.inventory_product_id,
					info_text: this._translocoService.translate(
						"inventory_destruction_not_seeing_products_expected_check_work_order_type_settings",
					),
				},
			}),
			consumptionSource: {
				...this.schema.properties.consumptionSource,
				default: this.location_id ? "Location" : "Batch",
				readOnly: this.batch_id || this.location_id,
			},
			batch_id: {
				...this.schema.properties.batch_id,
				default: this.batch_id ? this.batch_id : null,
				readOnly: this.batch_id,
				...(this.work_order_id && {
					oneOf: [
						{
							result_type: "batches",
							queryString: { work_order_id: this.work_order_id },
						},
					],
				}),
			},
			destruction_lot_id: {
				...this.schema.properties.destruction_lot_id,
				default: this.destruction_lot_id ? this.destruction_lot_id : null,
				readOnly: this.destruction_lot_id,
			},
			location_id: {
				...this.schema.properties.location_id,
				default: this.location_id ? this.location_id : null,
				readOnly: this.location_id,
			},
			work_order_id: {
				...this.schema.properties.work_order_id,
				default: this.work_order_id ? this.work_order_id : null,
				hidden: !this.work_order_id,
				readOnly: this.work_order_id,
			},
		};

		if (this.work_order_id) {
			this.schema.properties.inventory_product_id.oneOf[0].queryString = {
				destruction_inventory_products_for_work_order_type_id:
					this.work_order_type_id,
			};
		}

		if (inventory_product_id) {
			this.model = {
				...this.model,
				inventory_product_id,
			};
			this.prevInventoryProductId = inventory_product_id;
			this.schema.properties.inventory_product_id.readOnly = true;
		}

		if (inventory_unit_id) {
			this.model = {
				...this.model,
				inventory_unit_id,
			};
		}

		if (quantity) {
			this.model = { ...this.model, quantity };
		}

		if (destruction_reason_id) {
			this.model = { ...this.model, destruction_reason_id };
		}

		this._cd.detectChanges();
	}

	ngOnDestroy() {
		// In the form schema, none of the properties have readOnly as true by default.
		// Delete readOnly property from the schema to avoid wrong data selects being selected by default.
		for (const [key, value] of Object.entries(this.schema.properties)) {
			const property = value as any;
			if (property.readOnly) {
				delete this.schema.properties[key].readOnly;
			}
		}

		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onChanges(model) {
		if (!model.inventory_product_id && this.prevInventoryProductId) {
			this.prevInventoryProductId = undefined;
			model.inventory_unit_id = undefined;
		} else if (
			model.inventory_product_id &&
			this.prevInventoryProductId !== model.inventory_product_id
		) {
			this.prevInventoryProductId = model.inventory_product_id;
			model.inventory_unit_id = undefined;
			this.setUnit(model.inventory_product_id);
		}

		if (
			model.measure_unit &&
			model.measure_unit !== this.prevMeasureUnit &&
			[MeasureUnit.Mass, MeasureUnit.Quantity].includes(model.measure_unit)
		) {
			this.measure_setting_fallback = this.measure_setting || "batch";
			const units = this._globals.getFacilityUnit(
				model.measure_unit,
				this.measure_setting_fallback,
			);
			this.schema.properties.quantity.title = `Value (${units})`;
			this.schema.properties.quantity.title_translation_params = { units };
			this.prevMeasureUnit = model.measure_unit;
			model.inventory_unit_id = undefined;
			if (model.inventory_product_id) {
				this.setUnit(model.inventory_product_id);
			}
			this._cd.detectChanges();
		} else if (!model.measure_unit && this.prevMeasureUnit) {
			this.prevMeasureUnit = undefined;
			model.measure_unit = undefined;
		}
	}

	dispatchItem(item: any) {
		if (this.result_type === "destruction_lot") {
			this._store.dispatch(
				SelectionActions.select({
					result_type: "destruction_lots",
					id: this.model.destruction_lot_id,
				}),
			);
		} else {
			this._store.dispatch(
				ItemActions.updateSuccess({
					updatedItem: item,
					result_type: this.pluralize(this.result_type),
				}),
			);
		}
	}

	createItem(destruction_event: InventoryDestruction) {
		const isCultivationProduct =
			this._globals.cultivationInventoryProducts.includes(
				destruction_event.inventory_product_id,
			);
		let query: any = null;
		switch (this.result_type) {
			case "work_order":
				query = WorkOrderDestructionEventsQuery;
				break;
			case "batch":
				query = BatchDetailQuery;
				break;
			case "destruction_lot":
			default:
				query = {};
		}
		const item = {
			...destruction_event,
			existing_inventory: !isCultivationProduct,
			...(isCultivationProduct && { non_existing_inventory: true }),
			result_type: this.result_type,
		};

		// Verify form has value if remaining_inventory is NOT selected
		if (!destruction_event.quantity || destruction_event.quantity <= 0) {
			this.error$.next(
				this._translocoService.translate(
					"error_inventory_destruction_must_include_positive_quantity",
				),
			);
			return;
		}

		this.loading$.next(true);
		this._itemService
			.add(
				`destruction_lots/${destruction_event.destruction_lot_id}/inventory`,
				item,
				query,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(50000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((item) => {
					this.dispatchItem(item);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}

	setUnit(inventory_product_id: number) {
		this._itemService
			.fetchItem(`inventory_product`, `${inventory_product_id}`, {
				eager: "[inventory_units, virtual_products.[inventory_units]]",
			})
			.pipe(
				takeUntil(this.destroyed$),
				timeout(50000),
				catchError((error) => {
					/* eslint no-console: off */
					console.error(error);
					return EMPTY;
				}),
			)
			.subscribe((inventory_product) => {
				const productUnits: IInventoryUnit[] = inventory_product.inventory_units;
				const measureUnitType = this.model.measure_unit;
				let unitIdToUse: number | undefined = inventory_product.display_unit_id;
				if (measureUnitType === MeasureUnit.Quantity) {
					for (const virtualProduct of inventory_product.virtual_products ?? []) {
						for (const unit of virtualProduct.inventory_units ?? []) {
							productUnits.push(unit);
						}
					}
					const quantityUnit = productUnits.find(
						(unit) => unit.name.toLowerCase() === "ea",
					);
					if (quantityUnit) {
						unitIdToUse = quantityUnit.id;
					}
				}

				this.model.inventory_unit_id = unitIdToUse;
				this._cd.detectChanges();
			});
	}

	private pluralize(
		item: "destruction_lot" | "work_order" | "batch",
	): ItemKeyType {
		if (item === "destruction_lot") {
			return "destruction_lots";
		} else if (item === "work_order") {
			return "work_orders";
		}

		return "batches";
	}
}
/* jscpd:ignore-end */
