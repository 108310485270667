import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { timeout, catchError, takeUntil, tap } from "rxjs/operators";
import { EMPTY } from "rxjs";
import { Component, OnDestroy, Injector } from "@angular/core";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { IReportTemplate } from "@elevatedsignals/amygoodman";

import { GenericCreateComponent } from "../generic/generic-create.component";

import { reportColumns } from "./columns";

@Component({
	selector: "report-template-create",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class ReportTemplateCreateComponent
	extends GenericCreateComponent<IReportTemplate>
	implements OnDestroy
{
	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			report_type: {
				type: "string",
				title: "Report Type",
				title_translation_key: marker("word_report_type"),
				readOnly: true,
			},
			name: {
				type: "string",
				title: "Name",
				title_translation_key: marker("word_name"),
				warning: "Warning: Report Template name already in use.",
				warning_translation_key: marker(
					"form_field_warning_report_template_name_already_in_use",
				),
			},
		},
		required: ["report_type", "name", "columns"],
	};

	report_type: string;
	specific_field_id: string | null;
	validators = {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		"/name": (value, property, form) => {
			if (value !== "") {
				if (this.names && this.names.includes(value.trim())) {
					return { name: {} };
				}
			}
			return null;
		},
	};

	private names;

	constructor(
		protected _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _injector: Injector,
	) {
		super(_store);

		this.form_title = "Create Report Template";
		this.form_title_translation_key = marker("form_title_create_report_template");
		this.submit_button = "Create";
		this.submit_button_translation_key = marker("word_create");

		this.specific_field_id = this._injector.get("specific_field_id", null);

		this.report_type = localStorage.getItem("currentReportType") ?? "";
		this.updateSchema();
	}

	setThisModelValue = (key: string, value: any) => {
		this.model = {
			...this.model,
			[key]: value,
		};
	};

	updateSchema() {
		const reportColumn = reportColumns[this.report_type];
		if (!reportColumn) {
			return;
		}

		this.schema.properties = {
			...this.schema.properties,
			columns: {
				type: "array",
				title: "Columns",
				title_translation_key: marker("word_columns"),
				widget: "select",
				items: {
					type: "string",
					oneOf: reportColumn.columns.map((column) => ({
						name: column,
						value: column,
						enum: [column],
					})),
				},
				default: reportColumn.defaultColumns,
			},
		};
		this.setThisModelValue("report_type", this.report_type);
	}

	ngOnInit() {
		// Get a list of all names currently in use.
		this.loading$.next(true);
		this._itemService
			.fetchItem(`report_templates/fetch/names`, "", { col: "name" })
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.subscribe((value) => {
				// For duplicate name value validation
				this.names = value;
			});
		this.loading$.next(false);
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	createItem(report_template: Partial<IReportTemplate>) {
		this.loading$.next(true);
		this._itemService
			.add(`report_templates`, report_template)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((addedItem) => {
					this._store.dispatch(
						ItemActions.addSuccess({
							addedItem,
							result_type: "report_templates",
							...(this.specific_field_id && {
								specific_field_id: this.specific_field_id,
							}),
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
