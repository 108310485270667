import {
	IWorkOrder,
	IWorkOrderOutput,
	IWorkOrderPendingInventory,
	IInventory,
	IInventoryProduct,
} from "@elevatedsignals/amygoodman";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { TranslocoService } from "@jsverse/transloco";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { timeout, catchError, takeUntil, tap } from "rxjs/operators";
import { EMPTY, Subscription } from "rxjs";
import {
	Component,
	ChangeDetectorRef,
	OnInit,
	OnDestroy,
	Injector,
	SimpleChanges,
	SimpleChange,
} from "@angular/core";
import { Globals } from "app/shared/modules/globals/globals.service";
import { WorkOrderOutputQuery } from "app/shared/eagers";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";

import { GenericCreateComponent } from "../generic/generic-create.component";
import { getDynamicFormChanges, onVendorChange } from "../shared";

import {
	IGeneralInventoryOutput,
	GeneralInventorOutputDynamicSchema,
	SetInventoryStatusSchema,
	GeneralInventoryOutputDynamicSchemaWithSku,
} from "./schemas";

interface AddOutputConfig {
	work_order_id: number;
	work_order_type_id: number;
	pending_inventory_id: number;
	use_work_order_location_output: boolean;
	work_order_location_id: number;
	use_existing_inventory_output: boolean;
	use_existing_lot_output: boolean;
	duplicate_timestamp: boolean;
	output_inventory_status: boolean;
	default_output_inventory_status_id: number;
	add_by_sku_id: boolean;
	batch_id: number;
}

@Component({
	selector: "work-order-add-output-gi-dynamic",
	templateUrl: "wo-output-form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class WorkOrderAddOutputDynamicGIComponent
	extends GenericCreateComponent<IWorkOrder>
	implements OnInit, OnDestroy
{
	request: Subscription;
	formSchema: any = {};
	secondarySchema: any | undefined = undefined;
	secondaryModel: any = {};
	statusSchema: any | undefined = undefined;
	statusModel: any = {};

	pending_inventory: IWorkOrderPendingInventory;
	work_order$ = this._store.select(fromDashboard.getSelectedWorkOrder);
	work_order: IWorkOrder;

	private readonly whatChanged: SimpleChanges = {};
	private readonly outputConfig: AddOutputConfig;
	private duplicate: IWorkOrderOutput | null = null;
	private firstPass = true;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
		private readonly _globals: Globals,
		private readonly _injector: Injector,
		private readonly _translocoService: TranslocoService,
	) {
		super(_store);

		this.outputConfig = {
			work_order_id: this._injector.get("work_order_id", null),
			pending_inventory_id: this._injector.get("pending_inventory_id", null),
			work_order_type_id: this._injector.get("work_order_type_id", null),
			use_work_order_location_output: this._injector.get(
				"use_work_order_location_output",
				false,
			),
			work_order_location_id: this._injector.get("work_order_location_id", null),
			use_existing_inventory_output: this._injector.get(
				"use_existing_inventory_output",
				false,
			),
			use_existing_lot_output: this._injector.get(
				"use_existing_lot_output",
				false,
			),
			duplicate_timestamp: this._injector.get("duplicate_timestamp", false),
			output_inventory_status: this._injector.get(
				"output_inventory_status",
				false,
			),
			default_output_inventory_status_id: this._injector.get(
				"default_output_inventory_status_id",
				null,
			),
			add_by_sku_id: this._injector.get("add_by_sku_id", false),
			batch_id: this._injector.get("batch_id", null),
		};
		this.model.id = this.outputConfig.work_order_id;
		this.form_title = `Add Output Items to Work Order #${this.outputConfig.work_order_id}`;
		this.form_title_translation_key = marker(
			"form_title_add_output_items_to_work_order",
		);
		this.form_title_translation_params = {
			work_order_id: `#${this.outputConfig.work_order_id}`,
		};
		this.submit_button = "Add";
		this.submit_button_translation_key = marker("word_add");
		this.submit_icon = "plus";
		this.schema = this.outputConfig.add_by_sku_id
			? GeneralInventoryOutputDynamicSchemaWithSku
			: GeneralInventorOutputDynamicSchema();
		if (this.outputConfig.use_existing_inventory_output) {
			this.schema.properties.new_inventory.default = "Use Existing Inventory";
			this.model.new_inventory = "Use Existing Inventory";
		}
		if (this.outputConfig.use_existing_lot_output) {
			this.schema.properties.new_lot.default = "Use Existing Lot";
			this.model.new_lot = "Use Existing Lot";
		}

		this.schema.properties.inventory_product_id.oneOf[0].queryString = {
			// Retain the queryString already in this schema
			...this.schema.properties.inventory_product_id.oneOf[0].queryString,
			output_inventory_products_for_work_order_type_id:
				this.outputConfig.work_order_type_id,
		};
		if (this.outputConfig.output_inventory_status) {
			this.statusSchema = SetInventoryStatusSchema();
			this.statusModel.status_id =
				this.outputConfig.default_output_inventory_status_id;
		}
	}

	ngOnInit() {
		this.duplicate = this._injector.get("duplicate", null);
		if (this.duplicate) {
			this.model = {
				...this.model,
				id: this.duplicate.work_order_id,
				// eslint-disable-next-line no-warning-comments
				// TODO: Add work order type setting for customizing this behavior
				inventory_product_id: this.duplicate.inventory.inventory_product_id,
				batch_id: this.duplicate.batch_id,
				location_id: this.duplicate.location_id,
				inventory_id: this.outputConfig.use_existing_inventory_output
					? this.getEntityInventoryId(this.duplicate.inventory)
					: null,
				lot_id: this.outputConfig.use_existing_lot_output
					? this.duplicate.inventory.lot_id
					: null,
				sku_id: this.duplicate.inventory.sku?.id,
			};
			if (
				this.duplicate.inventory.measurements &&
				this.duplicate.inventory.measurements.length > 0
			) {
				this.model = {
					...this.model,
					quantity: -1 * this.duplicate.inventory.measurements[0]?.value!,
					timestamp: this.duplicate.inventory.measurements[0]?.timestamp,
				};
			}

			// clear timestamp for GMP
			if (this._globals.gmp_enabled) {
				delete this.model.timestamp;
			}
		}
		// This is not a dupe, so reset the readonly flags on batch_id and location_id
		else {
			this.schema.properties.batch_id.readOnly = false;
			this.schema.properties.location_id.readOnly = false;
		}

		if (this.outputConfig.pending_inventory_id) {
			this._itemService
				.fetchItem(
					`pending_inventory/${this.outputConfig.pending_inventory_id}`,
					"",
				)
				.pipe(takeUntil(this.destroyed$))
				.pipe(
					timeout(10000),
					catchError((error) => {
						this.error$.next(handleObservableError(error));
						this.loading$.next(false);
						return EMPTY;
					}),
				)
				.subscribe((pending_inventory) => {
					this.pending_inventory = pending_inventory;
					this.model = {
						...this.model,
						inventory_product_id: this.pending_inventory.inventory_product_id,
						inventory_unit_id: this.pending_inventory.inventory_unit_id,
						quantity: this.pending_inventory.quantity,
						pending_inventory_id: this.pending_inventory.id,
						// will not work as plant_count_create & plant_count_use are hidden by default
						...(this.pending_inventory.plants && {
							plant_count_create: this.pending_inventory.quantity,
							plant_count_use: this.pending_inventory.quantity,
						}),
					};
					this.schema.properties = {
						...this.schema.properties,
					};

					this._cd.detectChanges();
				});
		}

		if (this._globals.gmp_enabled) {
			this.schema.properties.timestamp.hidden = true;
		}
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onChanges(model) {
		getDynamicFormChanges(this.whatChanged, model, [
			"inventory_product_id",
			"inventory_id",
		]);
		this.autoSelectForm(model);
	}

	getCustomFields(type, entity_id?): any {
		return this._itemService.fetchItem("custom_field_model", `${type}`, {
			entity_id: entity_id.toString(),
		});
	}

	createItem(work_order_output: IGeneralInventoryOutput) {
		// Verify form has quantity
		if (
			work_order_output.inventory_product_id &&
			(!work_order_output.quantity || work_order_output.quantity <= 0)
		) {
			this.error$.next(
				this._translocoService.translate(
					"error_work_order_input_must_include_positive_quantity",
				),
			);
			return;
		}
		this.loading$.next(true);

		let update: any = {
			timestamp: work_order_output.timestamp,
			destination_location_id: work_order_output.destination_location_id,
			pending_inventory_id: this.outputConfig.pending_inventory_id,
			type: "Inventory",
			inventory_product_id: work_order_output.inventory_product_id,
			inventory_unit_id: work_order_output.inventory_unit_id,
			// remaining_inventory: work_order_output.remaining_inventory,
			inventory_id: work_order_output.inventory_id,
			create_new_inventory: work_order_output.create_new_inventory,
			inventory_name: work_order_output.inventory_name,
			lot_id: work_order_output.lot_id,
			create_new_lot: work_order_output.create_new_lot,
			lot_name: work_order_output.lot_name,
			source: work_order_output.destination,
			location_id: work_order_output.location_id,
			batch_id: work_order_output.batch_id,
			value: work_order_output.quantity,
			sku_id: work_order_output.sku_id,
			status_id: this.statusSchema ? this.statusModel.status_id : undefined,
			vendor_id: this.model.vendor_id,
		};

		// Create Custom Field Model
		if (this.secondarySchema) {
			const custom_fields = {
				custom_field_schema_id: this.formSchema.id, // custom_field_schema_id
				type: this.formSchema.type,
				jsonModel: this.secondaryModel,
			};
			update = {
				...update,
				custom_fields,
			};
		}

		this._itemService
			.add(
				`work_order/${work_order_output.id}/outputs`,
				update,
				WorkOrderOutputQuery,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "work_orders",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}

	getEntityInventoryId(inventory: IInventory | undefined) {
		if (!inventory?.measurements || inventory.measurements.length === 0) {
			return undefined;
		}

		return inventory.measurements[0]?.debit_event?.inventory?.id;
	}

	autoSelectForm(model: IGeneralInventoryOutput) {
		if (this.itemChanged(this.whatChanged.inventory_product_id)) {
			this.inventoryProductChanged(model);
		} else if (this.itemChanged(this.whatChanged.inventory_id)) {
			this.inventoryChanged(model);
		}
	}

	itemChanged = (change: SimpleChange | undefined): boolean => {
		if (!change || change.currentValue === change.previousValue) {
			return false;
		}
		return true;
	};

	inventoryProductChanged = (model: IGeneralInventoryOutput) => {
		let inventory_unit_id: number | null = model.inventory_unit_id ?? null;
		if (this.whatChanged.inventory_product_id!.currentValue) {
			this._itemService
				.fetchItem(`inventory_product`, `${model.inventory_product_id}`, {
					eager: "[vendors]",
				})
				.pipe(
					takeUntil(this.destroyed$),
					timeout(50000),
					catchError((error) => {
						/* eslint no-console: off */
						console.error(error);
						return EMPTY;
					}),
				)
				.subscribe((inventory_product: IInventoryProduct) => {
					inventory_unit_id = inventory_product.display_unit_id;
					this.model.inventory_unit_id = inventory_unit_id;
					if (inventory_product.require_batch) {
						this.schema.properties.batch_id.hidden = false;
						if (!this.model.batch_id) {
							this.model.batch_id = this.outputConfig.batch_id;
						}

						this.schema.properties.location_id.visibleIf = {
							allOf: [{ batch_id: ["$EXP$ target.value > 0"] }],
						};
						this.schema.properties.new_lot.visibleIf = {
							allOf: [{ batch_id: ["$EXP$ target.value > 0"] }],
						};
						this.schema.properties.new_inventory.visibleIf = {
							allOf: [{ batch_id: ["$EXP$ target.value > 0"] }],
						};
					} else {
						this.schema.properties.batch_id.hidden = true;
						this.schema.properties.location_id.visibleIf = {
							allOf: [{ inventory_product_id: ["$EXP$ target.value > 0"] }],
						};
						this.schema.properties.new_lot.visibleIf = {
							allOf: [{ inventory_product_id: ["$EXP$ target.value > 0"] }],
						};
						this.schema.properties.new_inventory.visibleIf = {
							allOf: [{ inventory_product_id: ["$EXP$ target.value > 0"] }],
						};

						if (
							!this.model.location_id &&
							this.outputConfig.use_work_order_location_output &&
							this.duplicate === null
						) {
							this.model.location_id = this.outputConfig.work_order_location_id;
						}
					}

					[this.model, this.schema] = onVendorChange(
						this.model,
						this.schema,
						inventory_product,
					);

					let inventoryUUID = inventory_product.uuid;
					if (this.duplicate) {
						inventoryUUID = this.duplicate.inventory.inventory_product?.uuid!;
						// ESS-7860 - If we are duplicating an output, we need to set the vendor_id and inventory_unit_id here
						if (this.firstPass) {
							this.firstPass = false;
							this.model = {
								...this.model,
								vendor_id: this.duplicate.inventory.vendor_id,
								inventory_unit_id: this.duplicate.inventory.inventory_unit_id,
							};
						}
					}

					// append or copy custom fields to sidebar
					this.getCustomFields(
						inventoryUUID,
						this.duplicate ? this.getEntityInventoryId(this.duplicate.inventory) : "",
					)
						.pipe(
							catchError(() => {
								this.secondaryModel = {};
								this.secondarySchema = undefined;
								this._cd.detectChanges();
								return EMPTY;
							}),
						)
						.subscribe((item) => {
							this.formSchema = JSON.parse(JSON.stringify(item));
							if (Object.keys(this.formSchema?.schema?.properties).length) {
								this.secondarySchema = {
									title: this.formSchema.name ?? "Product Fields",
									type: "object",
									properties: {
										...this.formSchema.schema.properties,
									},
									...(this.formSchema.schema.required && {
										required: [...this.formSchema.schema.required],
									}),
								};

								if (this.formSchema?.model?.length > 0) {
									this.secondaryModel = this.formSchema.model[0].field_model;
								}
							} else {
								this.secondarySchema = undefined;
							}

							// If this form is output by SKU, we need to manually update the inventory_unit_id
							if (this.outputConfig.add_by_sku_id) {
								this.model = {
									...this.model,
									inventory_unit_id,
								};
							}
							this._cd.detectChanges();
						});
				});
		} else {
			this.model.inventory_unit_id = undefined;
		}
	};

	inventoryChanged = (model: IGeneralInventoryOutput) => {
		this.schema.properties.batch_id.readOnly = Boolean(
			this.whatChanged.inventory_id!.currentValue,
		);
		this.schema.properties.location_id.readOnly = Boolean(
			this.whatChanged.inventory_id!.currentValue,
		);
		if (this.whatChanged.inventory_id!.currentValue) {
			this._itemService
				.fetchItem(`inventory`, `${model.inventory_id}`)
				.pipe(
					takeUntil(this.destroyed$),
					timeout(50000),
					catchError((error) => {
						/* eslint no-console: off */
						console.error(error);
						return EMPTY;
					}),
				)
				.subscribe((inventory) => {
					this.model.batch_id = inventory.batch_id;
					this.model.location_id = inventory.location_id;
					this.model.inventory_id = inventory.id;
					this._cd.detectChanges();
				});
		}
	};
}
