import {
	Component,
	Injector,
	ChangeDetectorRef,
	OnDestroy,
	OnInit,
} from "@angular/core";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import {
	FuiActiveModal,
	FuiModalService,
} from "@elevatedsignals/ngx-fomantic-ui";
import { timeout, catchError, tap, takeUntil } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { EMPTY } from "rxjs";
import { IPrinterJob } from "@elevatedsignals/amygoodman";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";

import { GenericCreateComponent } from "../generic/generic-create.component";
import { LabelPreviewModal, PreviewPrintJobConfig } from "../../modals";

@Component({
	selector: "custom-printer-job-create-po-inventory",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})

// This custom printer job creation form helps to facilitate inventory labels
// printed from the context of a purchase order
export class CustomPrinterJobCreateComponentPOInventory
	extends GenericCreateComponent<IPrinterJob>
	implements OnDestroy, OnInit
{
	// Set the print options to these if using a shipping order
	shippingOrderInventoryPrintOptions = [
		{
			name: "Print Label for one shipped item in shipping order inventory",
			name_translation_key: marker(
				"form_field_value_print_label_for_one_shipped_item_in_shipping_order_inventory",
			),
			value: "ONE_LINE_ITEM",
			enum: ["ONE_LINE_ITEM"],
		},
		{
			name: "Print Labels for all shipped items in shipping order inventory",
			name_translation_key: marker(
				"form_field_value_print_labels_for_all_shipped_items_in_shipping_order_inventory",
			),
			value: "ALL_LINE_ITEMS",
			enum: ["ALL_LINE_ITEMS"],
		},
	];

	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			purchase_order_id: {
				type: "number",
				title: "Purchase Order",
				title_translation_key: marker("word_purchase_order"),
				widget: "data-select",
				quick_create: false,
				readOnly: true,
				oneOf: [
					{
						result_type: "purchase_orders",
					},
				],
			},
			// Holds the inventory reference by 'purchase_order_inventory' table for the po
			purchase_order_inventory_id: {
				type: "number",
				title: "Purchase Order",
				title_translation_key: marker("word_inventory"),
				widget: "number",
				hidden: true,
			},
			purchase_order_inventory_label_options: {
				type: "string",
				title: "Purchase Order Inventory Print Options",
				title_translation_key: marker(
					"form_field_label_purchase_order_inventory_print_options",
				),
				widget: "radio",
				readOnly: true,
				oneOf: [
					{
						name: "Print Label for one received item in purchase order inventory",
						name_translation_key: marker(
							"form_field_value_print_label_for_one_received_item_in_purchase_order_inventory",
						),
						value: "ONE_LINE_ITEM",
						enum: ["ONE_LINE_ITEM"],
					},
					{
						name: "Print Labels for all received items in purchase order inventory",
						name_translation_key: marker(
							"form_field_value_print_labels_for_all_received_items_in_purchase_order_inventory",
						),
						value: "ALL_LINE_ITEMS",
						enum: ["ALL_LINE_ITEMS"],
					},
				],
			},
			purchase_order_measure_event_display_text: {
				type: "string",
				title: "Printing labels for",
				title_translation_key: marker("form_field_label_printing_labels_for"),
				widget: "string",
				readOnly: true,
			},
			// Holds the measure event id specific to the line item user printed
			purchase_order_measure_event_id: {
				type: "number",
				title: "Measure Event",
				title_translation_key: marker("word_measure_event"),
				widget: "number",
				hidden: true,
			},
			printer_id: {
				type: "number",
				title: "Printer",
				title_translation_key: marker("word_printer"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "printers",
					},
				],
			},
			printer_job_template_id: {
				type: "number",
				title: "Printer Job Template",
				title_translation_key: marker("word_printer_job_template"),
				widget: "data-select",
				related_properties: ["printer_id", "inventory_template"],
				oneOf: [
					{
						result_type: "printer_job_templates",
						queryString: {
							include_default_templates: false,
						},
					},
				],
				visibleIf: {
					allOf: [{ printer_id: [true] }],
				},
				selectFirstAndOnly: true,
			},
			inventory_template: {
				type: "boolean",
				widget: "checkbox",
				hidden: true,
				default: true,
			},
		},
		required: ["printer_id", "printer_job_template_id"],
	};

	validators = {};

	model: any = {};

	showOutputLabelRange = false;

	previewModalOpen = false;
	refreshModal: FuiActiveModal<string, unknown, void>;

	constructor(
		protected _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _injector: Injector,
		private readonly _cd: ChangeDetectorRef,
		private readonly _modalService: FuiModalService,
	) {
		super(_store);
		this.form_title = "Create a Printer Job";
		this.form_title_translation_key = marker("form_title_create_a_printer_job");
		this.submit_button = "Preview Job";
		this.submit_button_translation_key = marker("form_button_preview_job");
	}

	ngOnInit() {
		const printer_id: number | null = this._injector.get("printer_id", null);
		const purchase_order_id: string | null = this._injector.get(
			"purchase_order_id",
			null,
		);

		const purchase_order_inventory_id: string | null = this._injector.get(
			"purchase_order_inventory_id",
			null,
		);
		const purchase_order_measure_event_id: string | null = this._injector.get(
			"measure_event_id",
			null,
		);

		const purchase_order_inventory_label_options: string | null =
			this._injector.get("purchase_order_inventory_label_options", null);

		const purchase_order_measure_event_display_text: string | null =
			this._injector.get("purchase_order_measure_event_display_text", null);

		const is_outgoing: boolean = this._injector.get("is_outgoing", false);

		// If outgoing, this is a shipping order so update the relevant form labels and options to reflect that
		if (is_outgoing) {
			// Entity Selection
			this.schema.properties.purchase_order_id.title = "Shipping Order";
			this.schema.properties.purchase_order_id.title_translation_key = marker(
				"word_shipping_order",
			);

			// Print options
			this.schema.properties.purchase_order_inventory_label_options.title =
				"Shipping Order Inventory Print Options";
			this.schema.properties.purchase_order_inventory_label_options.title_translation_key =
				marker("form_field_label_shipping_order_inventory_print_options");
			this.schema.properties.purchase_order_inventory_label_options.oneOf = [
				...this.shippingOrderInventoryPrintOptions,
			];
		}

		this.model = {
			...this.model,
			...(printer_id && { printer_id }),
			...(purchase_order_id && { purchase_order_id }),
			...(purchase_order_inventory_id && { purchase_order_inventory_id }),
			...(purchase_order_measure_event_id && { purchase_order_measure_event_id }),
			...(purchase_order_inventory_label_options && {
				purchase_order_inventory_label_options,
			}),
			// Brought into the model in order to show it, but does not need to be sent to backend
			...(purchase_order_measure_event_display_text && {
				purchase_order_measure_event_display_text,
			}),
		};

		this._cd.detectChanges();
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onSubmit() {
		const printer_job: Partial<IPrinterJob> = {
			...this.model,
		};
		this.previewAndCreateItem(printer_job, true);
	}

	openPreviewModal(printPreviewJobConfig: PreviewPrintJobConfig) {
		this._modalService
			.open(new LabelPreviewModal(printPreviewJobConfig))
			.onApprove(() => {
				const printer_job: Partial<IPrinterJob> = {
					...this.model,
				};
				this.previewAndCreateItem(printer_job, false);
			});
	}

	previewAndCreateItem(
		printer_job: Partial<IPrinterJob>,
		preview_only: boolean,
	) {
		this.loading$.next(true);

		// Get the printer label template first, need its properties for the previews
		this._itemService
			.fetchItem("printer_job_template", `${this.model.printer_job_template_id}`)
			.pipe(
				takeUntil(this.destroyed$),
				timeout(50000),
				catchError((error) => {
					/* eslint no-console: off */
					console.error(error);
					return EMPTY;
				}),
			)
			.subscribe((printerJobTemplate) => {
				this._itemService
					.add(`printers/${printer_job.printer_id}/custom_job`, {
						...printer_job,
						entity_type: "purchase_order_inventory",
						preview_only,
					})
					.pipe(takeUntil(this.destroyed$))
					.pipe(
						timeout(10000),
						catchError((err) => {
							this.error$.next(handleObservableError(err, true));
							this.loading$.next(false);
							return EMPTY;
						}),
					)
					.pipe(
						tap((addedItem) => {
							this.loading$.next(false);

							const printPreviewJobConfig: PreviewPrintJobConfig = {
								processedTemplate: addedItem,
								labelHeight: printerJobTemplate.label_height,
								labelWidth: printerJobTemplate.label_width,
								printerDpi: printerJobTemplate.label_dpi,
							};

							// addedItem is a string if previewing
							if (preview_only) {
								this.openPreviewModal(printPreviewJobConfig);
							} else {
								this._store.dispatch(
									ItemActions.addSuccess({
										addedItem,
										result_type: "printer_jobs",
									}),
								);
								// only close when print jobs are actually created
								this.closeSidenav();
							}
						}),
					)
					.subscribe();
			});
	}

	// Not used but kept around so that this component can continue
	// extending the GenericCreateComponent class
	createItem(_printer_job: Partial<IPrinterJob>) {}
}
