import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { timeout, catchError, tap, takeUntil } from "rxjs/operators";
import { EMPTY } from "rxjs";
import {
	ChangeDetectorRef,
	Component,
	Injector,
	OnDestroy,
} from "@angular/core";
import { IBillOfMaterial } from "@elevatedsignals/amygoodman";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";

import { GenericCreateComponent } from "../generic/generic-create.component";

@Component({
	selector: "bill-of-material-create",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class BillOfMaterialCreateComponent
	extends GenericCreateComponent<IBillOfMaterial>
	implements OnDestroy
{
	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			bill_of_material_id: {
				type: "number",
				title: "Copy Bill Of Material",
				title_translation_key: marker("word_copy_bill_of_material"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "bill_of_materials",
					},
				],
			},
			name: {
				type: "string",
				title: "Name",
				title_translation_key: marker("word_name"),
			},
			use_existing_work_order: {
				type: "boolean",
				widget: "checkbox",
				title: "Create BOM from existing Work Order",
				title_translation_key: marker(
					"form_field_create_bom_from_existing_work_order",
				),
				default: false,
			},
			work_order_type_id: {
				type: "number",
				title: "Work Order Type",
				title_translation_key: marker("word_work_order_type"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "work_order_types",
					},
				],
				visibleIf: {
					oneOf: [
						{ bill_of_material_id: ["$ANY$"] },
						{ use_existing_work_order: [false] },
					],
				},
			},
			work_order_id: {
				type: "number",
				title: "Work Order",
				title_translation_key: marker("word_work_order"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "work_orders",
					},
				],
				visibleIf: {
					oneOf: [{ use_existing_work_order: [true] }],
				},
			},
		},
		required: ["name"],
	};

	private prevBomId: number | undefined;

	constructor(
		protected _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		protected _cd: ChangeDetectorRef,
		private readonly _injector: Injector,
	) {
		super(_store);
		this.form_title = "Create a Bill of Material";
		this.form_title_translation_key = marker(
			"form_title_create_a_bill_of_material",
		);
		this.submit_button = "Create";
		this.submit_button_translation_key = marker("word_create");

		const copyBom = this._injector.get("copy", false);
		const work_order_id = this._injector.get("work_order_id", false);

		if (copyBom) {
			delete this.schema.properties.use_existing_work_order;
		} else {
			delete this.schema.properties.bill_of_material_id;
			if (work_order_id) {
				this.model.use_existing_work_order = true;
				this.model.work_order_id = work_order_id;
			}
		}
	}

	onChanges(model) {
		if (!model.bill_of_material_id && this.prevBomId) {
			this.prevBomId = undefined;
		} else if (
			model.bill_of_material_id &&
			this.prevBomId !== model.bill_of_material_id
		) {
			this.prevBomId = model.bill_of_material_id;
			this._itemService
				.fetchItem(`bill_of_material`, `${model.bill_of_material_id}`)
				.pipe(
					takeUntil(this.destroyed$),
					timeout(50000),
					catchError((error) => {
						/* eslint no-console: off */
						console.error(error);
						return EMPTY;
					}),
				)
				.subscribe((bom) => {
					this.model.name = `${bom.name} copy`;
					this.model.work_order_type_id = bom.work_order_type_id;

					this._cd.detectChanges();
				});
		}
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	ngOnInit() {}

	createItem(bill_of_material: Partial<IBillOfMaterial>) {
		this.loading$.next(true);
		this._itemService
			.add(`bill_of_materials`, {
				...bill_of_material,
			})
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((err) => {
					this.error$.next(handleObservableError(err, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((addedItem) => {
					this._store.dispatch(
						ItemActions.addSuccess({
							addedItem,
							result_type: "bill_of_materials",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
