import { marker } from "@jsverse/transloco-keys-manager/marker";

// @deprecated
export const PlantPropagationSchema = () => ({
	title: "",
	description: "",
	info: "",
	properties: {
		location: {
			type: "string",
			title: "Destination",
			title_translation_key: marker("word_destination"),
			widget: "select",
			oneOf: [
				{
					name: "Location",
					name_translation_key: marker("word_location"),
					value: "LOCATION",
					enum: ["LOCATION"],
				},
				{
					name: "Batch",
					name_translation_key: marker("word_batch"),
					value: "BATCH",
					enum: ["BATCH"],
				},
			],
			default: "BATCH",
		},
		location_id: {
			type: "number",
			title: "Destination Location",
			title_translation_key: marker("form_field_label_destination_location"),
			widget: "data-select",
			oneOf: [{ result_type: "locations" }],
			visibleIf: {
				location: ["LOCATION"],
			},
		},
		batch_id: {
			type: "number",
			title: "Destination Batch",
			title_translation_key: marker("form_field_label_destination_batch"),
			widget: "data-select",
			oneOf: [{ result_type: "batches" }],
			visibleIf: {
				location: ["BATCH"],
			},
		},
		num_cuttings: {
			type: "number",
			title: "Number of Cuttings",
			title_translation_key: marker("form_field_label_number_of_cuttings"),
		},
		growth_stage_id: {
			type: "number",
			title: "Select Growth Stage",
			title_translation_key: marker("form_field_label_select_growth_stage"),
			widget: "data-select",
			oneOf: [
				{
					result_type: "growth_stages",
					queryString: {
						growth_stage_status: ["ACTIVE"],
					},
				},
			],
		},
		timestamp: {
			type: "string",
			title: "Date/time cuttings taken",
			title_translation_key: marker("form_field_label_date_time_cuttings_taken"),
			widget: "date",
		},
	},
	required: ["location", "num_cuttings", "growth_stage_id"],
});

export const PlantPropagationWorkOrderSchema = () => ({
	title: "",
	description: "",
	info: "",
	properties: {
		batch_id: {
			type: "number",
			title: "Destination Batch",
			title_translation_key: marker("form_field_label_destination_batch"),
			widget: "data-select",
			oneOf: [{ result_type: "batches" }],
		},
		num_cuttings: {
			type: "number",
			title: "Number of Cuttings",
			title_translation_key: marker("form_field_label_number_of_cuttings"),
		},
		growth_stage_id: {
			type: "number",
			title: "Select Growth Stage",
			title_translation_key: marker("form_field_label_select_growth_stage"),
			widget: "data-select",
			oneOf: [
				{
					result_type: "growth_stages",
					queryString: {
						growth_stage_status: ["ACTIVE"],
					},
				},
			],
		},
		timestamp: {
			type: "string",
			title: "Date/time cuttings taken",
			title_translation_key: marker("form_field_label_date_time_cuttings_taken"),
			widget: "date",
		},
	},
	required: ["batch_id", "num_cuttings", "growth_stage_id"],
});
