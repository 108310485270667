import { IInventoryProduct, IWorkOrder } from "@elevatedsignals/amygoodman";
import { Store } from "@ngrx/store";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { timeout, catchError, takeUntil, tap, take } from "rxjs/operators";
import { EMPTY } from "rxjs";
import {
	Component,
	ChangeDetectorRef,
	OnInit,
	OnDestroy,
	Injector,
	SimpleChanges,
	SimpleChange,
} from "@angular/core";
import { TranslocoService } from "@jsverse/transloco";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { Globals } from "app/shared/modules/globals/globals.service";
import { WorkOrderInputQuery } from "app/shared/eagers";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";

import { GenericCreateComponent } from "../generic/generic-create.component";
import * as fromDashboard from "../../../reducers";
import { isEqual } from "../../../vendor/lodash";
import { getDynamicFormChanges, skuIdsToTrack } from "../shared";

import {
	GeneralInventorySourceDynamicV2Schema,
	IGeneralInventorySource,
} from "./schemas";

interface AddSourceConfig {
	work_order_id: number;
	work_order_type_id: number;
	use_remaining_input: boolean;
}

@Component({
	selector: "work-order-add-source-gi-dynamic-2",
	templateUrl: "../form-view-for-dynamic.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class WorkOrderAddSourceDynamicGIV2Component
	extends GenericCreateComponent<IGeneralInventorySource>
	implements OnInit, OnDestroy
{
	work_order$ = this._store.select(fromDashboard.getSelectedWorkOrder);
	work_order: IWorkOrder;

	private readonly sourceConfig: AddSourceConfig;
	private readonly whatChanged: SimpleChanges = {};
	private cacheModel = "";

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
		private readonly _globals: Globals,
		private readonly _injector: Injector,
		private readonly _translocoService: TranslocoService,
	) {
		super(_store);
		this.sourceConfig = {
			work_order_id: this._injector.get("work_order_id", null),
			work_order_type_id: this._injector.get("work_order_type_id", null),
			use_remaining_input: this._injector.get("use_remaining_input", false),
		};
		this.form_title = `Add Inputs To Work Order #${this.sourceConfig.work_order_id}`;
		this.form_title_translation_key = marker(
			"form_title_add_inputs_to_work_order_number",
		);
		this.form_title_translation_params = {
			work_order_id: `#${this.sourceConfig.work_order_id}`,
		};
		this.submit_button = "Add";
		this.submit_button_translation_key = marker("word_add");
		this.submit_icon = "plus";

		this.schema = GeneralInventorySourceDynamicV2Schema();

		// ESS-4901 prevent outputs created in a work order from appearing when trying to add inputs within the same work order
		this.schema.properties.inventory_ids.items.oneOf[0].queryString = {
			...this.schema.properties.inventory_ids.items.oneOf[0].queryString,
			exclude_outputs_for_work_order_id: this.sourceConfig.work_order_id,
		};

		// Set work order type input restrictions
		this.schema.properties.inventory_product_id.oneOf[0].queryString = {
			...this.schema.properties.inventory_product_id.oneOf[0].queryString,
			source_inventory_products_for_work_order_type_id:
				this.sourceConfig.work_order_type_id,
		};

		if (this.sourceConfig.use_remaining_input === true) {
			this.schema.properties.remaining_inventory.default = true;
		} else {
			this.schema.properties.remaining_inventory.default = false;
		}
	}

	ngOnInit() {
		if (this._globals.gmp_enabled && this.schema.properties.timestamp) {
			this.schema.properties.timestamp.hidden = true;
		}

		this.model.id = this.sourceConfig.work_order_id;
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	/**
	 * uses JSON.stringify to compare the current model to the previous model.
	 * If the model has changed, then it will be considered for dynamic form changes (in getDynamicFormChanges)
	 * @param model
	 */
	onChanges(model: IGeneralInventorySource) {
		const modelString = JSON.stringify(model);
		if (this.cacheModel !== modelString) {
			getDynamicFormChanges(this.whatChanged, model, skuIdsToTrack);
			this.updateDynamicForm(model);
			// bc we use form-view-dynamic.html, we have to manually update some stuff that's not handled in onChanges
			this.model = {
				...this.model,
				remaining_inventory: model.remaining_inventory,
				inventory_unit_id: model.inventory_unit_id,
				quantity: model.quantity,
				timestamp: model.timestamp,
			};
			this.cacheModel = JSON.stringify({ ...model });
		}
	}

	createItem(work_order_source: IGeneralInventorySource) {
		// Verify form has remaining_inventory or weight
		if (
			work_order_source.inventory_product_id &&
			!work_order_source.remaining_inventory &&
			(!work_order_source.quantity || work_order_source.quantity <= 0)
		) {
			this.error$.next(
				this._translocoService.translate(
					"error_work_order_input_must_include_remaining_inventory_or_positive_quantity",
				),
			);
			return;
		}
		this.loading$.next(true);

		const update = {
			type: "Inventory",
			inventory_product_id: work_order_source.inventory_product_id,
			inventory_unit_id: work_order_source.inventory_unit_id,
			remaining_inventory: work_order_source.remaining_inventory,
			source: work_order_source.source,
			inventory_ids: work_order_source.inventory_ids,
			...(work_order_source.lot_id && { lot_id: work_order_source.lot_id }),
			...(work_order_source.batch_id && { batch_id: work_order_source.batch_id }),
			timestamp: work_order_source.timestamp,
			value: work_order_source.quantity,
			vendor_id: this.model.vendor_id,
			sku_id: this.model.sku_id,
		};
		this._itemService
			.add(
				`work_order/${work_order_source.id}/sources`,
				update,
				WorkOrderInputQuery,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "work_orders",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}

	/**
	 * Checks if a form element has changed since the last time
	 * If true, runs the function to update this.model
	 * If even one form element has changed, then 'Amount Remaining' will also be updated
	 * Tries to avoid running detectChanges unnecessarily; they're ran in changeFormByRequireBatch() and updateInventoryTotals() .
	 * @param model
	 */
	updateDynamicForm = (model?: any) => {
		let detectChangeNeeded = false;
		if (this.didItemChange(this.whatChanged.inventory_product_id)) {
			this.onInventoryProductChange(model);
			detectChangeNeeded = true;
		} else if (this.didItemChange(this.whatChanged.vendor_id)) {
			this.onVendorChange();
			detectChangeNeeded = true;
		} else if (this.didItemChange(this.whatChanged.sku_id)) {
			this.onSkuChange();
			detectChangeNeeded = true;
		} else if (this.didItemChange(this.whatChanged.batch_id)) {
			this.onBatchChange();
			detectChangeNeeded = true;
		} else if (this.didItemChange(this.whatChanged.lot_id)) {
			this.onLotChange();
			detectChangeNeeded = true;
		} else if (this.didItemChange(this.whatChanged.inventory_ids)) {
			this.onInventoryChange();
			detectChangeNeeded = true;
		} else if (this.didItemChange(this.whatChanged.timestamp)) {
			this.onTimeStampChange();
			detectChangeNeeded = true;
		}

		if (detectChangeNeeded) {
			this.updateInventoryTotals(
				this.model.inventory_product_id,
				this.model.lot_id,
				this.model.batch_id,
				this.model.inventory_ids,
				this.model.timestamp,
				this.model.vendor_id,
				this.model.sku_id,
			);
		}
	};

	/**
	 * Checks if there has been a change of values in the SimpleChange object
	 * @param change The SimpleChange key's value. current/previous values must be of type number, or a number array.
	 * @returns true if there is change; false if no change
	 */
	didItemChange = (change: SimpleChange | undefined) => {
		// If an array is detected, convert both current and previous values to arrays for comparison.
		if (
			change &&
			(Array.isArray(change.currentValue) || Array.isArray(change.previousValue))
		) {
			let currentValue: number[];
			let previousValue: number[];
			if (Array.isArray(change.currentValue)) {
				currentValue = change.currentValue;
			} else {
				currentValue = change.currentValue ? [change.currentValue] : [];
			}

			if (Array.isArray(change.previousValue)) {
				previousValue = change.previousValue;
			} else {
				previousValue = change.previousValue ? [change.previousValue] : [];
			}

			return isEqual(currentValue, previousValue) === false;
		} // Otherwise treat as a single number, and regular comparison will work.
		else if (change?.currentValue !== change?.previousValue) {
			return true;
		}
		return false;
	};

	onSkuChange = () => {
		// Lower Fields
		this.setThisModelValue("batch_id", null);
		this.setThisModelValue("lot_id", null);
		this.setThisModelValue("inventory_ids", []);

		// This field
		this.setThisModelValue("sku_id", this.whatChanged.sku_id!.currentValue);
	};

	/**
	 * When a change in inventory_product_id is detected, this function is called.
	 * GETs the inventory_product, with special attention to 'require batch'.
	 * After the change, the currently selected batch/lot are no longer valid so we clear those.
	 */
	onInventoryProductChange = (model?: any) => {
		// Lower Fields
		this.setThisModelValue("batch_id", null);
		this.setThisModelValue("lot_id", null);
		this.setThisModelValue("inventory_ids", []);

		// This field
		this.setThisModelValue(
			"inventory_product_id",
			this.whatChanged.inventory_product_id!.currentValue,
		);
		if (this.whatChanged.inventory_product_id!.currentValue) {
			const inventory_product_id =
				this.whatChanged.inventory_product_id!.currentValue;
			this._itemService
				.fetchItem(`inventory_product`, `${inventory_product_id}`)
				.pipe(
					takeUntil(this.destroyed$),
					timeout(50000),
					catchError((error) => {
						/* eslint no-console: off */
						console.error(error);
						return EMPTY;
					}),
				)
				.subscribe((inventory_product: IInventoryProduct) => {
					this.changeFormByRequireBatch(inventory_product.require_batch, model);
				});
		}
	};

	/**
	 * Changes Form rules dependent on inventory_product.require_batch.
	 * 		true: show batch_id and choose_source_lot. lot_id and inventory_ids' visibility will depend on batch_id.
	 * 				set this.model.batch_id to null, so that once detectChanges is ran it will automatically trigger onBatchChange()
	 * 		false: hide batch_id and choose_source_lot. lot_id and inventory_ids' visibility will depend on inventory_product_id.
	 * 				set this.model.lot_id to null, so that once detectChanges is ran it will automatically trigger onLotChange()
	 * @param require_batch inventory_product.require_batch
	 */
	changeFormByRequireBatch = (require_batch: boolean, model?: any) => {
		if (require_batch) {
			this.schema.properties.batch_id.hidden = false;
			this.setThisModelValue("batch_id", null);
		} else {
			this.schema.properties.batch_id.hidden = true;
		}
		this._cd.detectChanges();
	};

	/**
	 * When a change in batch_id is detected, this function is called.
	 * After the change, the currently selected lot_id is no longer valid so we clear it.
	 */
	onBatchChange = () => {
		// Lower fields
		this.setThisModelValue("inventory_ids", []);

		// Same level fields, special case
		this.setThisModelValue("lot_id", null);

		// Current field
		this.setThisModelValue("batch_id", this.whatChanged.batch_id!.currentValue);
	};

	/**
	 * When a change in lot_id is detected, this function is called.
	 * After the change, the currently selected inventory_ids are no longer valid so we clear them.
	 */
	onLotChange = () => {
		// Lower Fields
		this.setThisModelValue("inventory_ids", []);

		// Same level fields
		this.setThisModelValue("batch_id", this.whatChanged.batch_id!.currentValue);

		// Current field
		this.setThisModelValue("lot_id", this.whatChanged.lot_id!.currentValue);
	};

	/**
	 * When a change in lot_id is detected, this function is called.
	 * we don't have to clear anything because inventory_ids is the last widget in the form.
	 */
	onInventoryChange = () => {
		this.setThisModelValue(
			"inventory_ids",
			this.whatChanged.inventory_ids!.currentValue,
		);
	};

	/**
	 * When a change in timestamp is detected, this function is called.
	 */
	onTimeStampChange = () => {
		this.setThisModelValue("timestamp", this.whatChanged.timestamp!.currentValue);
	};

	/**
	 * On vendor change
	 */
	onVendorChange = () => {
		// This field
		this.setThisModelValue("vendor_id", this.whatChanged.vendor_id!.currentValue);

		// Same level fields don't get updated

		// Lower Fields
		this.setThisModelValue("batch_id", null);
		this.setThisModelValue("lot_id", null);
		this.setThisModelValue("inventory_ids", []);
	};

	/**
	 * This function is only ran if any changes in lot_id, batch_id, inventory_ids, or inventory_product_id was detected.
	 * It will update the inventory totals for the currently selected inventory_product_id, lot_id, inventory_ids, and batch_id.
	 * @param product_id
	 * @param lot_id
	 * @param batch_id
	 * @param inventory_ids
	 * @param vendor_id
	 * @param model We use model to grab choose_source_inventory and choose_source_lot; If we don't, it can cause some redundant looping
	 */
	updateInventoryTotals = (
		product_id: number,
		lot_id: number,
		batch_id: number,
		inventory_ids: number[] | boolean,
		timestamp: string,
		vendor_id: number,
		sku_id: number,
	) => {
		if (product_id && vendor_id) {
			this._itemService
				.fetchItem(`inventory`, `available`, {
					product_id: `${product_id}`,
					...(Array.isArray(inventory_ids) &&
						inventory_ids.length > 0 && {
							inventory_ids: inventory_ids.map((ea) => ea.toString()),
						}),
					...(lot_id && { lot_id: `${lot_id}` }),
					...(batch_id && { batch_id: `${batch_id}` }),
					...(timestamp && { timestamp: `${timestamp}` }),
					...(vendor_id && { vendor_id: `${vendor_id}` }),
					...(sku_id && { sku_id: `${sku_id}` }),
				})
				.pipe(
					take(1),
					timeout(50000),
					catchError((error) => {
						/* eslint no-console: off */
						console.error(error);
						return EMPTY;
					}),
				)
				.subscribe((availableInventoryAmount) => {
					if (
						availableInventoryAmount.content &&
						availableInventoryAmount.content.length === 0
					) {
						this.setThisModelValue("amount_available", "None Available");
					} else if (
						availableInventoryAmount.content &&
						availableInventoryAmount.content.length > 0
					) {
						const amountAvailable = availableInventoryAmount.content
							.map((availableInventory) => {
								return `${availableInventory.sum.toFixed(2)} ${availableInventory.name}`;
							})
							.join("\n");
						this.setThisModelValue("amount_available", amountAvailable);
					}

					this._cd.detectChanges();
				});
		}
	};

	/**
	 * Function meant to set this.model's properties.
	 * this.model changes are important, but this.model.x = [...] is easy to miss so this function is here for more visibility.
	 * @param key
	 * @param value
	 */
	setThisModelValue = (key: string, value: any) => {
		this.model = {
			...this.model,
			[key]: value,
		};
	};
}
