import { tap, takeUntil, timeout, catchError } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { EMPTY } from "rxjs";
import { Component, OnDestroy, Injector } from "@angular/core";
import { IStatus, StatusType } from "@elevatedsignals/amygoodman";
import { Globals } from "app/shared/modules/globals/globals.service";
import { handleObservableError } from "app/shared/utils";
import { startCase } from "app/modules/dashboard/vendor/lodash";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { getSelectedFacilitySettings } from "app/modules/dashboard/selectors/facility-settings.selector";
import { LicenceType } from "app/modules/dashboard/state/facilitySettings.state";

import { GenericCreateComponent } from "../generic/generic-create.component";

import { GI_STATUS_TYPES, NON_GI_STATUS_TYPES } from "./constants";

@Component({
	selector: "status-create",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class StatusCreateComponent
	extends GenericCreateComponent<IStatus>
	implements OnDestroy
{
	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			name: {
				type: "string",
				title: "Name",
				title_translation_key: marker("word_name"),
			},
			status_type: {
				type: "string",
				title: "Status Type",
				title_translation_key: marker("form_field_label_status_type"),
				widget: "select",
				oneOf: [],
			},
			is_blocking: {
				type: "boolean",
				widget: "checkbox",
				title: "Is Blocking",
				title_translation_key: marker("form_field_label_is_blocking"),
			},
		},
		required: ["name", "status_type"],
	};

	specific_field_id: string;

	facilitySettings$ = this._store.select(getSelectedFacilitySettings);
	hasQualityRecords = false;

	constructor(
		protected _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _globals: Globals,
		private readonly _injector: Injector,
	) {
		super(_store);
		this.form_title = "Add a Status";
		this.form_title_translation_key = marker("form_title_add_a_status");
		this.submit_button = "Create";
		this.submit_button_translation_key = marker("word_create");
		this.specific_field_id = this._injector.get("specific_field_id", null);

		this.facilitySettings$.subscribe((facilitySettings) => {
			this.hasQualityRecords =
				facilitySettings.licence[LicenceType.Quality] === true &&
				facilitySettings.licence[LicenceType.Forms] === true;
		});
	}

	ngOnInit() {
		let statusTypes = this._globals.general_inventory_enabled
			? GI_STATUS_TYPES
			: NON_GI_STATUS_TYPES;

		if (!this.hasQualityRecords) {
			statusTypes = statusTypes.filter((type) => type.name !== StatusType.Record);
		}

		statusTypes.map((type) => {
			this.schema.properties.status_type.oneOf.push({
				// converts snake case to start case
				name: startCase(type.name.toLowerCase()),
				name_translation_key: type.name_translation_key,
				value: type.name,
				enum: [type.name],
			});
		});

		this.model.status_type = this._injector.get("status_type", null);
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	createItem(status: Partial<IStatus>) {
		this.loading$.next(true);
		this._itemService
			.add(`statuses`, status)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((addedItem) => {
					this._store.dispatch(
						ItemActions.addSuccess({
							addedItem,
							result_type: "statuses",
							...(this.specific_field_id && {
								specific_field_id: this.specific_field_id,
							}),
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
