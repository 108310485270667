import { marker } from "@jsverse/transloco-keys-manager/marker";
import { maxPlantNumber } from "app/shared/work-order-functions";

import { NewLotCreationSchema } from "../../lot";

export const WorkOrderPlantOutputSchema = () => ({
	plant_batch_id: {
		type: "number",
		title: "Destination Batch",
		title_translation_key: marker("form_field_label_destination_batch"),
		widget: "data-select",
		// quick_create: false,
		oneOf: [
			{
				result_type: "batches",
				queryString: {
					input: "true",
					dont_show_expired: "true",
				},
			},
		],
		visibleIf: {
			input_type: ["PLANTS"],
		},
	},
	plant_new_lot: {
		...NewLotCreationSchema.new_lot,
		visibleIf: {
			input_type: ["PLANTS"],
		},
	},
	plant_create_new_lot: {
		...NewLotCreationSchema.create_new_lot,
		visibleIf: {
			plant_new_lot: ["Create New Lot"],
		},
	},
	plant_lot_name: {
		...NewLotCreationSchema.lot_name,
		visibleIf: {
			plant_create_new_lot: [true],
		},
	},
	plant_lot_id: {
		type: "number",
		title: "Destination Lot",
		title_translation_key: marker("form_field_label_destination_lot"),
		widget: "data-select",
		quick_create: false,
		related_properties: [],
		oneOf: [
			{
				result_type: "lots",
				queryString: {
					input: "true",
					dont_show_expired: "true",
				},
			},
		],
		visibleIf: {
			plant_new_lot: ["Use Existing Lot"],
		},
	},

	plant_selection: {
		title: "Plant Output",
		title_translation_key: marker("form_field_label_plant_output"),
		type: "string",
		widget: "radio",
		oneOf: [
			{
				name: "Create new plants.",
				name_translation_key: marker("form_field_value_create_new_plants"),
				value: "CREATE",
				enum: ["CREATE"],
			},
			{
				name: "Output existing plants in work order.",
				name_translation_key: marker(
					"form_field_value_output_existing_plants_in_work_order",
				),
				value: "EXISTING",
				enum: ["EXISTING"],
			},
		],
		visibleIf: {
			allOf: [{ input_type: ["PLANTS"] }],
		},
	},
	remaining_plants: {
		title: "Use Remaining Plants",
		title_translation_key: marker("form_field_label_use_remaining_plants"),
		type: "boolean",
		widget: "checkbox",
		default: false,
		visibleIf: {
			allOf: [{ input_type: ["PLANTS"] }, { plant_selection: ["EXISTING"] }],
		},
	},
	use_plant_ids: {
		title: "Use Specific Plant Ids",
		title_translation_key: marker("form_field_label_use_specific_plant_ids"),
		type: "boolean",
		widget: "checkbox",
		default: false,
		visibleIf: {
			allOf: [
				{ input_type: ["PLANTS"] },
				{ plant_selection: ["EXISTING"] },
				{ remaining_plants: false },
			],
		},
	},
	plant_ids: {
		type: "array",
		title: "Plant Ids",
		title_translation_key: marker("form_field_label_plant_ids"),
		widget: "data-select",
		quick_create: false,
		items: {
			type: "number",
			oneOf: [
				{
					result_type: "plants",
					queryString: {
						plant_status: "ACTIVE",
					},
				},
			],
		},
		visibleIf: {
			allOf: [{ input_type: ["PLANTS"] }, { use_plant_ids: [true] }],
		},
	},
	cultivar_id: {
		type: "number",
		title: "Cultivar",
		title_translation_key: marker("word_cultivar"),
		widget: "data-select",
		oneOf: [
			{
				result_type: "cultivars",
			},
		],
		visibleIf: {
			allOf: [{ input_type: ["PLANTS"] }, { plant_selection: ["CREATE"] }],
		},
	},
	growth_stage_id: {
		type: "number",
		title: "Growth Stage",
		title_translation_key: marker("word_growth_stage"),
		widget: "data-select",
		oneOf: [
			{
				result_type: "growth_stages",
				queryString: {
					growth_stage_status: ["ACTIVE"],
				},
			},
		],
		visibleIf: {
			allOf: [{ input_type: ["PLANTS"] }, { plant_selection: ["CREATE"] }],
		},
	},
	plant_count_create: {
		type: "number",
		title: "Number of Plants",
		title_translation_key: marker("form_field_label_number_of_plants"),
		max: maxPlantNumber,
		warning:
			"Number of plants in output exceeds the recommended maximum - Please lower your plant count.",
		warning_translation_key: marker(
			"form_field_warning_number_of_plants_exceeds_recommended",
		),
		visibleIf: {
			allOf: [{ input_type: ["PLANTS"] }, { plant_selection: ["CREATE"] }],
		},
	},
	plant_count_use: {
		type: "number",
		title: "Number of Plants",
		title_translation_key: marker("form_field_label_number_of_plants"),
		visibleIf: {
			allOf: [
				{ input_type: ["PLANTS"] },
				{ plant_selection: ["EXISTING"] },
				{ use_plant_ids: [false] },
				{ remaining_plants: [false] },
			],
		},
	},
});
