import { Input, Component, inject } from "@angular/core";
import { TranslocoService } from "@jsverse/transloco";
import { Store } from "@ngrx/store";
import { HttpClient } from "@angular/common/http";
import { ReplaySubject } from "rxjs";
import { tap, take } from "rxjs/operators";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { ApiService } from "app/modules/auth/services/api.service";
import { TranslateErrorService } from "app/shared/errors/handleError";
import {
	EReportingInterval,
	IFacility,
	IReportingPeriod,
	User,
} from "@elevatedsignals/amygoodman";
import { DateFormatService } from "app/modules/dashboard/services/date-format/dateFormat.service";
import {
	getReportingPeriods,
	reportingPeriodsEnabled,
} from "app/modules/dashboard/selectors/facility-settings.selector";
import { getUserInitials } from "app/shared/get-initials";

interface WorkOrderHistoryEvent {
	action: string;
	created_at: string;
	date: string;
	user: User;
	reference_table: string;
	data: any;
	update_reference: string[];
	id: number;
	note: any;
	reporting_period?: {
		isReconciled: boolean;
		createdPeriodYear: number;
		createdPeriod: number;
		closedAtDate?: number;
	};
	is_backdated?: boolean;
	action_summary?: string;
	backdate_summary?: string;
}

@Component({
	selector: "work-order-history",
	templateUrl: "work-order-history.template.html",
})
export class WorkOrderHistoryComponent {
	showReportingPeriods$ = this._store.select(reportingPeriodsEnabled);
	reportingPeriods$ = this._store.select(getReportingPeriods);

	public workOrderHistoryEvents$ = new ReplaySubject<WorkOrderHistoryEvent[]>();
	private _entityId: number;

	private readonly translateErrorService = inject(TranslateErrorService);

	@Input() set entityId(entityId: number) {
		if (entityId) {
			this._entityId = entityId;
			this.getHistory(this._entityId);
		}
	}

	get entityId() {
		return this._entityId;
	}

	constructor(
		private readonly _store: Store<fromDashboard.State>,
		private readonly _api: ApiService,
		private readonly _http: HttpClient,
		private readonly _dateFormatService: DateFormatService,
		private readonly _translocoService: TranslocoService,
	) {}

	getInitials(first?: string, last?: string) {
		return getUserInitials(first, last);
	}

	getHistory(workOrderId: number) {
		if (!workOrderId) {
			this.workOrderHistoryEvents$.next([]);
			return false;
		}

		return this._http
			.get(this._api.wo.eventLog(workOrderId))
			.pipe(
				tap((res: WorkOrderHistoryEvent[]) =>
					this.workOrderHistoryEvents$.next(res),
				),
				this.translateErrorService.catchAndTranslateError(
					"error_failed_to_retrieve_history",
				),
				take(1),
			)
			.subscribe();
	}

	parseRelativeDate(date: string) {
		return this._dateFormatService.getFromNow(new Date(date));
	}

	isReconciled(event: WorkOrderHistoryEvent): boolean {
		if (event.reporting_period) {
			return event.reporting_period.isReconciled;
		}
		return false;
	}

	isBackdated(event: WorkOrderHistoryEvent): boolean {
		return event.is_backdated ?? false;
	}

	hasBackdateSummary(event: WorkOrderHistoryEvent): boolean {
		return Boolean(event.backdate_summary);
	}

	showReportingPeriods(facility: IFacility): boolean {
		if (facility.data?.options?.enable_reporting_periods) {
			return (facility.reporting_periods?.length ?? 0) > 0;
		}

		return false;
	}

	// Function made with assumption that facility only has 1 reporting period currently.
	getPeriodRecordedOn(
		event: WorkOrderHistoryEvent,
		reportingPeriods: IReportingPeriod[],
	): string {
		const reportingPeriod: IReportingPeriod = reportingPeriods[0]!;
		const periodRecordedOn = `${this.getPrefix(reportingPeriod.interval)}${
			event.reporting_period?.createdPeriod
		}`;
		return `${event.reporting_period?.createdPeriodYear} ${periodRecordedOn}`;
	}

	getPrefix(interval: EReportingInterval) {
		if (interval === EReportingInterval.quarter) {
			return `${this._translocoService.translate("acronym_q")}`;
		} else if (interval === EReportingInterval.week) {
			return `${this._translocoService.translate("word_week")} `;
		}
		return `${this._translocoService.translate("word_month")} `;
	}
}
