import { marker } from "@jsverse/transloco-keys-manager/marker";

export const GeneralInventorySourceDynamicV2Schema = () => ({
	title: "",
	description: "",
	info: "",
	properties: {
		id: {
			type: "number",
			hidden: true,
		},
		/**
		 * Inventory Fields
		 */
		inventory_product_id: {
			title: "Product",
			title_translation_key: marker("word_product"),
			type: "number",
			widget: "data-select",
			quick_create: false,
			selectFirstAndOnly: true,
			oneOf: [
				{
					result_type: "inventory_products",
					text_key: "name",
					value_key: "id",
					queryString: {
						non_zero_inventory: "true",
					},
				},
			],
			related_properties: ["sku_id", "vendor_id"],
		},
		sku_id: {
			type: "number",
			title: "SKU",
			title_translation_key: marker("word_sku"),
			widget: "data-select",
			quick_create: false,
			oneOf: [
				{
					result_type: "skus",
					text_key: ["sku"],
				},
			],
			related_properties: ["inventory_product_id", "vendor_id"],
		},
		vendor_id: {
			type: "number",
			title: "Vendor",
			title_translation_key: marker("word_vendor"),
			widget: "data-select",
			selectFirstAndOnly: true,
			oneOf: [
				{
					result_type: "vendors",
				},
			],
			related_properties: ["inventory_product_id", "sku_id"],
		},
		batch_id: {
			type: "number",
			title: "Source Batch",
			title_translation_key: marker("form_field_label_source_batch"),
			widget: "data-select",
			quick_create: false,
			related_properties: [
				"inventory_product_id",
				"batch_id",
				"sku_id",
				"vendor_id",
			],
			selectFirstAndOnly: true,
			oneOf: [
				{
					result_type: "batches",
					queryString: {
						input: "true",
						dont_show_expired: "true",
						non_zero_inventory: "true",
					},
				},
			],
			visibleIf: {
				allOf: [
					{ inventory_product_id: ["$EXP$ target.value > 0"] },
					{ vendor_id: ["$EXP$ target.value > 0"] },
				],
			},
		},
		lot_id: {
			type: "number",
			title: "Source Lot",
			title_translation_key: marker("form_field_label_source_lot"),
			widget: "data-select",
			quick_create: false,
			related_properties: [
				"inventory_product_id",
				"batch_id",
				"sku_id",
				"vendor_id",
			],
			selectFirstAndOnly: true,
			oneOf: [
				{
					result_type: "lots",
					queryString: {
						input: "true",
						dont_show_expired: "false",
						non_zero_inventory: "true",
					},
				},
			],
			visibleIf: {
				allOf: [
					{ inventory_product_id: ["$EXP$ target.value > 0"] },
					{ vendor_id: ["$EXP$ target.value > 0"] },
				],
			},
		},
		inventory_ids: {
			type: "array",
			title: "Inventory ID",
			title_translation_key: marker("form_field_label_inventory_id"),
			widget: "data-select",
			quick_create: false,
			multi: true,
			related_properties: [
				"inventory_product_id",
				"batch_id",
				"lot_id",
				"sku_id",
				"vendor_id",
			],
			selectFirstAndOnly: true,
			items: {
				type: "number",
				oneOf: [
					{
						result_type: "inventories",
						text_key: ["name", "id"],
						text_format: "?1 (#?2)",
						value_key: "id",
						queryString: {
							dont_show_expired: "false",
							non_zero_inventory: "true",
						},
					},
				],
			},
			visibleIf: {
				allOf: [
					{ inventory_product_id: ["$EXP$ target.value > 0"] },
					{ vendor_id: ["$EXP$ target.value > 0"] },
					{
						oneOf: [
							{ batch_id: ["$EXP$ target.value > 0"] },
							{ lot_id: ["$EXP$ target.value > 0"] },
						],
					},
				],
			},
		},
		remaining_inventory: {
			title: "Use Remaining Inventory",
			title_translation_key: marker("form_field_label_use_remaining_inventory"),
			type: "boolean",
			widget: "checkbox",
			default: true,
			visibleIf: {
				"inventory_ids/*": ["$EXP$ target.value > 0"],
			},
		},
		quantity: {
			type: "number",
			title: `Quantity`,
			title_translation_key: marker("word_quantity"),
			width: "50%",
			visibleIf: {
				allOf: [
					{ "inventory_ids/*": ["$EXP$ target.value > 0"] },
					{ remaining_inventory: [false] },
				],
			},
		},
		inventory_unit_id: {
			title: "Inventory Unit",
			title_translation_key: marker("word_inventory_unit"),
			type: "number",
			widget: "data-select",
			quick_create: false,
			related_properties: ["inventory_product_id", "inventory_ids"],
			selectFirstAndOnly: true,
			width: "50%",
			shorter_placeholder: true,
			oneOf: [
				{
					result_type: "inventory_units",
				},
			],
			visibleIf: {
				allOf: [
					{ "inventory_ids/*": ["$EXP$ target.value > 0"] },
					{ remaining_inventory: [false] },
				],
			},
		},
		amount_available: {
			type: "string",
			widget: "string",
			title: "Amount Available",
			readOnly: true,
			visibleIf: {
				allOf: [
					{ inventory_product_id: ["$EXP$ target.value > 0"] },
					{ vendor_id: ["$EXP$ target.value > 0"] },
				],
			},
		},
		timestamp: {
			type: "string",
			title: "Timestamp",
			title_translation_key: marker("word_timestamp"),
			widget: "date",
			hidden: false,
		},
	},
	anyOf: [
		{
			required: ["id", "inventory_unit_id", "quantity", "inventory_ids"],
		},
		{
			required: ["id", "inventory_ids", "remaining_inventory"],
		},
	],
});
