import {
	ChangeDetectorRef,
	Component,
	Injector,
	OnDestroy,
	OnInit,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ISeedLot, ISelectListItem } from "@elevatedsignals/amygoodman";
import { BatchInventoryQuery } from "app/shared/eagers";
import { Globals } from "app/shared/modules/globals/globals.service";
import { canCloseBatch, handleObservableError } from "app/shared/utils";
import { EMPTY, ReplaySubject } from "rxjs";
import { catchError, timeout, takeUntil } from "rxjs/operators";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { ItemService } from "app/modules/dashboard/services/item.service";

import { GenericCreateComponent } from "../../generic/generic-create.component";
import { NewLotCreationSchema } from "../../lot/schemas/index";
import { onVendorChange } from "../../shared";
import { BatchCloseComponent } from "../../batch";

export enum AdjustmentType {
	New = "NEW",
	Addition = "ADD",
	Subtraction = "SUBTRACT",
}

@Component({
	selector: "inventory-measure-event-create",
	templateUrl: "../../form-view.component.html",
	styleUrls: ["../../sidenav.scss"],
})
export class CreateInventoryMeasureEventComponent
	extends GenericCreateComponent<ISeedLot>
	implements OnInit, OnDestroy
{
	valid$: ReplaySubject<boolean> = new ReplaySubject(1);
	error$: ReplaySubject<string> = new ReplaySubject();

	form_title = "Create Reconciliation";
	form_title_translation_key: string = marker(
		"form_title_create_reconciliation",
	);

	submit_button = "Add Reconciliation";
	submit_button_translation_key: string = marker(
		"form_button_add_reconciliation",
	);

	submit_icon = "plus";

	loading = false;

	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	inventory_product_id = undefined;

	related_item_id: number;
	model: any = {};
	adjustment_reasons: { name: string; name_translation_key?: string }[] = [
		{ name: "Harvested", name_translation_key: marker("word_harvested") },
		{ name: "Dried", name_translation_key: marker("word_dried") },
		{
			name: "Processing Loss",
			name_translation_key: marker("word_processing_loss"),
		},
		{ name: "Packaged", name_translation_key: marker("word_packaged") },
		{ name: "Transferred", name_translation_key: marker("word_transferred") },
		{ name: "Drying Loss", name_translation_key: marker("word_drying_loss") },
		{ name: "Destroyed", name_translation_key: marker("word_destroyed") },
		{
			name: "Lost/Theft",
			name_translation_key: marker("form_field_value_lost_theft"),
		},
		{
			name: "Lab Test",
			name_translation_key: marker("form_field_value_lab_test"),
		},
		{ name: "Returns", name_translation_key: marker("word_returns") },
		{
			name: "Opening Inventory",
			name_translation_key: marker("word_opening_inventory"),
		},
		{ name: "Other", name_translation_key: marker("word_other") },
	];

	adjustment_types: ISelectListItem[] = [
		{
			name: "Addition",
			name_translation_key: marker("word_addition"),
			value: AdjustmentType.Addition,
			enum: [AdjustmentType.Addition],
		},
		{
			name: "Subtraction",
			name_translation_key: marker("word_subtraction"),
			value: AdjustmentType.Subtraction,
			enum: [AdjustmentType.Subtraction],
		},
	];

	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			inventory_product_id: {
				type: "number",
				title: "Inventory Product",
				title_translation_key: marker("word_inventory_product"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "inventory_products",
						queryString: {
							non_inventory_destruction: "false",
							inventory_product_filter: "ACTIVE_ARCHIVED",
						},
					},
				],
			},
			vendor_id: {
				type: "number",
				title: "Vendor",
				title_translation_key: marker("word_vendor"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "vendors",
					},
				],
				hidden: true,
				related_properties: ["inventory_product_id"],
			},
			location_id: {
				type: "number",
				title: "Destination Location",
				title_translation_key: marker("form_field_label_destination_location"),
				widget: "data-select",
				quick_create: false,
				oneOf: [
					{
						result_type: "locations",
						queryString: {
							input: "true",
						},
					},
				],
			},

			new_lot: { ...NewLotCreationSchema.new_lot },
			create_new_lot: { ...NewLotCreationSchema.create_new_lot },
			lot_name: { ...NewLotCreationSchema.lot_name },
			lot_id: { ...NewLotCreationSchema.lot_id({ dont_show_expired: "true" }) },

			adjustment_type: {
				type: "string",
				title: "Adjustment Type",
				title_translation_key: marker("form_field_label_adjustment_type"),
				widget: "select",
				oneOf: this.adjustment_types,
				default: AdjustmentType.Addition,
			},
			value: {
				widget: "number",
				type: "number",
				step: "0.00001",
				title: `Value`,
				title_translation_key: marker("word_value"),
				visibleIf: {
					allOf: [
						{
							adjustment_type: [AdjustmentType.Addition, AdjustmentType.Subtraction],
						},
						{
							inventory_product_id: ["$EXP$ target.value > 0"],
						},
					],
				},
				width: "50%",
			},
			inventory_unit_id: {
				type: "number",
				title: "Inventory Unit",
				title_translation_key: marker("word_inventory_unit"),
				widget: "data-select",
				quick_create: false,
				shorter_placeholder: true,
				related_properties: ["inventory_product_id"],
				oneOf: [
					{
						result_type: "inventory_units",
					},
				],
				visibleIf: {
					inventory_product_id: ["$EXP$ target.value > 0"],
				},
				width: "50%",
			},
			sku_id: {
				type: "number",
				title: "SKU",
				title_translation_key: marker("word_sku"),
				widget: "data-select",
				quick_create: false,
				related_properties: ["inventory_product_id"],
				oneOf: [
					{
						result_type: "skus",
						text_key: ["sku"],
					},
				],
				visibleIf: {
					inventory_product_id: ["$EXP$ target.value > 0"],
				},
			},
			reason: {
				type: "string",
				title: "Reason",
				title_translation_key: marker("word_reason"),
				widget: "select",
				oneOf: this.adjustment_reasons.map((item) => {
					return {
						name: item.name,
						name_translation_key: item.name_translation_key,
						value: item.name,
						enum: [item.name],
					};
				}),
			},
			reason_other: {
				type: "string",
				title: "Other Reason",
				title_translation_key: marker("form_field_label_other_reason"),
				visibleIf: {
					reason: ["Other"],
				},
				default: "",
			},
			description: {
				type: "string",
				title: "Description",
				title_translation_key: marker("word_description"),
				widget: "textarea",
			},
			timestamp: {
				type: "string",
				title: "Date",
				title_translation_key: marker("word_date"),
				widget: "date",
			},
		},
		required: [
			"reason",
			"inventory_product_id",
			"adjustment_type",
			"inventory_unit_id",
		],
	};

	validators = {};
	private prevInventoryProductId: number | undefined;

	constructor(
		private readonly _injector: Injector,
		_store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _cd: ChangeDetectorRef,
		private readonly _globals: Globals,
	) {
		super(_store);
	}

	valid(isValid: boolean) {
		this.valid$.next(isValid);
	}

	ngOnInit() {
		if (this._globals.gmp_enabled) {
			delete this.schema.properties.timestamp;
		}

		this.inventory_product_id = this._injector.get("inventory_product_id", null);
		const inventory_unit_id = this._injector.get("inventory_unit_id", null);

		this.related_item_id = this._injector.get("related_item_id", null);

		if (this.related_item_id) {
			this.schema.properties = {
				...this.schema.properties,
				batch_id: {
					type: "number",
					hidden: true,
				},
			};
			this.model.batch_id = this.related_item_id;
			this.model.location_id = this._injector.get("location_id", null);
		}

		if (this.inventory_product_id) {
			this.model = {
				...this.model,
				inventory_product_id: this.inventory_product_id,
				inventory_unit_id,
			};
		}

		this._cd.detectChanges();
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	createItem(model) {
		let value = model.value;
		switch (this.model.adjustment_type) {
			case AdjustmentType.Addition:
				if (value < 0) {
					value *= -1;
				}
				break;
			case AdjustmentType.Subtraction:
				if (value > 0) {
					value *= -1;
				}
				break;
			default:
				break;
		}
		const adjustment = {
			inventory_product_id: this.model.inventory_product_id,
			inventory_unit_id: this.model.inventory_unit_id,
			sku_id: this.model.sku_id,
			location_id: this.model.location_id,
			lot_id: this.model.lot_id,
			create_new_lot: this.model.create_new_lot,
			lot_name: this.model.lot_name,
			value,
			reason:
				this.model.reason === "Other"
					? this.model.reason_other || this.model.reason
					: this.model.reason,
			description: this.model.description,
			timestamp: this.model.timestamp,
			vendor_id: this.model.vendor_id,
		};
		this.createEvent(adjustment);
	}

	createEvent(event: any) {
		this.loading$.next(true);
		const query = BatchInventoryQuery;
		this._itemService
			.add(`batch/${this.related_item_id}/measurements`, event, query)
			.pipe(
				takeUntil(this.destroyed$),
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.subscribe((updatedItem) => {
				this._store.dispatch(
					ItemActions.updateSuccess({
						updatedItem,
						result_type: "batches",
					}),
				);
				this.loading$.next(false);
				this.closeSidenav();

				if (canCloseBatch(updatedItem, this._globals.general_inventory_enabled)) {
					this._store.dispatch(
						layoutActions.openSidenav({
							component: BatchCloseComponent,
						}),
					);
				}
			});
	}

	onChanges(model) {
		if (!model.inventory_product_id && this.prevInventoryProductId) {
			this.prevInventoryProductId = undefined;
			model.inventory_unit_id = undefined;
		} else if (
			model.inventory_product_id &&
			this.prevInventoryProductId !== model.inventory_product_id
		) {
			this.prevInventoryProductId = model.inventory_product_id;
			model.inventory_unit_id = undefined;
			const firstProductSet = !this.model.inventory_product_id;

			this._itemService
				.fetchItem(`inventory_product`, `${model.inventory_product_id}`, {
					eager: "[vendors]",
				})
				.pipe(
					takeUntil(this.destroyed$),
					timeout(50000),
					catchError((error) => {
						/* eslint no-console: off */
						console.error(error);
						return EMPTY;
					}),
				)
				.subscribe((inventory_product) => {
					if (!firstProductSet || !this.model.inventory_unit_id) {
						this.model.inventory_unit_id = inventory_product.display_unit_id;
					}

					[this.model, this.schema] = onVendorChange(
						this.model,
						this.schema,
						inventory_product,
					);
					this._cd.detectChanges();
				});
		}
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}
}
