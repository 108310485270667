import { Injectable } from "@angular/core";
import { RouterStateSnapshot } from "@angular/router";
import {
	Batch,
	SubstanceTypes,
	IMeasureEvent,
	MeasureUnit,
	IInventory,
	IInventoryProduct,
	measureUnitDictToString,
	round,
	MeasureUnitDict,
} from "@elevatedsignals/amygoodman";
import { RouterStateSerializer } from "@ngrx/router-store";
import { Big } from "big.js";
import { environment } from "environments/environment";
// https://ngneat.github.io/transloco/docs/additional-functionality#translate
import { translate } from "@jsverse/transloco";
import { BatchDetailPageComponent } from "app/modules/dashboard/pages/batch/batch-detail.component";
import { FacilitySettings } from "app/modules/dashboard/state/facilitySettings.state";

import { strings } from "./strings";

/**
 * The RouterStateSerializer takes the current RouterStateSnapshot
 * and returns any pertinent information needed. The snapshot contains
 * all information about the state of the router at the given point in time.
 * The entire snapshot is complex and not always needed. In this case, you only
 * need the URL from the snapshot in the store. Other items could be
 * returned such as route parameters, query parameters and static route data.
 */

export interface RouterStateUrl {
	url: string;
}

@Injectable()
export class CustomRouterStateSerializer
	implements RouterStateSerializer<RouterStateUrl>
{
	serialize(routerState: RouterStateSnapshot): RouterStateUrl {
		const { url } = routerState;

		return { url };
	}
}

/**
 * This function coerces a string into a string literal type.
 * Using tagged union types in TypeScript 2.0, this enables
 * powerful typechecking of our reducers.
 *
 * Since every action label passes through this function it
 * is a good place to ensure all of our action labels
 * are unique.
 */

const typeCache: Record<string, boolean> = {};
export const type = <T>(label: T | ""): T => {
	if (typeCache[label as string]) {
		throw new Error(`Action type "${label}" is not unique"`);
	}

	typeCache[label as string] = true;

	return label as T;
};

export const handleObservableError = (error: any, askToWait = false) => {
	// console.error(error);
	if (typeof error === "string") {
		return error;
	} else if (typeof error === "object" && error.name) {
		return getErrorMessage(askToWait, error.name);
	}

	return getErrorMessage(askToWait);
};

const getErrorMessage = (askToWait: boolean, name?: string) => {
	if (!name) {
		return strings.en.new.other;
	}

	if (name === "TimeoutError") {
		if (askToWait) {
			return strings.en.new.time_out_wait;
		} else {
			return strings.en.new.time_out_try_again;
		}
	}

	if (name === "NotFoundError") {
		return strings.en.new.not_found;
	}

	return strings.en.new.problem_loading;
};

export const filterList = <T>(
	query: string,
	list: T[],
	properties: string[],
): T[] => {
	const queryItems = query.split(" ");
	const regexes: RegExp[] = [];
	for (let i = 0; i < queryItems.length; i++) {
		// Create regex and escape and special regex characters
		regexes.push(
			new RegExp(queryItems[i]!.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&"), "i"),
		);
	}

	return list.filter((item) => {
		for (let i = 0; i < regexes.length; i++) {
			// Loop though all query items for matches
			let hasMatch = false;
			for (let k = 0; k < properties.length; k++) {
				// Loop through all properties in list items
				if (item[properties[k]!] && regexes[i]!.exec(`${item[properties[k]!]}`)) {
					hasMatch = true;
				}
			}
			if (!hasMatch) {
				return false;
			}
		}
		return true;
	});
};

export function getPager(totalItems: number, currentPage = 1, pageSize = 10) {
	// calculate total pages
	const totalPages = Math.ceil(totalItems / pageSize);

	let startPage: number;
	let endPage: number;
	if (totalPages <= 10) {
		// less than 10 total pages so show all
		startPage = 1;
		endPage = totalPages;
	} else {
		if (totalPages < currentPage) {
			currentPage = 1;
		}
		// more than 10 total pages so calculate start and end pages
		if (currentPage <= 6) {
			startPage = 1;
			endPage = 10;
		} else if (currentPage + 4 >= totalPages) {
			startPage = totalPages - 9;
			endPage = totalPages;
		} else {
			startPage = currentPage - 5;
			endPage = currentPage + 4;
		}
	}

	// calculate start and end item indexes
	const startIndex = (currentPage - 1) * pageSize;
	const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

	function range(start: number, end: number) {
		return [...Array(1 + end - start).keys()].map((v) => start + v);
	}

	// create an array of pages to ng-repeat in the pager control
	const pages = range(startPage, endPage + 1);

	// return object with all pager properties required by the view
	return {
		totalItems,
		currentPage,
		pageSize,
		totalPages,
		startPage,
		endPage,
		startIndex,
		endIndex,
		pages,
	};
}

// @deprecated
export const getTotalWeight = (
	batch: Batch,
	substance_type: SubstanceTypes,
) => {
	switch (substance_type) {
		case SubstanceTypes.DriedCannabis:
			return Number(
				dryWeights(batch).reduce(
					(result, event) =>
						isNaN(event.value) ? result : result.plus(event.value),
					new Big(0),
				),
			);
		case SubstanceTypes.FreshCannabis:
			return Number(
				wetWeights(batch).reduce(
					(result, event) =>
						isNaN(event.value) ? result : result.plus(event.value),
					new Big(0),
				),
			);
		default:
			console.error("SUBSTANCE TYPE UNKNOWN: ", substance_type);
			return 0;
	}
};

// @deprecated
export const getTotal = (
	measurements: IMeasureEvent[],
	substance_type: number | number[] | string,
	measureUnit: MeasureUnit,
): number => {
	if (!measurements || measurements.length === 0) {
		return 0;
	}
	const relevantMeasurements = measurements.filter((m) => {
		if (typeof substance_type === "number") {
			// Filter by substance type id
			return (
				m.measure_unit === measureUnit && m.substance_type_id === substance_type
			);
		} else if (typeof substance_type === "object") {
			// Filter by substance type id
			return (
				m.measure_unit === measureUnit &&
				substance_type.includes(m.substance_type_id!)
			);
		} else {
			// Filter by substance type name
			return (
				m.measure_unit === measureUnit &&
				m.substance_type &&
				m.substance_type.name === substance_type
			);
		}
	});
	return Number(
		relevantMeasurements.reduce((result, event) => {
			return isNaN(event.value) ? result : result.plus(event.value);
		}, new Big(0)),
	);
};

export const inventoryByProductIds = (batch: Batch, productIds: number[]) => {
	return getInventoriesTotal(
		batch.inventories?.filter((inventory) =>
			productIds.includes(inventory.inventory_product_id),
		) ?? [],
	);
};

export const inventoryFreshCannabis = (
	batch: Batch,
	freshCannabisProductIds?: number[],
) => {
	return getInventoriesTotal(
		batch.inventories?.filter((inventory) =>
			freshCannabisProductIds?.length
				? freshCannabisProductIds.includes(inventory.inventory_product_id)
				: inventory.inventory_product?.name
						.toLocaleLowerCase()
						.includes(SubstanceTypes.FreshCannabis.toLocaleLowerCase()),
		) ?? [],
	);
};

// @deprecated
export const wetWeights = (batch: Batch) => {
	return (
		batch.measurements?.filter(
			(measurement: IMeasureEvent) =>
				measurement.substance_type?.name === SubstanceTypes.FreshCannabis,
		) ?? []
	);
};

// @deprecated
export const weightsBySubstanceIdsTotal = (
	batch: Batch,
	substanceIds: number[],
) => {
	return Number(
		batch.measurements
			?.filter((measurement: IMeasureEvent) =>
				substanceIds.includes(measurement.substance_type_id!),
			)
			.reduce(
				(result, event) => (isNaN(event.value) ? result : result.plus(event.value)),
				new Big(0),
			) ?? [],
	);
};

export const inventoryDriedCannabis = (
	batch: Batch,
	driedCannabisProductIds?: number[],
) => {
	return getInventoriesTotal(
		batch.inventories?.filter((inventory) =>
			driedCannabisProductIds?.length
				? driedCannabisProductIds.includes(inventory.inventory_product_id)
				: inventory.inventory_product?.name
						.toLocaleLowerCase()
						.includes(SubstanceTypes.DriedCannabis.toLocaleLowerCase()),
		) ?? [],
	);
};

// @deprecated
export const dryWeights = (batch: Batch) => {
	return (
		batch.measurements?.filter(
			(measurement: IMeasureEvent) =>
				measurement.substance_type?.name === SubstanceTypes.DriedCannabis,
		) ?? []
	);
};

export const hasWeight = (weightMap: Record<string, Big>) => {
	for (const unitName of Object.keys(weightMap)) {
		if (Number(weightMap[unitName]) > 0) {
			return true;
		}
	}
	return false;
};

export const changeLogLink = (): string => {
	let url = "/help/docs/change-log/";
	let version = environment.version as string;
	if (!version) {
		return "no-version-found";
	}
	if (version.indexOf("-") > 0) {
		version = version.split("-")[0]!;
	}
	// Don't use patch versions in link
	const versionParts = version.split(".");
	version = `${versionParts[0]}.${versionParts[1]}.0`;
	url += version;
	return url;
};

/**
 *  Returns a map of the inventory totals for a given product broken down by unit.
 * @param inventories
 * @param inventoryProduct
 * @param pending - select only inventory with specific pending status
 * @returns
 */
export const getInventoryTotalsMap = (
	inventories: IInventory[],
	inventoryProduct: IInventoryProduct,
	pending: boolean | null = null,
): Record<string, Big> => {
	let inventoryList = [...inventories];
	if (pending !== null) {
		inventoryList = inventoryList.filter(
			(inventory) => pending === inventory.pending,
		);
	}
	const inventoryTotalsByUnit: Record<string, Big> = inventoryList.reduce(
		(result, inventory) => {
			let conversionProduct: IInventoryProduct | undefined;
			// Check if initial product has conversion factor
			if (
				inventory.conversionProduct ||
				(inventoryProduct.unit_conversion &&
					inventory.inventory_unit_id in inventoryProduct.unit_conversion)
			) {
				conversionProduct = inventory.conversionProduct ?? inventoryProduct;
			} else {
				// Otherwise we must check virtual units
				for (const virtualProduct of inventoryProduct.virtual_products ?? []) {
					if (inventory.inventory_unit_id in virtualProduct.unit_conversion) {
						conversionProduct = virtualProduct;
						break;
					}
				}
			}
			if (!conversionProduct) {
				// throw if no conversion factor found
				throw new Error(
					`${translate("error_no_conversion_for")} ${inventory.inventory_unit?.name}`,
				);
			}
			if (!conversionProduct.display_unit) {
				// throw if conversion product doesn't have display unit
				throw new Error(
					translate("error_need_display_unit_information_to_convert"),
				);
			}
			const productTotal = (inventory.measurements ?? []).reduce(
				(total, event) => {
					const conversion =
						conversionProduct?.unit_conversion[inventory.inventory_unit_id]![
							conversionProduct.display_unit_id
						];
					return total.plus(new Big(event.value).times(conversion!));
				},
				new Big(0),
			);
			if (result[conversionProduct.display_unit.name]) {
				result[conversionProduct.display_unit.name] =
					result[conversionProduct.display_unit.name].plus(productTotal);
			} else {
				result[conversionProduct.display_unit.name] = productTotal;
			}
			return result;
		},
		{},
	);
	return inventoryTotalsByUnit;
};

/**
 * Returns a string representation of the totals of a given product converted to display unit if possible.
 * @param inventories
 * @param inventoryProduct
 * @param pending - select only inventory with specific pending status
 * @returns
 */
export const getInventoryTotals = (
	inventories: IInventory[],
	inventoryProduct: IInventoryProduct,
	pending: boolean | null = null,
	is_source = true,
): string => {
	const inventoryTotalsByUnit = getInventoryTotalsMap(
		inventories,
		inventoryProduct,
		pending,
	);
	const results: string[] = [];
	for (const units of Object.keys(inventoryTotalsByUnit)) {
		const total = bigRound(inventoryTotalsByUnit[units]!);
		results.push(`${is_source ? total : -total} ${units}`);
	}
	const result = results.join(", ");
	if (result === "") {
		return `0 ${inventoryProduct.display_unit?.name}`;
	}
	return result;
};

/**
 * Returns a string representation of the total weight of a given product converted to display unit if possible.
 * @param inventories
 * @param inventoryProduct
 * @returns
 */
export const getInventoryTotalWeight = (
	inventories: IInventory[],
	inventoryProduct: IInventoryProduct,
): string => {
	const inventoryTotalsByUnit = getInventoryTotalsMap(
		inventories,
		inventoryProduct,
	);
	const results: string[] = [];
	for (const units of Object.keys(inventoryTotalsByUnit)) {
		if (units === "ea") {
			continue;
		}
		results.push(`${inventoryTotalsByUnit[units]?.toNumber()} ${units}`);
	}
	const result = results.join(", ");
	if (result === "") {
		return `0 ${inventoryProduct.display_unit?.name}`;
	}
	return result;
};

/**
 * Returns a string representation of the total count of a given product
 * @param inventories
 * @param inventoryProduct
 * @returns
 */
export const getInventoryTotalCount = (
	inventories: IInventory[],
	inventoryProduct: IInventoryProduct,
): string => {
	const inventoryTotalsByUnit = getInventoryTotalsMap(
		inventories,
		inventoryProduct,
	);
	const results: string[] = [];
	for (const units of Object.keys(inventoryTotalsByUnit)) {
		if (units === "ea") {
			results.push(`${inventoryTotalsByUnit[units]?.toNumber()} ${units}`);
		}
	}
	const result = results.join(", ");
	if (result === "") {
		return `0 ${inventoryProduct.display_unit?.name}`;
	}
	return result;
};

/**
 * Returns a string representation of the totals of a given product converted to display unit if possible.
 * @param inventories
 * @returns
 */
export const getInventoriesTotalString = (
	inventories: IInventory[],
	totals?: Record<string, Big>,
): string => {
	const inventoryTotalsByUnit = totals ?? getInventoriesTotal(inventories);
	const results: string[] = [];
	for (const unit of Object.keys(inventoryTotalsByUnit)) {
		results.push(`${inventoryTotalsByUnit[unit]?.toNumber()} ${unit}`);
	}
	const result = results.join(", ");
	if (result === "") {
		return `0 `;
	}
	return result;
};

/**
 * Returns total amount of inventory by Inventory Unit
 * @param inventories
 * @param inventoryUnitName - preferred Inventory Unit to use
 * @param preferSIUnit - prefer to use SI unit
 * @param onlyOneUnit -  calc only for one unit; will remove inventory from array once added to calculation

 * @returns  map of totals by Inventory Unit
 */
export const getInventoriesTotal = (
	inventories: IInventory[],
	inventoryUnitName?: string | undefined,
	preferSIUnit = false,
	onlyOneUnit = false, // this will remove inventory from array once added to calculation
): Record<string, Big> => {
	const result: Record<string, Big> = {};
	// Get conversion products omitting undefined
	let inventoryProducts: IInventoryProduct[] = inventories

		.map((item) => item.conversionProduct ?? getConversionProduct(item))
		.filter((item): item is IInventoryProduct => Boolean(item));
	// Get unique products
	inventoryProducts = [
		...new Map(inventoryProducts.map((item) => [item.id, item])).values(),
	];
	// Group products
	const conversionGroups: Record<string, IInventoryProduct[]> =
		groupInventoryProductsByConversion(
			inventoryProducts,
			inventoryUnitName,
			preferSIUnit,
			onlyOneUnit,
		);

	// Calculate total for each group
	for (const [index, inventory] of inventories.entries()) {
		if (!inventory && onlyOneUnit) continue;
		const conversionProduct =
			inventory.conversionProduct ?? getConversionProduct(inventory);
		if (
			conversionProduct !== undefined &&
			conversionProduct.unit_conversion !== undefined
		) {
			for (const unitName in conversionGroups) {
				if (Object.prototype.hasOwnProperty.call(conversionGroups, unitName)) {
					const conversionProductIds: number[] = conversionGroups[unitName]!.map(
						(ip) => ip.id,
					);
					const inventoryTotal =
						inventory.totalAmount ?? getInventoryAmount(inventory);
					if (
						conversionProduct.inventory_units !== undefined &&
						conversionProductIds.includes(conversionProduct.id)
					) {
						const unit = conversionProduct.inventory_units.find(
							(inventory_unit) => inventory_unit.name === unitName,
						);
						if (unit) {
							const conversion =
								conversionProduct.unit_conversion[inventory.inventory_unit_id]?.[
									unit.id
								]!;
							result[unitName] = Object.prototype.hasOwnProperty.call(result, unitName)
								? result[unitName]!.plus(new Big(inventoryTotal).times(conversion))
								: new Big(inventoryTotal).times(conversion);
							if (onlyOneUnit) delete inventories[index];
						}
						break;
					} else if (conversionProduct.display_unit?.name === unitName) {
						// Use product display unit for total if units are not available
						const conversion =
							conversionProduct.unit_conversion[inventory.inventory_unit_id]?.[
								conversionProduct.display_unit_id
							]!;
						result[unitName] = Object.prototype.hasOwnProperty.call(result, unitName)
							? result[unitName]!.plus(new Big(inventoryTotal).times(conversion))
							: new Big(inventoryTotal).times(conversion);
						if (onlyOneUnit) delete inventories[index];
						break;
					}
				}
			}
		}
	}
	return result;
};

/**
 * Groups Inventory Products by the Inventory Unit product can be converted to.
 * @param inventoryProducts
 * @param inventoryUnitName - preferred Inventory Unit to use
 * @param preferSIUnit - prefer to use SI unit
 * @param onlyOneUnit -  find only products that can be converted to specified unit
 * @returns  map of Inventory Units to Inventory Products that have conversion to this unit
 */
export const groupInventoryProductsByConversion = (
	inventoryProducts: IInventoryProduct[],
	inventoryUnitName: string | undefined,
	preferSIUnit = false,
	onlyGroupOneUnit = false,
): Record<string, IInventoryProduct[]> => {
	if (!inventoryProducts.length) {
		return {};
	}
	// Get all units
	let groupUnit = inventoryUnitName;
	let allUnits: string[] = [];
	for (const product of inventoryProducts) {
		// If no inventoryUnitName provided use display unit of the first product
		if (!groupUnit) {
			groupUnit = product.display_unit?.name;
		}
		if (product.inventory_units !== undefined)
			allUnits = [...allUnits, ...product.inventory_units.map((iu) => iu.name)];
		else if (product.display_unit !== undefined) {
			allUnits = [...allUnits, ...[product.display_unit.name]];
		}
	}
	// Fin the most common unit name
	const occ: Record<string, any> = allUnits.reduce(
		(occ: Record<string, any>, el) => {
			occ[el] = occ[el] ? ++occ[el] : 1;
			return occ;
		},
		{},
	);
	let max_count = 0;
	let res = "";
	for (const key in occ) {
		if (occ[key]) {
			const value = occ[key];
			if (max_count < value) {
				res = key;
				max_count = value;
			}
		}
	}
	// no groupUnit change if looking for a specific unit
	if (!onlyGroupOneUnit) {
		const SIUnits = ["g", "mg", "kg", "lbs", "l", "gallons", "ml", "ea"];
		// Prefer largest SI Unit if same occ
		if (preferSIUnit) {
			const commonSIUnits = SIUnits.filter(
				(value) => Object.keys(occ).includes(value) && occ[value] === max_count,
			);

			// ESS-7181 - show in display_unit
			if (
				commonSIUnits.length &&
				!(groupUnit && commonSIUnits.includes(groupUnit))
			) {
				groupUnit = commonSIUnits[0];
			}
		}

		// Prefer inventoryUnitName or SI unit if same occ
		if (
			groupUnit &&
			(!allUnits.includes(groupUnit) || occ[groupUnit] < occ[res])
		) {
			groupUnit = res;
		}
	}
	// Group products that can be converted to groupUnit
	const inventoryConversionGroups: Record<string, IInventoryProduct[]> = {};
	const notGrouped: IInventoryProduct[] = [];
	if (groupUnit) {
		inventoryConversionGroups[groupUnit] = [];
		for (const product of inventoryProducts) {
			if (
				product.inventory_units !== undefined &&
				product.inventory_units.map((i) => i.name).includes(groupUnit)
			) {
				inventoryConversionGroups[groupUnit]!.push(product);
			} else if (product.display_unit?.name === groupUnit) {
				inventoryConversionGroups[groupUnit]!.push(product);
			} else {
				notGrouped.push(product);
			}
		}
		// Stop if not able to group
		if (inventoryConversionGroups[groupUnit]!.length === 0) {
			return inventoryConversionGroups;
		}
	}

	// Continue grouping of notGrouped if needed
	return notGrouped.length === 0
		? inventoryConversionGroups
		: {
				...inventoryConversionGroups,
				...(!onlyGroupOneUnit &&
					groupInventoryProductsByConversion(notGrouped, undefined, preferSIUnit)),
			};
};

export const getInventoryAmount = (inventory: IInventory): number => {
	let total = 0;

	if (inventory.measurements) {
		for (const me of inventory.measurements) {
			total = Number(new Big(me.value).plus(total));
		}
	}
	return total;
};

/**
 * Given a piece of inventory, will return the product or virtual product
 * who's unit conversion matrix contains the unit of that inventory.
 * @param inventory - inventory that needs to be converted
 * @returns - inventory product that has the unit_conversion for the units contained in the given inventory.
 */
export const getConversionProduct = (
	inventory: IInventory,
): IInventoryProduct | undefined => {
	const inventoryProduct = inventory.inventory_product;
	if (!inventoryProduct) {
		return undefined;
	}

	let conversionProduct: IInventoryProduct | undefined;

	// Check if initial product has conversion factor
	if (inventory.inventory_unit_id in inventoryProduct.unit_conversion) {
		conversionProduct = inventoryProduct;
	} else {
		// Otherwise we must check virtual units
		for (const virtualProduct of inventoryProduct.virtual_products ?? []) {
			if (inventory.inventory_unit_id in virtualProduct.unit_conversion) {
				conversionProduct = virtualProduct;
				break;
			}
		}
	}
	return conversionProduct;
};

export const getDestructionEventTitle = (event: any) => {
	const { location, batches, batch, plant, lot } = event;

	let title = "";
	if (location) {
		title = `${translate("word_location")}: ${location.name}`;
	} else if (batch) {
		title = batches;
	} else if (plant) {
		title = plant.name ? plant.name : `${translate("word_plant")} (${plant.id})`;
	} else if (lot) {
		title = lot.name;
	}
	return title;
};

const getPlantDestructionEventContent = (
	event: any,
	weight_unit: string,
	plant_ids_on_destruction_event = false,
) => {
	const { plant, weight, reweigh_weight, group_events_count } = event;
	const NumDecimalPlaces = 4;
	let destruction_contents;
	if (plant) {
		const total_weight = measureUnitDictToString(
			{ MASS: round(weight * group_events_count, NumDecimalPlaces) },
			weight_unit,
		);

		if (plant_ids_on_destruction_event) {
			destruction_contents = `${group_events_count} ${translate("word_plant")}${
				group_events_count > 1
					? `s (#${event.plant_ids.toString().replace(/,/g, ", #")})`
					: ` (#${event.plant_id})`
			}

			(${total_weight}`;
		} else {
			destruction_contents = `${group_events_count} ${translate("word_plant")}${
				group_events_count > 1 ? "s" : ""
			} (${total_weight}`;
		}

		if (reweigh_weight) {
			const reweigh_value = measureUnitDictToString(
				{ MASS: round(reweigh_weight * group_events_count, NumDecimalPlaces) },
				weight_unit,
			);
			destruction_contents += `, Reweighed: ${reweigh_value}`;
		}
		destruction_contents += ")";
	}

	return destruction_contents;
};

const getMeasureEventDestructionEventContent = (
	event: any,
	weight_unit: string,
) => {
	const { measure_event, reweigh_measure_event, group_events_count } = event;
	const NumDecimalPlaces = 4;
	let destruction_contents;
	if (measure_event && measure_event.substance_type) {
		const total_weight = measureUnitDictToString(
			{
				[measure_event.measure_unit]: round(
					measure_event.value * group_events_count,
					NumDecimalPlaces,
				),
			},
			weight_unit,
		);
		destruction_contents = `${measure_event.substance_type.name} (${total_weight}`;

		if (reweigh_measure_event) {
			const reweigh_weight = measureUnitDictToString(
				{
					[reweigh_measure_event.measure_unit]: round(
						reweigh_measure_event.value * group_events_count,
						NumDecimalPlaces,
					),
				},
				weight_unit,
			);
			destruction_contents += `, Reweighed: ${reweigh_weight}`;
		}
		destruction_contents += ")";
	}

	return destruction_contents;
};

const getInventoryDestructionEventContent = (event: any) => {
	const { inventory, reweigh_inventory } = event;
	const NumDecimalPlaces = 4;
	let destruction_contents;
	if (inventory) {
		// ESS-7331: Previously the code here assumed that we could multiply totalAmountInDisplayUnit * group_events_count,
		// but that assumption is flawed since the totalAmountInDisplayUnit could be different across all the inventories
		// involved in the destruction event group
		//
		// As a workaround, all of the inventory destruction events in the group are included as an additional property on the single
		// destruction event that represents the group, so that its possible for us to get the correct values to show up in
		// the frontend

		let total_weight_sum_across_inventory_group = 0;
		for (const inventoryDestructionEvent of event.all_inventory_destruction_events_from_group ??
			[]) {
			total_weight_sum_across_inventory_group +=
				inventoryDestructionEvent.inventory?.totalAmountInDisplayUnit ?? 0;
		}

		const total_weight = round(
			total_weight_sum_across_inventory_group,
			NumDecimalPlaces,
		);

		const displayUnit = inventory.displayUnit.name;
		destruction_contents = `${inventory.inventory_product.name} (${total_weight} ${displayUnit}`;

		// If a reweigh inventory exists, the values 'should' be the same across all other inventories involved in the group
		// re-weigh, but sum them manually to account for the potential future case that the reweigh values are different
		if (reweigh_inventory) {
			let total_reweigh_weight_sum_across_inventory_group = 0;
			for (const inventoryReweighDestructionEvent of event.all_inventory_destruction_events_from_group ??
				[]) {
				const reweigh_value = inventoryReweighDestructionEvent.reweigh_inventory
					?.totalAmountInDisplayUnit
					? inventoryReweighDestructionEvent.reweigh_inventory
							?.totalAmountInDisplayUnit
					: (inventoryReweighDestructionEvent.reweigh_inventory?.totalAmount ?? 0);

				total_reweigh_weight_sum_across_inventory_group += reweigh_value;
			}
			const reweigh_weight = round(
				total_reweigh_weight_sum_across_inventory_group,
				NumDecimalPlaces,
			);
			destruction_contents += `, Reweighed: ${reweigh_weight} ${displayUnit}`;
		}
		destruction_contents += ")";
	}

	return destruction_contents;
};

export const getDestructionEventContent = (
	event: any,
	weight_unit: string,
	plant_ids_on_destruction_event = false,
) => {
	const { plant, inventory, measure_event } = event;

	if (plant) {
		return getPlantDestructionEventContent(
			event,
			weight_unit,
			plant_ids_on_destruction_event,
		);
	} else if (inventory) {
		return getInventoryDestructionEventContent(event);
	} else if (measure_event && measure_event.substance_type) {
		return getMeasureEventDestructionEventContent(event, weight_unit);
	}

	return "";
};

export const getTotalsMeasureUnitDict = (
	totals: Record<string, Big>,
	oppositeSign = false,
): MeasureUnitDict => {
	const numberResult: MeasureUnitDict = {};
	// eslint-disable-next-line guard-for-in
	for (const unit in totals) {
		numberResult[unit] = (oppositeSign ? -1 : 1) * Number(totals[unit]);
	}
	return numberResult;
};

export const hasFreshCannabis = (
	batch: Batch,
	gi_enabled: boolean,
	facilitySettings?: FacilitySettings,
) => {
	if (gi_enabled) {
		const inventoryFreshCannabisWetWeight = inventoryFreshCannabis(batch);
		let facilitySettingsWetWeight = {};
		if (
			facilitySettings?.wet_weight_inventory_products &&
			facilitySettings.wet_weight_inventory_products.length
		) {
			facilitySettingsWetWeight = inventoryByProductIds(
				batch,
				facilitySettings.wet_weight_inventory_products.map((item) => item.id),
			);
		}

		// ESS-7609: This should account for the case where 'Fresh Cannabis' is not set as wet weight and
		// also the case where facility has other inventory products set as wet weight
		return (
			hasWeight(inventoryFreshCannabisWetWeight) ||
			hasWeight(facilitySettingsWetWeight)
		);
	}
	return getTotalWeight(batch, SubstanceTypes.FreshCannabis) > 0;
};

export const hasDriedCannabis = (batch: Batch, gi_enabled: boolean) => {
	if (gi_enabled) {
		const wetWeights = inventoryDriedCannabis(batch);
		return hasWeight(wetWeights);
	}
	return getTotalWeight(batch, SubstanceTypes.DriedCannabis) > 0;
};

export const hasActiveProducts = (
	batch: Batch,
	gi_enabled: boolean,
): boolean => {
	if (gi_enabled) {
		return (
			BatchDetailPageComponent.getActiveProducts(batch.inventories).length > 0
		);
	}
	return BatchDetailPageComponent.getActiveSubstanceTypes(batch).length > 0;
};

export const isOnHold = (batch: Batch): boolean => {
	if (batch.system_status?.is_blocking) {
		return true;
	}
	return batch.status?.is_blocking ?? batch.archived ?? false;
};

export const canCloseBatch = (batch: Batch, gi_enabled: boolean): boolean => {
	return (
		!hasDriedCannabis(batch, gi_enabled) &&
		!hasFreshCannabis(batch, gi_enabled) &&
		batch.plant_count === 0 &&
		!hasActiveProducts(batch, gi_enabled) &&
		!isOnHold(batch)
	);
};

export const bigRound = (
	value: Big,
	epsilon = Number.EPSILON,
	roundingFactor = 6,
): number => {
	const floor = value.round(0, Big.roundDown);
	const ceiling = value.round(0, Big.roundUp);

	if (value.minus(floor).abs().lt(epsilon)) {
		return floor.toNumber();
	} else if (ceiling.minus(value).abs().lt(epsilon)) {
		return ceiling.toNumber();
	} else {
		return value.round(roundingFactor).toNumber();
	}
};

export const generateUniqueId = (): string => {
	return `${Date.now().toString(36)}${Math.random().toString(36).substring(2)}`;
};
