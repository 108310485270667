import { IWorkOrder, IWorkOrderOutput } from "@elevatedsignals/amygoodman";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { TranslocoService } from "@jsverse/transloco";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { timeout, catchError, takeUntil, tap } from "rxjs/operators";
import { EMPTY, Subscription } from "rxjs";
import {
	Component,
	ChangeDetectorRef,
	OnInit,
	OnDestroy,
	Injector,
} from "@angular/core";
import { Globals } from "app/shared/modules/globals/globals.service";
import { WorkOrderOutputQuery } from "app/shared/eagers";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";

import { GenericCreateComponent } from "../generic/generic-create.component";

import {
	IGeneralInventoryOutput,
	WorkOrderPlantOutputDynamicSchema,
} from "./schemas";

interface AddOutputConfig {
	work_order_id: number;
	work_order_type_id: number;
	use_work_order_location_output: boolean;
	work_order_location_id: number;
	use_existing_lot_output: boolean;
	duplicate_timestamp: boolean;
}

@Component({
	selector: "work-order-add-output-plant",
	templateUrl: "wo-output-form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class WorkOrderAddOutputDynamicPlantComponent
	extends GenericCreateComponent<IWorkOrder>
	implements OnInit, OnDestroy
{
	request: Subscription;
	formSchema: any = {};
	secondarySchema: any | undefined = undefined;
	secondaryModel: any = {};
	work_order$ = this._store.select(fromDashboard.getSelectedWorkOrder);
	work_order: IWorkOrder;
	private prevBatchId: number | undefined;
	private cultivarId: number | undefined;

	private readonly outputConfig: AddOutputConfig;
	private duplicate: IWorkOrderOutput | null = null;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
		private readonly _globals: Globals,
		private readonly _injector: Injector,
		private readonly _translocoService: TranslocoService,
	) {
		super(_store);

		this.outputConfig = {
			work_order_id: this._injector.get("work_order_id", null),
			work_order_type_id: this._injector.get("work_order_type_id", null),
			use_work_order_location_output: this._injector.get(
				"use_work_order_location_output",
				false,
			),
			work_order_location_id: this._injector.get("work_order_location_id", null),
			use_existing_lot_output: this._injector.get(
				"use_existing_lot_output",
				false,
			),
			duplicate_timestamp: this._injector.get("duplicate_timestamp", false),
		};
		this.model.id = this.outputConfig.work_order_id;
		this.form_title = `Add Output Items to Work Order #${this.outputConfig.work_order_id}`;
		this.form_title_translation_key = marker(
			"form_title_add_output_items_to_work_order",
		);
		this.form_title_translation_params = {
			work_order_id: `#${this.outputConfig.work_order_id}`,
		};
		this.submit_button = "Add";
		this.submit_button_translation_key = marker("word_add");
		this.submit_icon = "plus";

		this.schema = WorkOrderPlantOutputDynamicSchema();

		if (this.outputConfig.use_existing_lot_output) {
			this.schema.properties.new_lot.default = "Use Existing Lot";
			this.model.new_lot = "Use Existing Lot";
		}
	}

	ngOnInit() {
		this.duplicate = this._injector.get("duplicate", null);
		if (this.duplicate) {
			if (this.duplicate.plants.length > 0) {
				this.model = {
					...this.model,
					id: this.duplicate.work_order_id,
					plant_batch_id: this.duplicate.batch_id,
					plant_selection: "CREATE",
					cultivar_id: this.duplicate.plants[0]?.cultivar_id,
					growth_stage_id: this.duplicate.plants[0]?.growth_stage?.id,
					plant_count_create: this.duplicate.plants.length,
					timestamp: this.duplicate.plants[0]?.action_updated_at,
				};
			}

			// clear timestamp for non GMP
			if (
				!this._globals.gmp_enabled &&
				this.schema.properties.timestamp === false &&
				!this.outputConfig.duplicate_timestamp
			) {
				this.model.timestamp.hidden = true;
			}
		}

		if (this._globals.gmp_enabled && this.schema.properties.hidden === false) {
			this.model.timestamp.hidden = true;
		}
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onChanges(model) {
		if (!model.plant_batch_id && this.prevBatchId) {
			this.prevBatchId = undefined;
		} else if (
			model.plant_batch_id &&
			this.prevBatchId !== model.plant_batch_id
		) {
			this.prevBatchId = model.plant_batch_id;

			this._itemService
				.fetchItem(`batch`, `${model.plant_batch_id}`)
				.pipe(
					takeUntil(this.destroyed$),
					timeout(50000),
					catchError((error) => {
						/* eslint no-console: off */
						console.error(error);
						return EMPTY;
					}),
				)
				.subscribe((batch) => {
					this.cultivarId = batch.cultivar_id;
					this.model.cultivar_id = batch.cultivar_id;

					this._cd.detectChanges();
				});
		}

		if (!model.cultivar_id && this.cultivarId) {
			model.cultivar_id = this.cultivarId;
		} else if (model.cultivar_id === this.cultivarId) {
			this.cultivarId = undefined;
		}
	}

	getCustomFields(type, entity_id?): any {
		return this._itemService.fetchItem("custom_field_model", `${type}`, {
			entity_id: entity_id.toString(),
		});
	}

	createItem(work_order_output: IGeneralInventoryOutput) {
		// Verify form has quantity
		if (
			work_order_output.inventory_product_id &&
			(!work_order_output.quantity || work_order_output.quantity <= 0)
		) {
			this.error$.next(
				this._translocoService.translate(
					"error_work_order_input_must_include_positive_quantity",
				),
			);
			return;
		}
		this.loading$.next(true);

		let update: any = {
			batch_id: work_order_output.batch_id || work_order_output.plant_batch_id,
			timestamp: work_order_output.timestamp,
			destination_location_id: work_order_output.destination_location_id,
			plant_selection: work_order_output.plant_selection,
			lot_id: work_order_output.plant_lot_id,
			create_new_lot: work_order_output.plant_create_new_lot,
			lot_name: work_order_output.plant_lot_name,
		};
		if (work_order_output.use_plant_ids) {
			update = {
				...update,
				plant_ids: work_order_output.plant_ids,
			};
		} else if (work_order_output.remaining_plants) {
			update = {
				...update,
				remaining_plants: work_order_output.remaining_plants,
			};
		} else {
			update = {
				...update,
				cultivar_id: work_order_output.cultivar_id,
				growth_stage_id: work_order_output.growth_stage_id,
				plant_count:
					work_order_output.plant_count_create || work_order_output.plant_count_use,
			};
		}

		this._itemService
			.add(
				`work_order/${work_order_output.id}/outputs`,
				update,
				WorkOrderOutputQuery,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "work_orders",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
