import { marker } from "@jsverse/transloco-keys-manager/marker";

import { NewInventoryCreationSchema } from "../../inventory/schemas/index";
import { NewLotCreationSchema } from "../../lot/schemas/index";

export const GeneralInventorOutputDynamicSchema = () => ({
	title: "",
	description: "",
	info: "",
	properties: {
		id: {
			type: "number",
			hidden: true,
		},
		inventory_product_id: {
			title: "Product",
			title_translation_key: marker("word_product"),
			type: "number",
			widget: "data-select",
			quick_create: false,
			selectFirstAndOnly: true,
			oneOf: [
				{
					result_type: "inventory_products",
					queryString: {
						showArchived: "false",
						non_inventory_destruction: "false",
					},
				},
			],
		},
		vendor_id: {
			type: "number",
			title: "Vendor",
			title_translation_key: marker("word_vendor"),
			widget: "data-select",
			oneOf: [
				{
					result_type: "vendors",
				},
			],
			hidden: true,
			related_properties: ["inventory_product_id"],
		},
		batch_id: {
			type: "number",
			title: "Destination Batch",
			title_translation_key: marker("form_field_label_destination_batch"),
			widget: "data-select",
			hidden: true,
			selectFirstAndOnly: true,
			oneOf: [
				{
					result_type: "batches",
					queryString: {
						input: "true",
						dont_show_expired: "true",
					},
				},
			],
			visibleIf: {
				allOf: [{ inventory_product_id: ["$EXP$ target.value > 0"] }],
			},
			readOnly: false,
		},
		location_id: {
			type: "number",
			title: "Destination Location",
			title_translation_key: marker("form_field_label_destination_location"),
			widget: "data-select",
			quick_create: false,
			selectFirstAndOnly: true,
			oneOf: [
				{
					result_type: "locations",
					queryString: {
						input: "true",
					},
				},
			],
			visibleIf: {
				allOf: [{ inventory_product_id: ["$EXP$ target.value > 0"] }],
			},
			readOnly: false,
		},
		new_lot: {
			...NewLotCreationSchema.new_lot,
			visibleIf: {
				allOf: [{ inventory_product_id: ["$EXP$ target.value > 0"] }],
			},
		},
		create_new_lot: { ...NewLotCreationSchema.create_new_lot },
		lot_name: { ...NewLotCreationSchema.lot_name },
		lot_id: {
			...NewLotCreationSchema.lot_id({
				input: "false",
				dont_show_expired: "false",
				non_zero_inventory: "false",
			}),
			selectFirstAndOnly: true,
		},
		new_inventory: {
			...NewInventoryCreationSchema().new_inventory,
			visibleIf: {
				allOf: [{ inventory_product_id: ["$EXP$ target.value > 0"] }],
			},
		},
		create_new_inventory: {
			...NewInventoryCreationSchema().create_new_inventory,
		},
		inventory_name: { ...NewInventoryCreationSchema().inventory_name },
		inventory_id: {
			...NewInventoryCreationSchema().inventory_id({
				non_zero_inventory: "false",
			}),
			selectFirstAndOnly: true,
		},
		quantity: {
			type: "number",
			title: `Quantity`,
			title_translation_key: marker("word_quantity"),
			width: "50%",
			visibleIf: {
				inventory_product_id: ["$EXP$ target.value > 0"],
			},
		},
		inventory_unit_id: {
			type: "number",
			title: "Inventory Unit",
			title_translation_key: marker("word_inventory_unit"),
			widget: "data-select",
			quick_create: false,
			related_properties: ["inventory_product_id"],
			width: "50%",
			shorter_placeholder: true,
			oneOf: [
				{
					result_type: "inventory_units",
				},
			],
			visibleIf: {
				inventory_product_id: ["$EXP$ target.value > 0"],
			},
		},
		sku_id: {
			type: "number",
			title: "SKU",
			title_translation_key: marker("word_sku"),
			widget: "data-select",
			quick_create: false,
			related_properties: ["inventory_product_id"],
			oneOf: [
				{
					result_type: "skus",
					text_key: ["sku"],
				},
			],
			visibleIf: {
				inventory_product_id: ["$EXP$ target.value > 0"],
			},
		},
		timestamp: {
			type: "string",
			title: "Timestamp",
			title_translation_key: marker("word_timestamp"),
			hidden: false,
			widget: "date",
		},

		/** These are just used for the required to make sure we have all the correct items selected before allowing the user to submit the form */
		use_existing_lot_selected: {
			type: "boolean",
			widget: "checkbox",
			hidden: true,
			default: true,
			visibleIf: {
				new_lot: ["Use Existing Lot"],
			},
		},
		create_new_lot_selected: {
			type: "boolean",
			widget: "checkbox",
			hidden: true,
			default: true,
			visibleIf: {
				new_lot: ["Create New Lot"],
			},
		},
		destination_location_id: {
			type: "number",
			title: "Destination Location",
			title_translation_key: marker("form_field_label_destination_location"),
			description:
				"Destination batch will be moved to this location when the work order is closed.",
			description_translation_key: marker(
				"form_field_description_destination_batch_will_be_moved_to_this_location_when_the_work_order_is_closed",
			),
			widget: "data-select",
			quick_create: false,
			oneOf: [
				{
					result_type: "locations",
					queryString: {
						input: "true",
					},
				},
			],
			visibleIf: {
				move_batch_to_destination: [true],
			},
		},
	},
	anyOf: [
		{
			required: [
				"id",
				"location_id",
				"inventory_product_id",
				"inventory_unit_id",
				"quantity",
			],
		},
	],
});
