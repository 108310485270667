import { marker } from "@jsverse/transloco-keys-manager/marker";
import { maxPlantNumber } from "app/shared/work-order-functions";

import { NewLotCreationSchema } from "../lot/schemas";

export const schema = () => ({
	title: "",
	description: "",
	info: "",
	properties: {
		purchase_order_id: {
			type: "number",
			title: "Purchase Order",
			hidden: true,
		},

		name: {
			type: "string",
			title: "Name (optional)",
			title_translation_key: marker("form_field_label_name_optional"),
		},

		cultivar_id: {
			type: "number",
			title: "Cultivar",
			title_translation_key: marker("word_cultivar"),
			widget: "data-select",
			related_properties: [],
			oneOf: [
				{
					result_type: "cultivars",
				},
			],
		},

		destination_batch_id: {
			type: "number",
			title: "Destination Batch",
			title_translation_key: marker("form_field_label_destination_batch"),
			widget: "data-select",
			related_properties: [],
			oneOf: [
				{
					result_type: "batches",
					queryString: {
						input: "true",
						dont_show_expired: "true",
						non_zero_inventory: "false",
					},
				},
			],
		},
		lot_id: {
			...NewLotCreationSchema.lot_id({ dont_show_expired: "true" }),
			visibleIf: undefined,
			quick_create: true,
		},

		growth_stage_id: {
			type: "number",
			title: "Growth Stage",
			title_translation_key: marker("word_growth_stage"),
			widget: "data-select",
			related_properties: [],
			oneOf: [
				{
					result_type: "growth_stages",
					queryString: {
						input: "true",
						dont_show_expired: "true",
						non_zero_inventory: "false",
						growth_stage_status: ["ACTIVE"],
					},
				},
			],
		},

		number_of_plants: {
			type: "number",
			title: `Quantity`,
			title_translation_key: marker("word_quantity"),
			max: maxPlantNumber,
			warning:
				"Number of plants in output exceeds the recommended maximum - Please lower your plant count.",
			warning_translation_key: marker(
				"form_field_warning_number_of_plants_exceeds_recommended",
			),
		},

		timestamp: {
			type: "string",
			title: "Timestamp",
			widget: "date",
			warning: "The date must be after Purchase Order Date.",
			warning_translation_key: marker(
				"form_field_warning_the_date_must_be_after_purchase_order_date",
			),
		},

		/** These are just used for the required to make sure we have all the correct items selected before allowing the user to submit the form */
		is_outgoing: {
			type: "boolean",
			widget: "checkbox",
			hidden: true,
			default: false,
		},
	},
	anyOf: [
		{
			required: [
				"purchase_order_id",
				"cultivar_id",
				"destination_batch_id",
				"growth_stage_id",
				"number_of_plants",
			],
		},
	],
});
