import { ReplaySubject, Observable, EMPTY, switchMap } from "rxjs";
import {
	take,
	takeUntil,
	timeout,
	catchError,
	skipWhile,
} from "rxjs/operators";
import {
	Component,
	OnInit,
	OnDestroy,
	ChangeDetectorRef,
	Injector,
	inject,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { Globals } from "app/shared/modules/globals/globals.service";
import { PasswordInputComponent } from "app/modules/auth/components/password-input";
import { canCloseBatch, handleObservableError } from "app/shared/utils";
import { Batch, UserProfile } from "@elevatedsignals/amygoodman";
import { DevCycleService } from "app/devcycle/devcycle.service";
import { DevCycleKey } from "app/devcycle/devcycleKeys";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { FetchPageActions } from "app/modules/dashboard/actions/paging.actions";
import { PagerService } from "app/modules/dashboard/services/pager.service";
import { BatchDetailQuery } from "app/shared/eagers";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";

import { schema } from "./plant-reconcile.schema";
import { BatchCloseComponent } from "./close.component";

@Component({
	selector: "plant-reconcile",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class PlantReconcileComponent implements OnInit, OnDestroy {
	private _pagerService = inject(PagerService);

	valid$: ReplaySubject<boolean> = new ReplaySubject(1);
	error$: ReplaySubject<string> = new ReplaySubject();
	batches$ = this._store.select(fromDashboard.getGiBatchPage);
	batch_names: string[] = [];
	batch$ = this._store.select(fromDashboard.getSelectedBatch);
	batch: Batch;
	giEnabled = false;
	ezGiMigrated = false;

	user$: Observable<UserProfile | null> = this._store.select(
		fromDashboard.getProfile,
	);

	user: UserProfile;

	form_title = "Reconcile Plants";
	submit_button = "Submit";
	submit_icon = "plus";
	loading = false;

	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	model: any = {};

	schema: any;

	validators = {};

	constructor(
		private readonly _injector: Injector,
		private readonly _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _cd: ChangeDetectorRef,
		private readonly _globals: Globals,
		private readonly _devCycleService: DevCycleService,
	) {}

	valid(validator) {
		this.valid$.next(validator);
	}

	ngOnInit() {
		this._devCycleService
			.getVariable(DevCycleKey.ShowPlantLots, false)
			.subscribe((variable: any) => {
				this.giEnabled = this._injector.get("gi_enabled", false);
				this.ezGiMigrated = this._injector.get("ez_gi_migrated", false);
				this.schema = schema();

				if (this.ezGiMigrated) {
					delete this.schema.properties.lot_id;
				} else if (!this.giEnabled) {
					delete this.schema.properties.lot_id;
				} else if (!variable.value) {
					delete this.schema.properties.lot_id;
				}

				if (this._globals.gmp_enabled) {
					delete this.schema.properties.action_updated_at;
				}

				this.batch$
					.pipe(takeUntil(this.destroyed$), isNotNullOrUndefined(), take(1))
					.subscribe((batch) => {
						this.batch = batch;
						this.model = {
							...this.model,
							batch_id: this.batch.id,
						};
						this.schema.properties.cultivar_id.default = this.batch.cultivar_id;
						this._cd.detectChanges();
					});
			});

		this.user$
			.pipe(
				takeUntil(this.destroyed$),
				skipWhile((user) => user === null),
			)
			.subscribe((user) => {
				this.user = user!;
			});
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onSubmit() {
		const cuttings: any = {
			...this.model,
		};
		if (this._globals.gmp_enabled) {
			this._store.dispatch(
				layoutActions.openSidenav({
					component: PasswordInputComponent,
					inputs: {
						onSubmit: (password) => {
							this.plantReconcileRequest(cuttings, true, password);
						},
					},
				}),
			);
		} else {
			this.plantReconcileRequest(cuttings, true);
		}
	}

	onChanges(_event): void {}

	plantReconcileRequest(
		reconcile_model: any,
		signing = false,
		password?: string,
	) {
		const authObject = password
			? { username: this.user.email, password }
			: undefined;

		this.loading$.next(true);
		this._itemService
			.add(
				`batch/${this.batch.id}/plant_reconcile`,
				reconcile_model,
				{ ...BatchDetailQuery, signing: signing.toString() },
				authObject,
			)
			.pipe(
				takeUntil(this.destroyed$),
				timeout(25000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.subscribe((item) => {
				this._store.dispatch(
					ItemActions.updateSuccess({
						updatedItem: item,
						result_type: "batches",
					}),
				);

				this._store
					.select(fromDashboard.getPlantPage)
					.pipe(
						takeUntil(this.destroyed$),
						isNotNullOrUndefined(),
						switchMap((page) => {
							return this._pagerService.fetchPage({
								...page,
								type: "batch",
								type_id: this.batch.id,
								result_type: "plants",
								page_type: "plants",
							});
						}),
					)
					.subscribe((updatePage) => {
						this._store.dispatch(
							FetchPageActions.fetchPageSuccess({
								payload: updatePage,
							}),
						);
					});

				this.loading$.next(false);
				this.closeSidenav();

				if (canCloseBatch(item, this._globals.general_inventory_enabled)) {
					this._store.dispatch(
						layoutActions.openSidenav({
							component: BatchCloseComponent,
						}),
					);
				}
			});
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}
}
