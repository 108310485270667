// Costing
const inventory_cost_and_tax_eager = `cost_per_unit, discount, other_fees, tax`;
const po_other_fees_eager = `discounts, other_fees`;
/**
 * Inventory bucket eagers
 */
const inventoryProduct = `[display_unit, inventory_units, virtual_products.[display_unit, inventory_units], skus, vendors]`;
// Related information that is relevant to both measure events and inventory
const related_item_eager = `work_order_source.[work_order.[work_order_type, open_action, close_action]], work_order_output.[work_order.[work_order_type, open_action, close_action]], destruction_event`;
const related_measurement_eager = `[${related_item_eager}]`;
// Additional related information for inventory
const related_inventory_eager = `[${related_item_eager}, location, batch, purchase_order, lot]`;
const related_inventory_minimal_eager = `[${related_item_eager}, location(minimalSelects), batch(minimalSelects), sku]`;
const inventory_measurement_eager = `[notes.[replies.[user]], user, credit_event.[inventory.${related_inventory_eager}, inventories.[purchase_order]], debit_event.[inventory.${related_inventory_eager}]]`;
const inventory_measurement_minimal_eager = `[notes(minimalSelects).[replies.[user(minimalSelects)]], user(minimalSelects), credit_event.[inventory.${related_inventory_minimal_eager}], debit_event.[inventory.${related_inventory_minimal_eager}]]`;
const inventory = `[user, sku, lot, inventory_product.${inventoryProduct}, allocated_inventory.[measurements.${inventory_measurement_eager}],allocated_inventories.[measurements.${inventory_measurement_eager}], inventory_unit, measurements.${inventory_measurement_eager}, location, batch, purchase_order, work_order_output.[work_order], work_order_source.[work_order], destruction_event.[destruction_lot], status, ${inventory_cost_and_tax_eager}]`;
const inventory_minimal_eager = `[sku, lot, inventory_product.${inventoryProduct}, inventory_unit, measurements.${inventory_measurement_minimal_eager}, location, batch, purchase_order, work_order_output.[work_order], work_order_source.[work_order], destruction_event.[destruction_lot]]`;
// Eager for notes
const notes_eager = `[user, replies.[user]]`;
const notes_minimal_eager = `[user(minimalSelects), replies.[user(minimalSelects)]]`;
// Eager for measure events
const measurement_eager = `[substance_type, notes.${notes_eager}, user, credit_event.${related_measurement_eager}, debit_event.${related_measurement_eager}]`;
const measurement_minimal_eager = `[substance_type, notes.${notes_minimal_eager}, user(minimalSelects), credit_event.${related_measurement_eager}, debit_event.${related_measurement_eager}]`;

/**
 * Batches
 */
const batch_work_order_input_eager =
	"[batch, added_by.[user], measure_event.[substance_type], inventory.[measurements, inventory_product.[inventory_units, display_unit, virtual_products]], plants, from_growth_stage, to_growth_stage]";
const batch_work_order_eager = `[work_order_type, open_action.[user], close_action.[user], sources.${batch_work_order_input_eager}, outputs.${batch_work_order_input_eager}, destruction_events.[measure_event]]`;
const batchDetailQuery = () => {
	const inventory_eager = `[inventory_product.${inventoryProduct}, inventory_unit, measurements, location]`;
	const measurement_eager = `[substance_type, credit_event.[work_order_source, work_order_output], debit_event.[work_order_source, work_order_output]]`;
	const batch_detail_eager = `[batch_type, growth_stages.[growth_stage_type], production_stage.[production_stage_type], tags, cultivar, system_status, status, status_history.[status, user], location, measurements.${measurement_eager}, inventories.${inventory_eager}, plants.[cultivar]]`;
	const query: Record<string, string | string[]> = {};
	query.eager = batch_detail_eager;
	query.historical_growth_stage_timespans = "true";
	return query;
};
export const BatchDetailQuery = batchDetailQuery();

const batchRelativesQuery = () => {
	const query: Record<string, string> = {};
	query.eager = `[parent_batches, child_batches]`;
	return query;
};

export const BatchRelativesQuery = batchRelativesQuery();

const batchSensorsQuery = () => {
	const query: Record<string, string> = {};
	query.eager = `[location.sensors.[capabilities, attachments], sensors.[capabilities, attachments]]`;
	return query;
};

export const BatchSensorsQuery = batchSensorsQuery();

const batchSpecialWorkOrdersQuery = () => {
	const query: Record<string, string> = {};
	query.eager = `[harvest_work_orders.${batch_work_order_eager}, drying_work_orders.${batch_work_order_eager}]`;
	return query;
};

export const BatchSpecialWorkOrdersQuery = batchSpecialWorkOrdersQuery();

const batchInventoryQuery = () => {
	const query: Record<string, string> = {};
	query.eager = `[measurements.${measurement_eager}, inventories.${inventory}]`;
	return query;
};

export const BatchInventoryQuery = batchInventoryQuery();

const batchPlantHistoryQuery = () => {
	const query: Record<string, string> = {};
	const destructionEvents = "destruction_events.[measure_event]";
	const batch_work_order_input_eager =
		"[batch, added_by.[user], measure_event.[substance_type], plants.[plant_order], from_growth_stage, to_growth_stage]";
	const batch_work_order_eager = `[work_order_type, open_action.[user], close_action.[user], sources.${batch_work_order_input_eager}, outputs.${batch_work_order_input_eager}, ${destructionEvents}]`;
	query.eager = `[plants.[destruction_events.[destruction_reason, user, work_order.[work_order_type]], user], source_work_orders.${batch_work_order_eager}, output_work_orders.${batch_work_order_eager}, plant_batch_history.[addedBy], plant_purchase_orders.[purchase_order, plants]]`;
	return query;
};
export const BatchPlantHistoryQuery = batchPlantHistoryQuery();

const batchPageQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = `[location, cultivar, tags, growth_stages.[growth_stage_type], production_stage.[production_stage_type], batch_type,  measurements.[substance_type], inventories.[measurements, inventory_product.${inventoryProduct}]]`;
	return query;
};
export const BatchPageQuery = batchPageQuery();

/**
 * Cultivars
 */
const cultivarDetailQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[tags]";
	return query;
};
export const CultivarDetailQuery = cultivarDetailQuery();

/**
 * Seed Lots
 */
const seedLotDetailQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[tags, measurements.[notes, user]]";
	return query;
};
export const SeedLotDetailQuery = seedLotDetailQuery();

const seedLotPageQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[measurements]";
	return query;
};
export const SeedLotPageQuery = seedLotPageQuery();

/**
 * Plants
 */
const plantDetailQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager =
		"[tags, cultivar, lot, location.[sensors], batch, growth_stage.[growth_stage_type]]";
	return query;
};
export const PlantDetailQuery = plantDetailQuery();

const plantRelativesQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager =
		"[plant_parents, seed_lot_parents, batch_parents, work_order_parents.[work_order_type], children]";
	return query;
};
export const PlantRelativesQuery = plantRelativesQuery();

/**
 *  Work Orders
 */
const workOrderInventory = `[user, sku, lot, inventory_product.${inventoryProduct}, inventory_unit, measurements.${inventory_measurement_minimal_eager}, status, vendor]`;
const pendingInventory =
	"[inventory_product, inventory_unit, sku, bill_of_material]";
const destructionEvents = `destruction_events.[destruction_reason, destruction_lot, batch, lot, user, plant, measure_event.[substance_type, notes.[replies.[user]], user], inventory.${workOrderInventory}]`;
const equipments = "equipments.[equipment.records.schema, added_by.[user]]";
const records =
	"records.[record.[schema, locks, verifications], added_by.[user]]";
const inputDetail = `[batch, location, added_by.[user], measure_event.[substance_type], plants.[growth_stage_history.[growth_stage], growth_stage, plant_order], from_growth_stage, to_growth_stage, inventory.${workOrderInventory}, action_attributes.[from_growth_stage, to_growth_stage, from_location, to_location]]`;

const workOrderDetailQuery = () => {
	const query: Record<string, string> = {};
	const inventoryMin = `[inventory_product.${inventoryProduct}, inventory_unit, measurements]`;
	const destructionEventsMin = `[plant, measure_event.[substance_type], inventory.${inventoryMin}]`;
	const inputDetailMin = `[measure_event.[substance_type], plants, inventory.${inventoryMin}]`;
	const processingLossesMin = `[measure_event.[substance_type], inventory.${inventoryMin}]`;
	const recordEager = `records.[record.[schema, locks, verifications], added_by.[user]]`;
	query.eager = `[work_order_type, location, harvest_batch.[plants.[growth_stage.[growth_stage_type]]], drying_batch.[inventories.[inventory_product, measurements], measurements.[substance_type]], close_action.[user], open_action.[user], locks, verifications.signature.user, bill_of_materials, sources.${inputDetailMin}, outputs.${inputDetailMin}, processing_losses.${processingLossesMin}, destruction_events.${destructionEventsMin}, ${recordEager}, status, status_history.[status, note, user], production_run]`;
	return query;
};

export const WorkOrderDetailQuery = workOrderDetailQuery();

export enum WorkOrderEagerType {
	PendingInventory = "pending_inventory",
	Input = "input",
	Output = "output",
	DestructionEvents = "destructionEvents",
	ProcessingLosses = "processing_losses",
	Equipments = "equipments",
	Records = "records",
}

const workOrderAdditionalQuery = (type = "") => {
	const query: Record<string, string> = {};
	switch (type) {
		case WorkOrderEagerType.PendingInventory:
			query.eager = `[pending_inventory.${pendingInventory}, bill_of_materials]`;
			break;
		case WorkOrderEagerType.Input:
			query.eager = `[sources.${inputDetail}, pending_inventory.${pendingInventory}]`;
			break;
		case WorkOrderEagerType.Output:
			query.eager = `[outputs.${inputDetail}, pending_inventory.${pendingInventory}]`;
			break;
		case WorkOrderEagerType.DestructionEvents:
			query.eager = `[${destructionEvents}]`;
			break;
		case WorkOrderEagerType.ProcessingLosses:
			query.eager = `[processing_losses.[added_by.[user], measure_event.[substance_type], inventory.${workOrderInventory}]]`;
			break;
		case WorkOrderEagerType.Equipments:
			query.eager = `[${equipments}]`;
			break;
		case WorkOrderEagerType.Records:
			query.eager = `[${records}]`;
			break;
		default:
			query.eager = `[pending_inventory.${pendingInventory}, sources.${inputDetail}, outputs.${inputDetail}, processing_losses.[added_by.[user], measure_event.[substance_type], inventory.${workOrderInventory}], ${equipments}, ${records}, ${destructionEvents}]`;
			break;
	}
	return query;
};

export const WorkOrderAllAdditionalQuery = workOrderAdditionalQuery();

export const WorkOrderPendingInventoryQuery = workOrderAdditionalQuery(
	WorkOrderEagerType.PendingInventory,
);

export const WorkOrderInputQuery = workOrderAdditionalQuery(
	WorkOrderEagerType.Input,
);

export const WorkOrderOutputQuery = workOrderAdditionalQuery(
	WorkOrderEagerType.Output,
);

export const WorkOrderDestructionEventsQuery = workOrderAdditionalQuery(
	WorkOrderEagerType.DestructionEvents,
);

export const WorkOrderProcessingLossesQuery = workOrderAdditionalQuery(
	WorkOrderEagerType.ProcessingLosses,
);

export const WorkOrderEquipmentsQuery = workOrderAdditionalQuery(
	WorkOrderEagerType.Equipments,
);

export const WorkOrderRecordsQuery = workOrderAdditionalQuery(
	WorkOrderEagerType.Records,
);

const workOrderPageQuery = () => {
	const woPageInvent = `[inventory_product.[display_unit, virtual_products.display_unit], measurements, inventory_unit]`;
	const query: Record<string, string | string[]> = {};
	query.eager = `[work_order_type, close_action, open_action, source_inventories.${woPageInvent}, output_inventories.${woPageInvent}, verifications.signature.user, status]`;
	return query;
};
export const WorkOrderPageQuery = workOrderPageQuery();

/**
 *  Work Order Types
 */
const workOrderTypeDetailQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[]";
	return query;
};
export const WorkOrderTypeDetailQuery = workOrderTypeDetailQuery();

const workOrderTypePageQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[]";
	return query;
};
export const WorkOrderTypePageQuery = workOrderTypePageQuery();

/**
 *  Production Plans
 */
const productionPlanDetailQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[]";
	return query;
};
export const ProductionPlanDetailQuery = productionPlanDetailQuery();

const productionPlanPageQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[]";
	return query;
};
export const ProductionPlanPageQuery = productionPlanPageQuery();

/**
 *  Substance Types
 */
const substanceTypeDetailQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[]";
	return query;
};
export const SubstanceTypeDetailQuery = substanceTypeDetailQuery();

const substanceTypePageQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[]";
	return query;
};
export const SubstanceTypePageQuery = substanceTypePageQuery();

/**
 *  Location
 */
const locationDetailQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager =
		"[batches.plants, sensors(currentLocation).[capabilities, attachments], location_types, child_locations.^10, parent_location]";
	return query;
};
export const LocationDetailQuery = locationDetailQuery();

const locationPageQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[tags, location_types, child_locations.^10]";
	return query;
};
export const LocationPageQuery = locationPageQuery();

/**
 *  Location Types
 */
const locationTypeDetailQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[]";
	return query;
};
export const LocationTypeDetailQuery = locationTypeDetailQuery();

const locationTypePageQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[]";
	return query;
};
export const LocationTypePageQuery = locationTypePageQuery();

/**
 *  Applicable Products
 */
const applicableProductDetailQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[application_methods]";
	return query;
};
export const ApplicableProductDetailQuery = applicableProductDetailQuery();

const applicableProductPageQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[]";
	return query;
};
export const ApplicableProductPageQuery = applicableProductPageQuery();

/**
 *  Bill of Material
 */
const billOfMaterialDetailQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager =
		"[inputs.[inventory_product.[display_unit, virtual_products.[display_unit, inventory_units]], sku, inventory_unit], outputs.[inventory_product.[display_unit], sku, inventory_unit], work_order_type, work_orders, related_boms.[updated_by_user, user]]";
	return query;
};
export const BillOfMaterialDetailQuery = billOfMaterialDetailQuery();

const billOfMaterialPageQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[]";
	return query;
};
export const BillOfMaterialPageQuery = billOfMaterialPageQuery();

/**
 *  Application Methods
 */
const applicationMethodDetailQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[]";
	return query;
};
export const ApplicationMethodDetailQuery = applicationMethodDetailQuery();

const applicationMethodPageQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[]";
	return query;
};
export const ApplicationMethodPageQuery = applicationMethodPageQuery();

/**
 *  Rate Types
 */
const rateTypeDetailQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[]";
	return query;
};
export const RateTypeDetailQuery = rateTypeDetailQuery();

const rateTypePageQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[]";
	return query;
};
export const RateTypePageQuery = rateTypePageQuery();

/**
 *  Vendor
 */
const vendorDetailQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager =
		"[tags, inventory_products, status, status_history.[status, user]]";
	return query;
};
export const VendorDetailQuery = vendorDetailQuery();

const vendorPageQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[]";
	return query;
};
export const VendorPageQuery = vendorPageQuery();

/**
 *  Vendor Category
 */
const vendorCategoryDetailQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[vendors, tags]";
	return query;
};
export const VendorCategoryDetailQuery = vendorCategoryDetailQuery();

const vendorCategoryPageQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[]";
	return query;
};
export const VendorCategoryPageQuery = vendorCategoryPageQuery();

/**
 *  Purchase Order
 */
const purchaseOrderDetailQuery = () => {
	const query: Record<string, string | string[]> = {};
	const records = "records.[record.[schema], added_by.[user]]";
	const verifications = "verifications.[signature.user]";
	query.eager = `[vendor, status, status_history.[status, user], inventories.${inventory}, plantOrders.[cultivar, destination_batch, growth_stage, plants, lot, added_by], ${records}, ${verifications}, locks, ${po_other_fees_eager}]`;
	return query;
};
export const PurchaseOrderDetailQuery = purchaseOrderDetailQuery();

const purchaseOrderPageQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[vendor, inventories]";
	return query;
};
export const PurchaseOrderPageQuery = purchaseOrderPageQuery();

/**
 *  Activity Log
 */

const activityLogPageQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[user, note]";
	return query;
};
export const ActivityLogPageQuery = activityLogPageQuery();

/**
 *  Status
 */
const statusDetailQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[tags]";
	return query;
};
export const StatusDetailQuery = statusDetailQuery();

const statusPageQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[]";
	return query;
};
export const StatusPageQuery = statusPageQuery();

/**
 *  Batch Types
 */
const batchTypeDetailQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[]";
	return query;
};
export const BatchTypeDetailQuery = batchTypeDetailQuery();

const batchTypePageQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[]";
	query.showArchived = "true";
	return query;
};
export const BatchTypePageQuery = batchTypePageQuery();

/**
 *   Equipments
 */
const equipmentDetailQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[]";
	return query;
};

export const EquipmentDetailQuery = equipmentDetailQuery();

const equipmentPageQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[]";
	return query;
};

export const EquipmentPageQuery = equipmentPageQuery();

/**
 *   Document Sets
 */
const documentSetDetailQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[tags]";
	return query;
};

export const DocumentSetDetailQuery = documentSetDetailQuery();

const documentSetPageQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[]";
	return query;
};

export const DocumentSetPageQuery = documentSetPageQuery();

/**
 * Printers
 */
const printerDetailQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[jobs]";
	return query;
};

export const PrinterDetailQuery = printerDetailQuery();

const printerPageQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[]";
	return query;
};

export const PrinterPageQuery = printerPageQuery();

/**
 * Printer Job Templates
 */
const printerJobTemplateDetailQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[printer]";
	return query;
};

export const PrinterJobTemplateDetailQuery = printerJobTemplateDetailQuery();

const printerJobTemplatePageQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[]";
	return query;
};

export const PrinterJobTemplatePageQuery = printerJobTemplatePageQuery();

/**
 * Skus
 */
const skuDetailQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = `[vendors, inventory_products, inventories.${inventory}]`;
	return query;
};

export const SkuDetailQuery = skuDetailQuery();

const skuPageQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[]";
	return query;
};

export const SkuPageQuery = skuPageQuery();

/**
 *   Inventory
 */
const inventoryDetailQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = `[sku, location, batch, lot, inventory_product.${inventoryProduct}, inventory_unit, measurements.${inventory_measurement_eager}, tags, user, status, status_history.[status, user], vendor]`;
	return query;
};

export const InventoryDetailQuery = inventoryDetailQuery();

const inventoryPageQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = inventory;
	return query;
};

export const InventoryPageQuery = inventoryPageQuery();

/**
 *   Inventory Product
 */
const inventoryProductDetailQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager =
		"[skus, vendors, display_unit, tags, equivalent_units.[input_inventory_unit, output_inventory_unit], inventory_units, virtual_products.[display_unit, inventory_units], inventory_categories]";
	return query;
};

export const InventoryProductDetailQuery = inventoryProductDetailQuery();

const inventoryProductPageQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[vendors, inventory_categories]";
	return query;
};

export const InventoryProductPageQuery = inventoryProductPageQuery();

const cannabisInventoryProductPageQuery = () => {
	const query: Record<string, string | string[]> = {
		category_name: "Cannabis",
	};
	query.eager = "[]";
	return query;
};

export const CannabisInventoryProductPageQuery =
	cannabisInventoryProductPageQuery();

/**
 *  Inventory Category
 */
const inventoryCategoryDetailQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[inventory_products, tags]";
	return query;
};
export const InventoryCategoryDetailQuery = inventoryCategoryDetailQuery();

const inventoryCategoryPageQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[]";
	return query;
};
export const InventoryCategoryPageQuery = inventoryCategoryPageQuery();

/**
 *   Inventory Units
 */
const inventoryUnitDetailQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[]";
	return query;
};

export const InventoryUnitDetailQuery = inventoryUnitDetailQuery();

const inventoryUnitPageQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[]";
	return query;
};

export const InventoryUnitPageQuery = inventoryUnitPageQuery();

/**
 *   Equivalent Units
 */
const equivalentUnitDetailQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager =
		"[input_inventory_unit.[inventory_product], output_inventory_unit.[inventory_product]]";
	return query;
};

export const EquivalentUnitDetailQuery = equivalentUnitDetailQuery();

const equivalentUnitPageQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager =
		"[input_inventory_unit.[inventory_product], output_inventory_unit.[inventory_product]]";
	return query;
};

export const EquivalentUnitPageQuery = equivalentUnitPageQuery();

/**
 * Destruction Lots
 */
const destructionLotDetailQuery = () => {
	const query: Record<string, string | string[]> = {};
	// query.eager = `[created_by_user, destroyed_by_signature_ref.[signature.[user]], witness_signature_refs.[signature.[user]], destroyed_by_user, witness_one_user, witness_two_user, destruction_events(minimalSelects, orderByTime).[destruction_reason(minimalSelects), user(minimalSelects), batch(minimalSelects), plant(minimalSelects), location(minimalSelects), notes(minimalSelects).${notes_minimal_eager}, measure_event.${measurement_minimal_eager}, inventory.${inventory_minimal_eager}]]`;
	query.eager = `[created_by_user, destroyed_by_signature_ref.[signature.[user]], witness_signature_refs.[signature.[user]], destroyed_by_user, witness_one_user, witness_two_user, locks, verifications.signature.user]`;
	return query;
};

export const DestructionLotDetailQuery = destructionLotDetailQuery();

const destructionLotPageQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = `[created_by_user, destroyed_by_user, destroyed_by_signature_ref.[signature.[user]], witness_signature_refs.[signature.[user]], witness_one_user, witness_two_user, destruction_events.[measure_event, reweigh_measure_event], verifications, locks]`;
	query.data = "true";
	return query;
};

export const DestructionLotPageQuery = destructionLotPageQuery();

const sensorDetailQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager =
		"[batches, locations, capabilities, attachments, allAttachments, tags]";
	return query;
};

/**
 *  Destruction Reason
 */
const destructionReasonDetailQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[]";
	return query;
};
export const DestructionReasonDetailQuery = destructionReasonDetailQuery();

const destructionReasonPageQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[]";
	return query;
};
export const DestructionReasonPageQuery = destructionReasonPageQuery();

export const SensorDetailQuery = sensorDetailQuery();

const sensorPageQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[capabilities, attachments, tags]";
	return query;
};

export const SensorPageQuery = sensorPageQuery();

/**
 *  Report
 */
const reportDetailQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[]";
	return query;
};
export const ReportDetailQuery = reportDetailQuery();

const reportPageQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[]";
	return query;
};
export const ReportPageQuery = reportPageQuery();

/**
 *  Reporting Category
 */
const reportingCategoryDetailQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager =
		"[inventory_products, inventory_categories, exclude_inventory_products, exclude_inventory_categories]";
	return query;
};
export const ReportingCategoryDetailQuery = reportingCategoryDetailQuery();

const reportingCategoryPageQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[]";
	return query;
};
export const ReportingCategoryPageQuery = reportingCategoryPageQuery();

/**
 *  Report Type
 */
const reportTypeDetailQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[reporting_categories]";
	return query;
};
export const ReportTypeDetailQuery = reportTypeDetailQuery();

const reportTypePageQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[]";
	return query;
};
export const ReportTypePageQuery = reportTypePageQuery();

/**
 *   Form Schemas
 */
const formSchemaDetailQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[related_schemas(recordCount).[user], user]";
	return query;
};

export const FormSchemaDetailQuery = formSchemaDetailQuery();

const formSchemaPageQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[related_schemas]";
	return query;
};

export const FormSchemaPageQuery = formSchemaPageQuery();

/**
 *   Custom Field
 */
const customFieldSchemaDetailQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[related_schemas.[user], user]";
	return query;
};

export const CustomFieldSchemaDetailQuery = customFieldSchemaDetailQuery();

const customFieldPageDetailQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[related_schemas]";
	return query;
};

export const CustomFieldPageDetailQuery = customFieldPageDetailQuery();

/**
 *   Custom Scanning
 */

const customScanningQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[]";
	return query;
};

export const CustomScanningPageDetailQuery = customScanningQuery();

/**
 *  Lot tracking
 */
const lotDetailQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = `[inventories.${inventory}, plants, user, status, status_history.[status, user]]`;
	return query;
};
export const LotDetailQuery = lotDetailQuery();

const lotPageQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = `[inventories.${inventory}]`;
	return query;
};
export const LotPageQuery = lotPageQuery();

/**
 *  Tag
 */
const tagDetailQuery = () => {
	const query: Record<string, string | string[]> = {};
	query.eager = "[locations, plants, batches, cultivars]";
	return query;
};
export const TagDetailQuery = tagDetailQuery();

export const unwrapEager = (eager: string) => {
	return eager.slice(1, eager.length - 1);
};
