import { HttpClient } from "@angular/common/http";
import { AfterViewInit, Component, Input, OnInit } from "@angular/core";
import { ApiService } from "app/modules/auth/services/api.service";
import { BehaviorSubject, firstValueFrom } from "rxjs";

@Component({
	selector: "subscription",
	template: `
		<subscription-button
			[subscribed]="subscribed$ | async"
			[loading]="loading$ | async"
			(onSubscribe)="subscribeToTopic(topic)"
			(onUnsubscribe)="unsubscribeFromTopic(topic)"
		>
		</subscription-button>
	`,
})
export class SubscriptionComponent implements OnInit, AfterViewInit {
	@Input() topic: string;

	subscribed$ = new BehaviorSubject<boolean>(false);
	loading$ = new BehaviorSubject<boolean>(false);

	private _subscriptionId: string | undefined;

	constructor(
		private readonly _api: ApiService,
		private readonly _http: HttpClient,
	) {}

	ngOnInit() {
		if (this.topic) {
			this.loading$.next(true);
		}
	}

	ngAfterViewInit() {
		if (this.topic) {
			this.fetchSubscriptionStatus(this.topic).then((_status) => {
				this.loading$.next(false);
			});
		}
	}

	/**
	 * Fetch the subscription status for a given topic
	 * @param topic - The topic to fetch the subscription status for
	 * @returns A promise that resolves to the subscription status
	 *
	 * GET /subscriptions?topic=work_order.closed
	 *
	 *{
	 *	 "subscriptionId": "aaaa"
	 *}
	 * or
	 * {
	 *	 "subscriptionId": undefined
	 *}
	 *
	 * subscription will be undefined if its not present, so you could enable/disable the button based on the result
	 */
	fetchSubscriptionStatus = async (topic: string) => {
		const response = await firstValueFrom(
			this._http.get<{ subscriptionId?: string }>(
				`${this._api.es_api_server}/subscriptions?topic=${topic}`,
			),
		);
		if (response?.subscriptionId !== undefined) {
			this._subscriptionId = response.subscriptionId;
			this.subscribed$.next(true);
		} else {
			this._subscriptionId = undefined;
			this.subscribed$.next(false);
		}
	};

	/**
	 * Subscribe to a topic
	 * @param topic - The topic to subscribe to
	 * @returns A promise that resolves to the subscription status
	 *
	 * POST /subscriptions
	 * {
	 *	 "topic": "work_order.closed"
	 * }
	 */
	subscribeToTopic = async (topic: string) => {
		this.loading$.next(true);
		await firstValueFrom(
			this._http.post<{ subscriptionId?: string }>(
				`${this._api.es_api_server}/subscriptions`,
				{ topic },
			),
		);
		this.subscribed$.next(true);
		this.loading$.next(false);
		await this.fetchSubscriptionStatus(topic);
	};

	/**
	 * DELETE /subscriptions/<subscriptionId>
	 * @param topic
	 */
	unsubscribeFromTopic = async (topic: string) => {
		this.loading$.next(true);
		await firstValueFrom(
			this._http.delete<{ subscriptionId?: string }>(
				`${this._api.es_api_server}/subscriptions/${this._subscriptionId}`,
			),
		);
		this.subscribed$.next(false);
		this.loading$.next(false);
		await this.fetchSubscriptionStatus(topic);
	};
}
