import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { timeout, catchError, takeUntil } from "rxjs/operators";
import { EMPTY } from "rxjs";
import { Component, OnDestroy, Injector } from "@angular/core";
import { IProductionPlan } from "@elevatedsignals/amygoodman";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";

import { GenericCreateComponent } from "../generic/generic-create.component";

@Component({
	selector: "production-plan-create",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class ProductionPlanCreateComponent
	extends GenericCreateComponent<IProductionPlan>
	implements OnDestroy
{
	specific_field_id: string;

	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			name: {
				type: "string",
				title: "Name",
				title_translation_key: marker("word_name"),
				warning: "Warning: Production Plan name already in use.",
				warning_translation_key: marker(
					"form_field_warning_production_plan_name_already_in_use",
				),
			},
			use_existing_work_orders: {
				type: "boolean",
				widget: "checkbox",
				title: "Create Plan Steps using existing Work Orders",
				title_translation_key: marker(
					"form_field_create_plan_steps_using_existing_work_orders",
				),
			},
			work_orders: {
				type: "array",
				title: "Work Orders",
				title_translation_key: marker("word_work_orders"),
				widget: "data-select",
				items: {
					type: "number",
					oneOf: [
						{
							result_type: "work_orders",
							queryString: {
								wo_status: ["OPEN", "CLOSED", "PLANNED"],
							},
						},
					],
				},
				visibleIf: {
					use_existing_work_orders: [true],
				},
			},
			create_boms: {
				type: "boolean",
				widget: "checkbox",
				title: "Create BOM for each Work Order",
				title_translation_key: marker("form_field_create_bom_for_each_work_order"),
				visibleIf: {
					use_existing_work_orders: [true],
				},
			},
			first_step_is_po: {
				type: "boolean",
				widget: "checkbox",
				title: "Start with Purchase Order",
				title_translation_key: marker("form_field_start_with_purchase_order"),
				visibleIf: {
					use_existing_work_orders: [true],
				},
			},
			last_step_is_so: {
				type: "boolean",
				widget: "checkbox",
				title: "End with Shipping Order",
				title_translation_key: marker("form_field_end_with_shipping_order"),
				visibleIf: {
					use_existing_work_orders: [true],
				},
			},
		},
		required: ["name"],
	};

	validators = {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		"/name": (value, property, form) => {
			if (value !== "") {
				if (this.names && this.names.includes(value.trim())) {
					return { name: {} };
				}
			}
			return null;
		},
	};

	private names;

	constructor(
		protected _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _injector: Injector,
	) {
		super(_store);
		this.form_title = "Create a piece of Production Plan";
		this.form_title_translation_key = marker(
			"form_title_create_a_piece_of_production_plan",
		);
		this.submit_button = "Create";
		this.submit_button_translation_key = marker("word_create");
		this.specific_field_id = this._injector.get("specific_field_id", null);
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	ngOnInit() {
		// Get a list of all names currently in use.
		this.loading$.next(true);
		this._itemService
			.fetchItem(`production_plans/fetch/names`, "", { col: "name" })
			.pipe(
				takeUntil(this.destroyed$),
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.subscribe((value) => {
				// For duplicate name value validation
				this.names = value;
			});
		this.loading$.next(false);
	}

	createItem(production_plan: Partial<IProductionPlan>) {
		this.loading$.next(true);
		this._itemService
			.add(`production_plans`, production_plan)
			.pipe(
				takeUntil(this.destroyed$),
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.subscribe((addedItem) => {
				this._store.dispatch(
					ItemActions.addSuccess({
						result_type: "production_plans",
						addedItem,
						specific_field_id: this.specific_field_id,
					}),
				);
				this.loading$.next(false);
				this.closeSidenav();
			});
	}
}
