import { marker } from "@jsverse/transloco-keys-manager/marker";

export const ShippingOrderAllocateInventoryByIdsSchema = () => ({
	title: "",
	description: "",
	info: "",
	properties: {
		purchase_order_id: {
			type: "number",
			title: "Purchase Order",
			hidden: true,
		},
		inventory_product_id: {
			title: "Product",
			title_translation_key: marker("word_product"),
			type: "number",
			widget: "data-select",
			quick_create: false,
			related_properties: ["vendor_id"],
			oneOf: [
				{
					result_type: "inventory_products",
					queryString: {
						non_zero_inventory: "false",
						showArchived: "false",
					},
				},
			],
			hidden: true,
		},
		inventory_ids: {
			type: "array",
			title: "Inventory ID",
			title_translation_key: marker("form_field_label_inventory_id"),
			widget: "data-select",
			quick_create: false,
			multi: true,
			related_properties: ["inventory_product_id"],
			items: {
				type: "number",
				oneOf: [
					{
						result_type: "inventories",
						text_key: ["name", "id"],
						text_format: "?1 (#?2)",
						value_key: "id",
						queryString: {
							non_zero_inventory: "true",
							dont_show_expired: "true",
						},
					},
				],
			},
		},
		use_remaining: {
			title: "Use Remaining Inventory",
			title_translation_key: marker("form_field_label_use_remaining_inventory"),
			type: "boolean",
			widget: "checkbox",
			default: false,
		},
		value: {
			type: "number",
			title: `Quantity`,
			title_translation_key: marker("word_quantity"),
			visibleIf: {
				allOf: [{ use_remaining: false }],
			},
			width: "50%",
		},
		inventory_unit_id: {
			type: "number",
			title: "Inventory Unit",
			title_translation_key: marker("word_inventory_unit"),
			widget: "data-select",
			quick_create: false,
			shorter_placeholder: true,
			related_properties: ["inventory_product_id", "inventory_ids"],
			oneOf: [
				{
					result_type: "inventory_units",
				},
			],
			visibleIf: {
				allOf: [{ use_remaining: false }],
			},
			width: "50%",
		},
		amount_available: {
			type: "string",
			widget: "string",
			title: "Amount Available",
			readOnly: true,
			visibleIf: {
				oneOf: [{ inventory_ids: ["$ANY$"] }],
			},
		},
		timestamp: {
			type: "string",
			title: "Date",
			widget: "date",
			title_translation_key: marker("word_date"),
			warning: "The date must be after Shipping Order Date.",
			warning_translation_key: marker(
				"form_field_warning_the_date_must_be_after_shipping_order_date",
			),
		},
	},
	anyOf: [
		{
			required: [
				"purchase_order_id",
				"inventory_ids",
				"value",
				"inventory_unit_id",
			],
		},
		{
			required: ["purchase_order_id", "inventory_ids", "use_remaining"],
		},
	],
});
