import { HeaderQueryType, IInventory, Page } from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { InventoryPageQuery } from "app/shared/eagers";

import { createPagingReducer } from "./createPagingReducer";

export const reducerKey = "inventories";

export type State = Page<IInventory>;

export const reducer = createPagingReducer<IInventory>(reducerKey, {
	result_type: "inventories",
	title: "Inventory",
	title_plural: "Inventories",
	title_translation_key: marker("page_title_inventories"),
	title_plural_translation_key: marker("page_title_plural_inventories"),
	results: [],
	page: 1,
	page_size: 50,
	total: 0,
	header_queries: [
		{
			title: "Archived Filter",
			key: "archived_filter",
			type: HeaderQueryType.select,
			options: [
				{
					label: "Show All",
					translationKey: marker("inventory_filter_all"),
					value: "ALL",
				},
				{
					label: "Show Active",
					translationKey: marker("inventory_filter_active"),
					value: "ACTIVE",
				},
				{
					label: "Show Pending",
					translationKey: marker("inventory_filter_pending"),
					value: "PENDING",
				},
				{
					label: "Show Zeroed",
					translationKey: marker("inventory_filter_zeroed"),
					value: "ZEROED",
				},
				{
					label: "Show Archived",
					translationKey: marker("inventory_filter_archived"),
					value: "ARCHIVED",
				},
			],
			value: "ACTIVE",
		},
		{
			title: "Batch",
			title_translation_key: marker("page_title_batches"),
			key: "batch_id",
			type: HeaderQueryType.dataSelect,
			result_type: "batches",
			value_key: "id",
			text_key: "name",
			disabled: false,
			multiple: false,
			search: true,
		},
		{
			title: "Lot",
			title_translation_key: marker("page_title_lots"),
			key: "lot_id",
			type: HeaderQueryType.dataSelect,
			result_type: "lots",
			value_key: "id",
			text_key: "name",
			disabled: false,
			multiple: false,
			search: true,
		},
	],
	query: InventoryPageQuery,
});
