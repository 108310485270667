import {
	IWorkOrder,
	IWorkOrderPendingInventory,
} from "@elevatedsignals/amygoodman";
import { Store } from "@ngrx/store";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { timeout, catchError, takeUntil, tap } from "rxjs/operators";
import { EMPTY } from "rxjs";
import {
	Component,
	ChangeDetectorRef,
	OnInit,
	OnDestroy,
	Injector,
} from "@angular/core";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import _ from "lodash";
import { Globals } from "app/shared/modules/globals/globals.service";
import { WorkOrderInputQuery } from "app/shared/eagers";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";

import { GenericCreateComponent } from "../generic/generic-create.component";

import { IGeneralInventorySource } from "./schemas";
import { WorkOrderPlantSourceDynamicSchema } from "./schemas/gi-plant-source-dynamic";

interface AddSourceConfig {
	work_order_id: number;
	location_id: boolean;
	work_order_type_id: number;
	move_batch_to_location: boolean;
	use_remaining_input: boolean;
}
@Component({
	selector: "work-order-add-source-dynamic-plant",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class WorkOrderAddSourceDynamicPlantComponent
	extends GenericCreateComponent<IGeneralInventorySource>
	implements OnInit, OnDestroy
{
	pending_inventory: IWorkOrderPendingInventory;

	work_order$ = this._store.select(fromDashboard.getSelectedWorkOrder);
	work_order: IWorkOrder;

	private readonly sourceConfig: AddSourceConfig;
	private readonly pending_inventory_id: number;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
		private readonly _globals: Globals,
		private readonly _injector: Injector,
	) {
		super(_store);
		this.sourceConfig = {
			work_order_id: this._injector.get("work_order_id", null),
			location_id: this._injector.get("location_id", null),
			work_order_type_id: this._injector.get("work_order_type_id", null),
			move_batch_to_location: this._injector.get("move_batch_to_location", null),
			use_remaining_input: this._injector.get("use_remaining_input", false),
		};
		this.form_title = `Add Inputs To Work Order #${this.sourceConfig.work_order_id}`;
		this.form_title_translation_key = marker(
			"form_title_add_inputs_to_work_order_number",
		);
		this.form_title_translation_params = {
			work_order_id: `#${this.sourceConfig.work_order_id}`,
		};
		this.submit_button = "Add";
		this.submit_button_translation_key = marker("word_add");
		this.submit_icon = "plus";

		this.schema = WorkOrderPlantSourceDynamicSchema();

		if (this.sourceConfig.use_remaining_input === true) {
			this.schema.properties.use_remaining.default = true;
		} else {
			this.schema.properties.use_remaining.default = false;
		}
	}

	ngOnInit() {
		if (this._globals.gmp_enabled && this.schema.properties.timestamp) {
			this.schema.properties.timestamp.hidden = true;
		}

		this.model.id = this.sourceConfig.work_order_id;
		if (this.sourceConfig.location_id) {
			this.schema.properties.move_batch_to_work_order_location = {
				...this.schema.properties.move_batch_to_work_order_location,
				hidden: false,
				default: this.sourceConfig.move_batch_to_location,
			};
			this.model.move_batch_to_work_order_location =
				this.sourceConfig.move_batch_to_location;
		}
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onChanges() {}

	createItem(work_order_source: IGeneralInventorySource) {
		this.loading$.next(true);

		let update: any = {
			batch_id: work_order_source.plant_batch_id,
			timestamp: work_order_source.timestamp,
			move_batch_to_work_order_location:
				work_order_source.move_batch_to_work_order_location ?? false,
			type: "Plants",
			lot_id: work_order_source.plant_lot_id,
		};
		if (work_order_source.use_plant_ids) {
			update = {
				...update,
				plant_ids: work_order_source.plant_ids,
			};
		} else if (work_order_source.use_remaining) {
			update = {
				...update,
				use_remaining: work_order_source.use_remaining,
			};
		} else {
			update = {
				...update,
				plant_count: work_order_source.plant_count,
			};
		}
		this._itemService
			.add(
				`work_order/${work_order_source.id}/sources`,
				update,
				WorkOrderInputQuery,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "work_orders",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
