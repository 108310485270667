import {
	IVendor,
	IWorkOrder,
	IWorkOrderPendingInventory,
	IInventoryProduct,
} from "@elevatedsignals/amygoodman";
import { Store } from "@ngrx/store";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { timeout, catchError, takeUntil, tap } from "rxjs/operators";
import { EMPTY } from "rxjs";
import {
	Component,
	ChangeDetectorRef,
	OnInit,
	OnDestroy,
	Injector,
} from "@angular/core";
import { TranslocoService } from "@jsverse/transloco";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { Globals } from "app/shared/modules/globals/globals.service";
import { WorkOrderDetailQuery } from "app/shared/eagers";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";

import { GenericCreateComponent } from "../generic/generic-create.component";

import {
	GeneralInventoryBOMSourceSchema,
	IGeneralInventoryBOMSource,
} from "./schemas";

@Component({
	selector: "work-order-add-source-bom",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class WorkOrderAddSourceBOMComponent
	extends GenericCreateComponent<IGeneralInventoryBOMSource>
	implements OnInit, OnDestroy
{
	pending_inventory: IWorkOrderPendingInventory;

	work_order$ = this._store.select(fromDashboard.getSelectedWorkOrder);
	work_order: IWorkOrder;

	schema: any = GeneralInventoryBOMSourceSchema();

	private prevInventoryProductId: number | undefined;
	private prevVendorId: number | undefined;
	private readonly work_order_id: number;
	private readonly work_order_type_id: number;
	private readonly use_remaining_input: boolean;
	private readonly move_batch_to_location: boolean;
	private readonly location_id: number;
	private readonly pending_inventory_id: number;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
		private readonly _globals: Globals,
		private readonly _injector: Injector,
		private readonly _translocoService: TranslocoService,
	) {
		super(_store);
		this.pending_inventory_id = this._injector.get("pending_inventory_id", null);
		this.work_order_id = this._injector.get("work_order_id", null);
		this.work_order_type_id = this._injector.get("work_order_type_id", null);
		this.move_batch_to_location = this._injector.get(
			"move_batch_to_location",
			null,
		);
		this.use_remaining_input = this._injector.get("use_remaining_input", false);
		this.location_id = this._injector.get("location_id", null);

		this.form_title = `Add Inputs To Work Order #${this.work_order_id}`;

		this.form_title_translation_key = marker(
			"form_title_add_inputs_to_work_order_number",
		);
		this.form_title_translation_params = {
			work_order_id: `#${this.work_order_id}`,
		};
		this.submit_button = "Add";
		this.submit_button_translation_key = marker("word_add");
		this.submit_icon = "plus";

		this.schema.properties.inventory_product_id.oneOf[0].queryString = {
			// Retain the queryString already in this schema
			...this.schema.properties.inventory_product_id.oneOf[0].queryString,
			source_inventory_products_for_work_order_type_id: this.work_order_type_id,
		};
	}

	ngOnInit() {
		if (this.pending_inventory_id) {
			this._itemService
				.fetchItem(`pending_inventory/${this.pending_inventory_id}`, "")
				.pipe(takeUntil(this.destroyed$))
				.pipe(
					timeout(10000),
					catchError((e) => {
						this.error$.next(handleObservableError(e));
						this.loading$.next(false);
						return EMPTY;
					}),
				)
				.subscribe((pending_inventory) => {
					this.pending_inventory = pending_inventory;
					if (this.pending_inventory) {
						this.prevInventoryProductId = this.pending_inventory.inventory_product_id;
						this.model = {
							...this.model,
							input_type: this.pending_inventory.plants ? "PLANTS" : "INVENTORY",
							inventory_product_id: this.pending_inventory.inventory_product_id,
							inventory_unit_id: this.pending_inventory.inventory_unit_id,
							quantity: this.pending_inventory.quantity,
							pending_inventory_id: this.pending_inventory.id,
							...(this.pending_inventory.plants && {
								plant_count: this.pending_inventory.quantity,
							}),
						};
					}
					this._cd.detectChanges();
				});
		}
		if (this._globals.gmp_enabled && this.schema.properties.timestamp) {
			delete this.schema.properties.timestamp;
		}

		this.model.id = this.work_order_id;
		if (this.location_id) {
			this.schema.properties = {
				...this.schema.properties,
				move_batch_to_work_order_location: {
					type: "boolean",
					widget: "checkbox",
					title: "Move batch to work order location",
					title_translation_key: marker(
						"form_field_label_move_batch_to_work_order_location",
					),
					default: this.move_batch_to_location ?? false,
					visibleIf: {
						input_type: ["PLANTS"],
					},
				},
			};
			this.model.move_batch_to_work_order_location =
				this.move_batch_to_location ?? false;
		}
		if (this.use_remaining_input === true) {
			this.schema.properties = {
				...this.schema.properties,
				remaining_inventory: {
					...this.schema.properties.remaining_inventory,
					default: true,
				},
			};
		} else {
			this.schema.properties = {
				...this.schema.properties,
				remaining_inventory: {
					...this.schema.properties.remaining_inventory,
					default: false,
				},
			};
		}
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onChanges(model: IGeneralInventoryBOMSource) {
		if (!model.vendor_id && this.prevVendorId) {
			this.prevVendorId = undefined;
		} else if (model.vendor_id && this.prevVendorId !== model.vendor_id) {
			this.prevVendorId = model.vendor_id;

			this._itemService
				.fetchItem(`inventory_product`, `${model.inventory_product_id}`, {
					eager: "inventory_units",
				})
				.pipe(
					takeUntil(this.destroyed$),
					timeout(50000),
					catchError((error) => {
						/* eslint no-console: off */
						console.error(error);
						return EMPTY;
					}),
				)
				.subscribe((inventory_product: IInventoryProduct) => {
					this._itemService
						.fetchItem("vendor", `${model.vendor_id}`, {
							eager: "inventory_products.[inventory_units]",
						})
						.pipe(
							takeUntil(this.destroyed$),
							timeout(50000),
							catchError((error) => {
								/* eslint no-console: off */
								console.error(error);
								return EMPTY;
							}),
						)
						.subscribe((vendor: IVendor) => {
							const vendorProduct = vendor.inventory_products!.filter(
								(product) => product.name === inventory_product.name,
							)[0];

							const bomUnit = inventory_product.inventory_units!.filter(
								(unit) => unit.id === this.model.inventory_unit_id,
							)[0];

							if (
								vendorProduct!.inventory_units!.filter(
									(unit) => unit.name === bomUnit!.name,
								).length < 1
							) {
								this.error$.next(
									this._translocoService.translate(
										"error_work_order_bom_input_must_have_same_unit",
									),
								);
								return;
							} else {
								this.error$.next("");
							}
							this.model.inventory_product_id = vendorProduct!.id;
							this.model.inventory_unit_id = vendorProduct!.inventory_units!.filter(
								(unit) => unit.name === bomUnit!.name,
							)[0]!.id;

							this._cd.detectChanges();
						});
				});
		}
	}

	createItem(work_order_source: IGeneralInventoryBOMSource) {
		// Verify form has remaining_inventory or weight
		if (
			work_order_source.inventory_product_id &&
			!work_order_source.remaining_inventory &&
			(!work_order_source.quantity || work_order_source.quantity <= 0)
		) {
			this.error$.next(
				this._translocoService.translate(
					"error_work_order_input_must_include_remaining_inventory_or_positive_quantity",
				),
			);
			return;
		}
		this.loading$.next(true);

		let update: any = {
			batch_id: work_order_source.batch_id || work_order_source.plant_batch_id,
			timestamp: work_order_source.timestamp,
			move_batch_to_work_order_location:
				work_order_source.move_batch_to_work_order_location ?? false,
			type: "Plants",
			pending_inventory_id: this.pending_inventory_id,
		};
		if (work_order_source.input_type == "PLANTS") {
			update = { ...update, lot_id: work_order_source.plant_lot_id };
			if (work_order_source.use_plant_ids) {
				update = {
					...update,
					plant_ids: work_order_source.plant_ids,
				};
			} else if (work_order_source.use_remaining) {
				update = {
					...update,
					use_remaining: work_order_source.use_remaining,
				};
			} else {
				update = {
					...update,
					plant_count: work_order_source.plant_count,
				};
			}
		} else {
			update = {
				...update,
				type: "Inventory",
				inventory_product_id: work_order_source.inventory_product_id,
				inventory_unit_id: work_order_source.inventory_unit_id,
				remaining_inventory: work_order_source.remaining_inventory,
				source: work_order_source.source,
				inventory_ids: work_order_source.inventory_ids,
				lot_id: work_order_source.lot_id,
				location_id: work_order_source.location_id,
				batch_id: work_order_source.batch_id,
				value: work_order_source.quantity,
			};
		}
		this._itemService
			.add(
				`work_order/${work_order_source.id}/sources`,
				update,
				WorkOrderDetailQuery,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((e) => {
					this.error$.next(handleObservableError(e, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "work_orders",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
